import React from 'react'
import { NavLink } from 'react-router-dom';
import needHelpIcon from '../../Image/FAQ_Page/need-help-icon.png';

function NeedMoreHelp() {
  return (
    <>
     <section className="needHelpSectionStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 needHelpFirstHeading">
                    <h5>Need More Help?</h5>
                </div>
                <div className="col-md-12 needHelpSecondHeading">
                    {/* <NavLink to={"/getQuotepage"}></NavLink> */}
                    <span className="needHelpSecondHeadingSpan"><NavLink to={"/getQuote"} className="href"><span><img src={needHelpIcon} alt="Need Help" /></span><span className="ms-3">Contact Us</span></NavLink></span>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default NeedMoreHelp;