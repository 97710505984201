import React from 'react'
import '../Page/DissertationPrivacyPolicy.css'
import PrivacyPolicyBanner from './Privacy_Policy/PrivacyPolicyBanner';
import BlogAndChapters from './Privacy_Policy/BlogAndChapters';
import LastQuestion from './Privacy_Policy/LastQuestion';
import { Helmet } from 'react-helmet';

function DissertationPrivacyPolicy() {
  return (
    <>
    <Helmet>
          <title>Dissertation Writing Service</title>
          <meta name="description" content="This privacy policy document outlines the types of personal information that is received and collected by Write My Dissertation For Me " />
          <meta name="keywords" content="Dissertation Experts, take my online dissertation, thesis help online, full thesis, dissertation research methods" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/privacypolicy/" />
        </Helmet>
    <PrivacyPolicyBanner />
    <BlogAndChapters />
    <LastQuestion />
    </>
  )
}

export default DissertationPrivacyPolicy;