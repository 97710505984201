
const FAQDataOnEconomicsSubject = [
    {
        id : 1,
        question : "Can I Pay Someone To Write My Economics Dissertation For Me?",
        answer : <span>Yes! Those who are interested in improving their grades on their dissertations can make use of the custom <strong>dissertation writing services</strong> offered by companies like writemydissertationforme, as well as the experience of the dissertation experts that these companies provide.</span>,
    },
    {
        id : 2,
        question : "Can I request revisions if I am not satisfied with the initial draft?",
        answer : `Absolutely. We offer free revisions to ensure your dissertation meets your expectations and academic standards. You can communicate directly with the writer to provide feedback, and we will make adjustments until you are satisfied with the final result.`,
    },
    {
        id : 3,
        question : "Can you help with both the research and writing aspects of my dissertation?",
        answer : "Yes, we offer comprehensive support that covers all stages of your dissertation, from formulating a research question and conducting literature reviews to data collection, analysis, and writing. You can choose full-service help or focus on specific sections where you need assistance.",
    },
    {
        id : 4,
        question : "How Many Pages Are in an Economic Dissertation?",
        answer : "The Criteria of your specific degree program, as well as the subject and topic of your dissertation, will determine the duration of your final project. On average, it might run from 80-300 pages.",
    },
    {
        id : 5,
        question : "Can you provide well-written thesis for an economics dissertation?",
        answer : "Yes, take our Thesis writing service and our writers will provide perfect a thesis statement for economics subject.",
    },

];

export default FAQDataOnEconomicsSubject;
