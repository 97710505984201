import React from 'react'
import '../CommonPage/CarouselComponent.css'
import { MdOutlineStar, MdOutlineStarHalf } from "react-icons/md";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Ken from '../Image/Testimonial_Page/Ken.png'
import Mary from '../Image/Testimonial_Page/Mary.png'
import Audrey from '../Image/Testimonial_Page/Audrey.png'

function CarouselComponent() {
  return (
    <>
    <div id="carouselExampleControls" className="carousel testimonialCarouselComponent slide" data-bs-ride="carousel">
  <div className="carousel-inner carouselInnerComponent">
    <div className="carousel-item carouselItemOfThisComponent active">
        <div className="container carouselContainer">
            <div className="row justify-content-evenly testimonialCarouselComponentImageStarAndName">
                <div className="col-lg-4 testimonialComponentImageAndPara">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 carouselComponentImage">
                                <img src={Ken} alt="write my dissertation online" />
                            </div>
                            <div className="col-lg-6 testimonialIconAndName">
                                <span className="carouselComponentIcon"><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStarHalf /></i></span><br />
                                <span className='testimonialComponentName'>Ken Roberts</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 carouselParagraph">
                    <p>“Very good job, guys! I liked the communication; it was swift. I was skeptical initially about giving my paper, but you guys surprised me; I got an A grade on my paper, thank you. I will work with you again, my friends and I have a big paper coming up.”</p>
                </div>
            </div>
        </div>
    </div>
    <div className="carousel-item carouselItemOfThisComponent">
    <div className="container carouselContainer">
            <div className="row justify-content-evenly testimonialCarouselComponentImageStarAndName">
                <div className="col-lg-4 testimonialComponentImageAndPara">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 carouselComponentImage">
                                <img src={Mary} alt="dissertation writing help" />
                            </div>
                            <div className="col-lg-6 testimonialIconAndName">
                            <span classname="carouselComponentIcon"><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStarHalf /></i></span><br />
                                <span className='testimonialComponentName'>Mary Liv</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 carouselParagraph">
                    <p>“Hell Yeah! My dissertation paper got a satisfactory grade; no edits were needed. There were no grammar errors or plagiarism issues; the citations were done pretty neatly. The feedback I gave was taken gracefully; these are reaaallllyy good people when it comes to writing! They took the burden off my shoulders.”</p>
                </div>
            </div>
        </div>
    </div>
    <div className="carousel-item carouselItemOfThisComponent">
    <div className="container carouselContainer">
            <div className="row justify-content-evenly testimonialCarouselComponentImageStarAndName">
                <div className="col-lg-4 testimonialComponentImageAndPara">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 carouselComponentImage">
                                <img src={Audrey} alt="write my dissertation" />
                            </div>
                            <div className="col-lg-6 testimonialIconAndName">
                            <span classname="carouselComponentIcon"><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStar /></i><i><MdOutlineStarHalf /></i></span><br />
                                <span className='testimonialComponentName'>Audrey Holt</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 carouselParagraph">
                    <p>“If you are planning to pursue your higher doctorate studies and you are confused on how to go about it. Then I would recommend to use these services, since it has helped me in every other way -economical, clear communication and timely work. I was happy with their professional attitude and their quality work.”</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <button className="carousel-control-prev carouselControlPreviousButton" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"><i><BsChevronLeft /></i></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next carouselControlNextButton" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"><i><BsChevronRight /></i></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </>
  )
}

export default CarouselComponent;