import React from 'react'
import CarouselData from '../Data/LandingPage/CarouselData'
import './QuickQueryForm.css'
import UseEmailValidation from '../Hooks/UseEmailValidation';

function QuickQueryForm() {
    const [writingServices, dissertationWritingServices, email, carouselEmail, reEmail, carouselReEmail, enquireNowButton] = UseEmailValidation();
    
  return (
    <>
    <form action="/thankYouPage" name='contact-quick-query-form' method="post" enctype="multipart/form-data" data-netlify="true" onSubmit="submit" className="costOfDissertationQuickQueryFormStartHere">
                    <input type="hidden" name='form-name' value="contact-quick-query-form" />
                        <div className="mb-3 costOfDissertationQuickQueryFormPartDiv">
                            <label htmlFor="quickQueryTypeOfPaper" className="form-label quickQueryFormLabel">Type of paper<span>*</span></label>
                            <select className="costOfDissertationQuickQueryFormSelect" name='quickQueryTypeOfPaper' id="quickQueryTypeOfPaper" value={writingServices} onChange={dissertationWritingServices} >
                        <option selected disabled>Type of Paper</option>
                    {
                        CarouselData.map((quickQueryTypeOfPaper) => (
                            <option key={quickQueryTypeOfPaper.id} value={quickQueryTypeOfPaper.value}>{quickQueryTypeOfPaper.typeOfPaperData}</option>
                        ))
                    }
                </select>
                          </div>
                        <div className="mb-3 costOfDissertationQuickQueryFormPartDiv">
                            <label htmlFor="quickQueryFormEmail" className="form-label quickQueryFormLabel">Email<span>*</span></label>
                            <input type="email" name="quickQueryFormEmail" id="quickQueryFormEmail" className='costOfDissertationQuickQueryFormEmail' value={email} onChange={carouselEmail} required />
                          </div>
                        <div className="mb-3 costOfDissertationQuickQueryFormPartDiv">
                            <label htmlFor="quickQueryFormConfirmEmail" className="form-label quickQueryFormLabel">Confirm Email<span>*</span></label>
                            <input type="email" name="quickQueryFormConfirmEmail" id="quickQueryFormConfirmEmail" className='costOfDissertationQuickQueryFormEmail' ondrop="return false;" autocomplete="off" value={reEmail} onChange={carouselReEmail} onPaste = {(e) => {e.preventDefault(); return false;}} required />
                          </div>
                          <button type="submit" className="costOfDissertationQuickQueryFormPartButton my-2" id='costOfDissertationQuickQueryFormPartButton' ref={enquireNowButton}>HIRE AN EXPERT</button>
                    </form>
    </>
  )
}

export default QuickQueryForm