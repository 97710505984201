import React from 'react';
import { NavLink } from 'react-router-dom';
import "../Page/DissertationSubject.css";

const SubjectComponent = () => {
  return (
    <>
    <section className="topSubjectsUnderDissertationSectionStartHere">
<div className="container">
<div className="row justify-content-center">
    <div className="col-md-12 topDissertationSubjectsHeading">
        <h2>TOP SUBJECTS</h2>
    </div>
        <div className="col-md-2 firstSubject individualSubjectDiv">
            <div className="firstImage"></div>
            <p><NavLink to={"/accounts"} className="ParaOfSubjectName">ACCOUNTS</NavLink></p>
        </div>
        <div className="col-md-2 secondSubject individualSubjectDiv">
            <div className="secondImage"></div>
            <p><NavLink to={"/economics"} className="ParaOfSubjectName">ECONOMICS</NavLink></p>
        </div>
        <div className="col-md-2 thirdSubject individualSubjectDiv">
            <div className="thirdImage"></div>
            <p><NavLink to={"/finance"} className="ParaOfSubjectName">FINANCE</NavLink></p>
        </div>
        <div className="col-md-2 fourthSubject individualSubjectDiv">
            <div className="fourthImage"></div>
            <p><NavLink to={"/history"} className="ParaOfSubjectName">HISTORY</NavLink></p>
        </div>
        <div className="col-md-2 fifthSubject individualSubjectDiv">
            <div className="fifthImage"></div>
            <p><NavLink to={"/english"} className="ParaOfSubjectName">ENGLISH</NavLink></p>
        </div>
        <div className="col-md-2 sixthSubject individualSubjectDiv">
            <div className="sixthImage"></div>
            <p><NavLink to={"/mathematics"} className="ParaOfSubjectName">MATHEMATICS</NavLink></p>
        </div>
        <div className="col-md-2 seventhSubject individualSubjectDiv">
            <div className="seventhImage"></div>
            <p><NavLink to={"/it"} className="ParaOfSubjectName">IT</NavLink></p>
        </div>
        <div className="col-md-2 eighthSubject individualSubjectDiv">
            <div className="eighthImage"></div>
            <p><NavLink to={"/psychology"} className="ParaOfSubjectName">PSYCHOLOGY</NavLink></p>
        </div>
        <div className="col-md-2 ninthSubject individualSubjectDiv">
            <div className="ninthImage"></div>
            <p><NavLink to={"/political-science"} className="ParaOfSubjectName">POLITICAL SCIENCE</NavLink></p>
        </div>
        <div className="col-md-2 tenthSubject individualSubjectDiv">
            <div className="tenthImage"></div>
            <p><NavLink to={"/sociology"} className="ParaOfSubjectName">SOCIOLOGY</NavLink></p>
        </div>
</div>
</div>
</section>
    </>
  )
}

export default SubjectComponent;