import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from './Testimonial/Banner';
import CardTestimonial from './Testimonial/CardTestimonial';
import Quote from './Testimonial/Quote';

function DissertationTestimonial() {
  return (
    <>
    <Helmet>
          <title>Testimonial | Write My Thesis Paper For Me</title>
          <meta name="description" content="Don't trust your dissertation to a random service. Why not get testimonials from real students that have used our services? You won't regret it!" />
          <meta name="keywords" content="Dissertation writing Help, Dissertation Search, take my online dissertation, Do My Dissertation For Me, dissertation paper" />
          <link rel="canonical" href="https://www.writemydissertationforme.com/testimonial/" />
        </Helmet>
    <Banner />
    <CardTestimonial />
    <Quote />
    {/* <VideoTestimonial /> */}
    </>
  )
}

export default DissertationTestimonial;