import React, { useState } from 'react';
import academicLevel from '../Data/GetQuote/AcademicLevel';
import countryCode from '../Data/GetQuote/CountryCode';
import references from '../Data/GetQuote/References';
import style from '../Data/GetQuote/Style';
import typesOfServices from '../Data/GetQuote/TypeOfServices';
import '../Page/DissertationGetQuote.css';
import ToggleButton from '../SharedComponent/ToggleButton/ToggleButton';
// import ToggleSwitch from '../SharedComponent/ToggleSwitch/ToggleSwitch';
import { Helmet } from 'react-helmet';

function DissertationGetQuote() {
    const [putWordsOrPages, setPutWordsOrPages] = useState(true);
    // const [visible, setVisible] = useState(true);
    const [selectedYourTopic , setSelectedYourTopic] = useState(true);
    const [chooseTopic , setChooseTopic] = useState(false);

    function wordsOrPagesFunction(value) {
        setPutWordsOrPages(value);
    }

    const selectedYourTopicChange=(value)=>{
        setSelectedYourTopic(value);
    }

    const chooseTopicChange=(value)=>{
        setChooseTopic(value);
    }

  return (
    <>
    <Helmet>
          <title>Get Quote | Can Someone Write My Dissertation For Me</title>
          <meta name="description" content="Looking for affordable &amp; quality dissertation writing services? We offer top quality dissertation &amp; thesis writing service to busy students in every step." />
          <meta name="keywords" content="Dissertation Help, dissertation assignment help, Best Dissertation writing service, pay someone to write my dissertation, write my dissertation" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/getquotepage/" />
        </Helmet>
    <section className="getQuotePageStartHere">
        <div className="container-fluid getQuotePageFirstInnerDiv">
            <div className="row justify-content-center"><h2>REQUEST A QUOTE</h2></div>
            <div className="below-heading-line-get_quote"><span></span></div>
        </div>
    </section>
    <section className="getQuotePageFormSectionStartHere">
        <form action="/thankYouPage" name='contact-main' method="post" enctype="multipart/form-data" data-netlify="true" onSubmit="submit">

        <input type="hidden" name='form-name' value="contact-main" />

        <div className="container getQuotePageFormSectionFirstInnerDiv">
            <div className="row justify-content-evenly">
                <div className="col-md-12 mb-4 mt-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="name" className="col-form-label getQuoteFormLabel">Your Name</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input type="text" className="form-control getQuoteFormInputForPutValue" id="name" name="name" placeholder="Enter Your Name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="email" className="col-form-label getQuoteFormLabel">Your Email<span>*</span></label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input type="email" className="form-control getQuoteFormInputForPutValue" id="email" name="email" placeholder="yourname@gmail.com" required />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="reEmail" className="col-form-label getQuoteFormLabel">Re-Type Email<span>*</span></label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input type="email" className="form-control getQuoteFormInputForPutValue" id="reEmail" name="reEmail"
                        placeholder="yourname@gmail.com" required />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="phone" className="col-form-label getQuoteFormLabel">Phone</label>
                            </div>
                            <div className="col-sm-3 col-4 getQuoteFormSelectPhoneCodeDiv">
                            <select className="form-select getQuoteFormSelectPhoneCode" aria-label="Default select example" name='phone'>
                        <option selected disabled>Select Country Code</option>
                        {
                                countryCode.map((countryCodeData) => (
                                    <option key={countryCodeData.id} value={countryCodeData.value}>{countryCodeData.phoneCodeData}</option>
                                ))
                            }
                    </select>
                            </div>
                            <div className="col-sm-5 col-8 getQuoteFormSeccondInnerInputDiv">
                            <input type="text" className="form-control getQuoteFormInputForPutValue getQuoteFormInputPhoneNumber" id="phone" name="phone"
                        placeholder="Enter Your Phone Number" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="askYourTopic" className="col-form-label getQuoteFormLabel">Have You Selected Your Topic?</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            {/* <input type="radio" id="selectedYourTopicYes" name="selectedYourTopic" onClick={() => setVisible(true)} defaultChecked />
                            <label htmlFor="selectedYourTopicYes" className="ms-2">Yes</label>
                            <input type="radio" id="selectedYourTopicNo" name="selectedYourTopic" className="ms-5 radioInputPages" onClick={() => setVisible(false)} />
                            <label htmlFor="selectedYourTopicNo" className="ms-2">No</label> */}
                            <ToggleButton onChange={selectedYourTopicChange} defaultChecked={selectedYourTopic} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv" id='getQuoteFormChooseYourTopic'>
                    {selectedYourTopic ?
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="askYourTopic" className="col-form-label getQuoteFormLabel">Specify Your Topic</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input type="text" className="form-control getQuoteFormInputForPutValue" id="askYourTopic" name="askYourTopic"
                        placeholder="Type Your Topic" />
                            </div>
                        </div>
                    </div>
                    :''}
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    {!selectedYourTopic ?
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="askYourTopic" className="col-form-label getQuoteFormLabel">Do You Want To Choose A Topic?</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <ToggleButton onChange={chooseTopicChange} defaultChecked={chooseTopic} />
                            </div>
                           
                        </div>
                    </div>
                    :''}
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="typeOfService" className="col-form-label getQuoteFormLabel">Type Of Service<span>*</span></label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <select className="form-select" aria-label="Default select example" id="typeOfService"
                        name="typeOfService" required>
                        <option selected disabled>Types Of Service</option>
                        {
                            typesOfServices.map((typesOfServicesData) => (
                                <option key={typesOfServicesData.id} value={typesOfServicesData.value}>{typesOfServicesData.servicesData}</option>
                            ))
                        }
                    </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="deadline" className="col-form-label getQuoteFormLabel">Deadline In Days</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input type="text" className="form-control getQuoteFormInputForPutValue" id="deadline" name="deadline" placeholder="Number Of Days" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="askYourTopic" className="col-form-label getQuoteFormLabel">Spacing</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            {/* <input type="radio" id="single" name="spacingButton" />
                            <label htmlFor="single" className="ms-2">Single</label>
                            <input type="radio" id="double" name="spacingButton" className="ms-5 radioInputPages" defaultChecked />
                            <label htmlFor="double" className="ms-2">Double</label> */}
                            <ToggleButton onChange={state => console.log(state)} icons={{checked:<>Double</>,  unchecked:<>Single</> }} />
                            </div>
                            {/* <ToggleSwitch label="topic" /> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="askYourTopic" className="col-form-label getQuoteFormLabel">Will You Provide The Required Length In</label>
                            </div>
                            <div className="col-md-8 getQuoteFormRadioButtonWordsOrPages">
                            <ToggleButton icons={{checked:<>Words</>,  unchecked:<>Pages</> }} onChange={wordsOrPagesFunction} />
                            {/* <input type="radio" id="words" name="requiredLength" value={"Words"} onClick={wordsOrPagesFunction} />
                        <label htmlFor="words" className="ms-2">Words</label>
                        <input type="radio" id="pages" name="requiredLength" className="ms-5 radioInputPages" value={"Pages"} onClick={wordsOrPagesFunction} /><label htmlFor="pages" className="ms-2">Pages</label> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="provideTheLengthOfPagesOrWords" className="col-form-label getQuoteFormLabel">Length</label>
                            </div>
                            <div className="col-sm-7 col-10 getQuoteFormSeccondInnerInputDiv">
                            <input type="text" className="form-control getQuoteFormInputForPutValue" id="provideTheLengthOfPagesOrWords" name="provideTheLengthOfPagesOrWords" placeholder="Required Length" />
                            </div>
                            <div className="col-sm-1 col-2 getQuoteFormSeccondInnerInputDiv">
                            <span className="wordsOrPages ms-1">{putWordsOrPages ? "Words" : "Pages"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="academicLevel" className="col-form-label getQuoteFormLabel">Academic Level</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <select className="form-select" aria-label="Default select example" id="academicLevel" name="academicLevel">
                            <option selected disabled>Academic Level</option>
                        {
                            academicLevel.map((academicLevelData) => (
                                <option key={academicLevelData.id} value={academicLevelData.value}>{academicLevelData.styleData}</option>
                            ))
                        }
                    </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="subjects" className="col-form-label getQuoteFormLabel">Subject</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input className="form-control getQuoteFormInputForPutValue" type="text" id="subjects" name="subjects"
                        placeholder="Type Your Subject" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="style" className="col-form-label getQuoteFormLabel">Style</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <select className="form-select" aria-label="Default select example" id="style" name="style">
                            <option selected disabled>Style</option>
                        {
                            style.map((writingStyle) => (
                                <option key={writingStyle.id} value={writingStyle.value}>{writingStyle.styleData}</option>
                            ))
                        }
                    </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="references" className="col-form-label getQuoteFormLabel">No Of References</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <select className="form-select" aria-label="Default select example" id="references" name="references">
                            <option selected disabled>No Of References</option>
                        {
                            references.map((number) => (
                                <option key={number.id} value={number.value}>{number.referenceData}</option>
                            ))
                        }
                    </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="uploadFiles" className="col-form-label getQuoteFormLabel">Upload Files</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <input className="form-control getQuoteFormInputForPutValue" type="file" id="uploadFiles" name="uploadFiles" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv">
                    <div className="container-fluid">
                        <div className="row justify-content-evenly">
                            <div className="col-md-4">
                            <label htmlFor="commentBox" className="col-form-label getQuoteFormLabel">Comment Box</label>
                            </div>
                            <div className="col-md-8 getQuoteFormSeccondInnerInputDiv">
                            <textarea name="commentBox" cols="95" style={{width: "100%"}} id="commentBox" rows="7"
                        placeholder="Please Mention Their Specific Instruction..."></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4 getQuoteFormInputDiv getQuoteFormSubmitButtonDiv">
                <button type="submit" id="submitButton" name="submitButton" className="getQuoteFormSubmitButton">SUBMIT YOUR ORDER</button>
                </div>
            </div>
        </div>
        </form>
    </section>
    </>
  )
}

export default DissertationGetQuote;