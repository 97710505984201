import React from 'react'
import writeMyDissertationForMe from "../../Image/Landing_Page/content-writing-1.png"
import howWeWork from "../../Image/Landing_Page/how-we-work.png"
import whyChooseUs from "../../Image/Landing_Page/why-choose-us.png"
import '../LandingPage.css'

function WriteMyDissertation() {
  return (
    <>
     <section className="secondSectionStartHere my-2 py-3">
          <div className="container-fluid secondSectionInnerDiv">
              <div className="row justify-content-center">
                  <div className="col-lg-5 writeMyDissertationForMe">
                    <span className="box-1"></span><span className="heading-1">WRITE MY DISSERTATION FOR ME</span>
                    <p className="mt-3">When our experts help you, getting top grades is assured and easy. Our carefully chosen and reviewed experts are the top choice for students who want the best for their dissertation within a time-bound manner. Detailed industry experience, high-quality writing, research skills, and affordable dissertation writing services from our tutors provide customized original content for your dissertation papers. So, when you want the best for your work with a money back guarantee, you deserve highly reviewed writers of our tutor base.</p>
                  </div>
                  <div className="col-lg-5 image-section">
                      <img src={writeMyDissertationForMe} alt="write My Dissertation For Me" className="img-fluid" />
                  </div>
                  <div className="col-lg-5 image-section">
                    <img src={whyChooseUs} alt="dissertaion writing services" className="img-fluid"/>
                  </div>
                  <div className="col-lg-5 whyChooseUs">
                    <span className="heading-2">WHY CHOOSE US</span><span className="box-2"></span>
                    <p className="mt-3">Our students continue to repose faith in our work for the professional outlook and high-quality research work. PhD-level dissertation projects need a healthy mix of theory and practical knowledge, and our tutors with years of experience are best suited for the same. While we continue to provide premium and customized project writing services, our prices are affordable for clients to ensure that you get the best value while taking help from us. Come and discover our service-with-a-difference that vows students like you!</p>
                  </div>
                  <div className="col-lg-5 howWeWork">
                    <span className="box-3"></span><span className="heading-3">HOW WE WORK</span>
                    <p className="mt-3">New to our website and worried as to how we work? Do not sweat! We work in three simple steps. First, you provide details of your projects, and we send you a quote. Once initial formalities are done, our experienced tutor base starts to work on your project. Second, upon submitting the initial draft or outline, we seek your feedback. Upon receiving your feedback, we will start incorporating changes to the work. Third, the final manuscript and background data are provided for your review. If you have any queries or revision requests, just drop an email, and it will be done! Remember, we maintain a comprehensive revision policy until the work exactly matches your needs. That's it! Your project is ready to shine as a symbol of your academic achievement!</p>
                  </div>
                  <div className="col-lg-5 image-section">
                    <img src={howWeWork} alt="do my dissertation for me" className="img-fluid" />
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default WriteMyDissertation;