
 const academicLevel = [
    {
        id : 1,
        value : "UG - Undergraduate",
        styleData : "UG - Undergraduate"
    },
    {
        id : 2,
        value : "Graduate",
        styleData : "Graduate"
    },
    {
        id : 3,
        value : "PG - Post Graduate",
        styleData : "PG - Post Graduate"
    },
    {
        id : 4,
        value : "PhD",
        styleData : "PhD"
    }
];

export default academicLevel;