
const FAQDataOnSociologySubject = [
    {
        id : 1,
        question : "What is a sociology dissertation?",
        answer : "A sociology dissertation is a research project that explores social behavior, structures, institutions, and relationships within a specific context, using sociological theories and methodologies.",
    },
    {
        id : 2,
        question : "How do I choose a sociology dissertation topic?",
        answer : "Choosing a topic that contributes both to the field and societal well-being is crucial. Explore areas like gender issues, social media impacts, or immigration trends for meaningful and relevant sociology research.",
    },
    {
        id : 3,
        question : "How to write a sociology dissertation?",
        answer : "To write a sociology dissertation, develop a clear research question, conduct thorough literature review, choose an appropriate methodology, analyze data, and present findings with a strong connection to sociological theories.",
    },
    {
        id : 4,
        question : "Can I get help with data analysis for my sociology dissertation?",
        answer : "Yes, professional services can assist with both qualitative and quantitative data analysis, ensuring your findings are accurately interpreted and aligned with your research objectives.",
    },
    {
        id : 5,
        question : "What kind of data is typically collected in sociology dissertations?",
        answer : "Sociology dissertations may collect qualitative data (interviews, focus groups) or quantitative data (surveys, census data), depending on the research focus.",
    },

];

export default FAQDataOnSociologySubject;
