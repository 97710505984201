import React, { useState } from 'react'
import SubjectStatistics from '../../Data/LandingPage/SubjectStatistics'
import yearlySubjectData from '../../Data/LandingPage/YearlyData'
import CompletedDissertation from '../../Image/Landing_Page/Completed_Dissertation.png'
import QualifiedTutors from '../../Image/Landing_Page/Qualified_Tutors.png'
import StudentsAssisted from '../../Image/Landing_Page/Students_Assisted.png'
import SuccessRate from '../../Image/Landing_Page/Success_Rate.png'
import WordsWritten from '../../Image/Landing_Page/Words_Written.png'
import YearlyData from '../../Image/Landing_Page/Yearly_Data.png'
import socialStatiasticsPic from '../../Image/Landing_Page/social-statistics.png'
import '../LandingPage.css'

function SocialStatistics() {
    const [subjectData, setSubjectData] = useState(yearlySubjectData[0]);
    function dataInYear(e) {
        let data = yearlySubjectData.find(yearlyDataOfSubject => yearlyDataOfSubject.Year === parseInt(e.target.value))
        // console.log(data);
        setSubjectData(data);
    }
  return (
    <>
    <section className="socialStatisticsSectionStartHere">
                <div className="container socialStatisticsContainer">
                    <div className="row justify-content-between socialStatisticsThirdDiv">
                        <div className="col-md-12 my-3 py-3 socialStatisticsHeadingPart">
                            <h2 className="text-center"><span>SOCIAL STATISTICS</span></h2>
                            <div className="below-heading-line mt-2"></div>
                        </div>
                        <div className="col-lg-5 socialStatisticsImagePart my-2 py-2">
                            <img src={socialStatiasticsPic} alt={"Image-1"} className="img-fluid" />
                        </div>
                        <div className="col-lg-6 socialStatisticsDetailPart my-4 py-4">
                            <div className="d-flex socialStatisticsDetailPartInnerDiv">
                                <span className="qualifiedTutors">
                                    <p><span><img src={QualifiedTutors} alt="Image-2" className='mx-2 img-fluid' style={{width: "23px"}} /></span><span className="qualifiedTutorsSpan">Qualified Tutors</span></p>
                                    <p className="qualifiedTutorsPara">457</p>
                                </span>
                                <span className="completeDissertation ms-5">
                                    <p className="completeDissertationPara">54891</p>
                                    <p><span><img src={CompletedDissertation} alt="Image-3" className='mx-2 img-fluid' style={{width: "23px"}} /></span><span className="completeDissertationSpan">Completed Projects</span><span className='completeDissertationSpan'>(till date)</span></p>

                                </span>
                            </div>
                            <div className="d-flex socialStatisticsDetailPartInnerDiv">
                                <span className="subjectsSolved">
                                    <p><span><img src={YearlyData} alt="Image-4" className='mx-2 img-fluid' style={{width: "23px"}} /></span>
                                    <span className="subjectsSolvedSpan">Yearly Data
                                        <select onChange={dataInYear} id="selectYearlyDataOfStudents" className='selectedYearlyData'>
                                            {/* <option default>Year</option> */}
                                            {
                                                SubjectStatistics.map((statistics) => (
                                                    <option key={statistics.id} value={statistics.value}>{statistics.subjectData}</option>
                                                ))
                                            }
                                        </select>
                                    </span></p>
                                    <p className="subjectsSolvedPara">Management: <span className='paraOne'>
                                        {subjectData.management}
                                        </span></p>
                            <p className="subjectsSolvedPara">Marketing: <span className='paraTwo'>
                            {subjectData.marketing}
                                </span></p>
                            <p className="subjectsSolvedPara">Social Science: <span className='paraThree'>
                            {subjectData.socialScience}
                                </span></p>
                                    {/* <p className="subjectsSolvedPara">Medical: <span>25413</span></p> */}
                                    {/* <p className="subjectsSolvedPara">Psychology: <span>79464</span></p> */}
                                </span>
                                <span className="studentsEnrolled ms-5">
                                    <p className="studentsEnrolledSpan">35912</p>
                                    <p><span><img src={StudentsAssisted} alt="Image-5" className='mx-2 img-fluid' style={{width: "23px"}} /></span><span className="studentsEnrolledPara">Students Assisted</span><span className='studentsEnrolledPara'>(till date)</span></p>
                                </span>
                            </div>
                            <div className="d-flex socialStatisticsDetailPartInnerDiv">
                                <span className="wordsWritten">
                                    <p className="wordsWrittenPara">120 million</p>
                                    <p><span><img src={WordsWritten} alt="Image-6" className='mx-2 img-fluid' style={{width: "23px"}} /></span><span className="wordsWrittenSpan">Words Written</span><span className='wordsWrittenSpan'>(in total)</span></p>
                                </span>
                                <span className="successRate ms-5">
                                    <p><span><img src={SuccessRate} alt="Image-7" className='mx-2 img-fluid' style={{width: "23px"}} /></span><span className="successRateSpan">Success Rate</span></p>
                                    <p className="successRatePara">94.74%</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default SocialStatistics;