import React from 'react'
import '../FrequentlyAskedQuestions.css'

function Banner() {
  return (
    <>
     <section className="bannerSectionStartHere">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 bannerSectionHeading">
                    <p>FREQUENTLY ASKED QUESTIONS<br /> (FAQs)</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Banner;