import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FAQDataOnCapstoneProject from '../../Data/FAQs/FAQDataOnCapstoneProject';
import bannerImage from '../../Image/Dissertation_Capstone/pay someone to write my dissertation.png';
import arrowIcon from '../../Image/Dissertation_Literature_Review/Arrow.png';
import '../DissertationCapstone.css';

function QuestionFaqAndImage() {
  const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
  return (
    <>
      <section className="capstoneProjectLastQuestionAndFaqSection">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-7 col-12 capstoneProjectLastQuestion">
              <h2>RECENTLY COMPLETED CAPSTONE PROJECTS</h2>
              <p>Writing a capstone project is a daunting task for many students. It requires a lot of research, analysis, problem-solving skills, and a good grasp of the topic. The success of a capstone project depends on its quality and the ability of the author to present the information in a clear, organized, and well-structured way. Dissertation helps experts provide several tips to help students write a successful capstone project. Specifically, they discuss how to choose a topic, how to create an effective research plan, how to structure the project, and how to use different writing techniques.Dissertation writing services explain each of these steps in detail and provide examples to illustrate each point.</p>
              <p>Capstone project forms the foundation for research work within college life of students. It is an opportunity to demonstrate their understanding of the subject matter and to apply their knowledge and skills to a real-world problem. The complexity of the capstone project will have to be reviewed in a clear manner so that the core understanding of the research question would be properly reviewed. Dissertation writing services experts will provide tips for writing a successful capstone project. The overall development of the capstone project will be engaged and handled by the tutors who guide the students starting from selection of the topic and completion of all parts of the project. They provide a list of resources that students can use to help them in their capstone project. By following these tips, students will be well-prepared to write a successful capstone project.</p>
              <p>Writing a Capstone Project is a major undertaking for many students. It requires significant research and in-depth analysis of the subject matter. In order to ensure that the project is successful, it is important to consider several key factors when writing it. ‘Hire someone to write my dissertation’ services experts explore the tips and techniques that can help students write an effective and successful Capstone Project. Using their experience they are able to suggest tips connected with the process connected with structuring the paper, researching the topic, formulating an argument, and editing the final draft. Examples of successful Capstone Projects to demonstrate the importance of applying these tips are shared by tutors. As such,research students will have a better understanding of how to write a successful Capstone Project.</p>
              <p>Completing a dissertation is a major work area for academics. In order to craft a good dissertation, one needs to utilize extensive research and professional formatting, including a bibliography. A capstone dissertation project has to include a list of sources that inform a dissertation's research and writing. It provides readers details of authors, titles, publishers, and dates of the sources used. Dissertation helps tutors explain how to search and select scholarly sources, and how to correctly format a bibliography in the right citation style. Clear engagement of bibliography is followed by the tutors for enhancing the knowledge of the students. Major queries of 6the research students in terms of understanding importance of a bibliography, the different citation styles, and the best practices for writing a bibliography for a dissertation, research question management, data collection, etc are resolved and explained by experienced tutors.</p>
              <p>To ensure success in Capstone Projects, you need to consider several essential factors when you write the project. Effective tips and techniques that can help you create a successful Capstone Project are shared by dissertation writers. In particular, topics like structuring the paper, researching the topic, forming an argument, and editing the final draft will be crucial. Moreover, it is important to check the manner in which successful Capstone Projects can be completed within the given time frame. All these elements will create the foundation in which clear completion of the topic would be followed. All these aspects would be necessary for the purpose of dealing with the research work requirements. </p>
            </div>
            <div className="col-lg-5 col-12 dissertationCapstoneImportantServices">
              <ul>
                <li className="mt-4"><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">On Time Delivery</span></li>
                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">24 / 7 Availability</span></li>
                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">Instant Status Update</span></li>
                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">Dissertation Abstract</span></li>
                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">Plagiarism Free</span></li>
                <li><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">Affordable Price</span></li>
                <li className="mb-4"><img src={arrowIcon} alt="Pay someone to write my dissertation" className="img-fluid" /><span className="href ms-2">Privacy</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="dissertationCapstoneFaqAndGetDissertationHelpNowButtonSection">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-12 dissertationCapstoneFaqAndHelpNowSection">
              <button className="capstoneProjectGetDissertationHelpNow"><Link to={"/getQuote"}>GET CAPSTONE PROJECT HELP NOW</Link></button>
              <h2>HAVE ANY QUESTIONS</h2>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {
                  FAQDataOnCapstoneProject.map((item, i) => (
                    <>
                      <div className="faqParaAndHeadingDiv" key={item.id}>
                        <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                          <h5>{item.question}</h5>
                        </div>
                        <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                      </div>
                    </>
                  ))
                }
              </div>
              <p className="capstoneProjectReadMoreOnFaqSection"><span><Link to={"/faq"}>Read More on FAQ</Link></span></p>
            </div>
            <div className="col-lg-5 col-12 dissertationCapstoneRightSideImageFaqSection">
              <img src={bannerImage} alt="pay someone to write my dissertation" style={{ height: "300px", width: "400px" }} className="img-fluid capstoneImportantServicesImage" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default QuestionFaqAndImage;