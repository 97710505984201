import React, { useRef } from 'react'
import "../CommonPage/Navbar.css"
import logo from "../Image/Landing_Page/Final_Logo.png"
import { FaAlignJustify } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

const BelowNavbar = () => {
    const showAndHideNavMenu = useRef("");
    const toggleNavMenu = () => {
        showAndHideNavMenu.current.classList.toggle("show-main-nav-menu");
    }
    const hideNavMenuInMobile = () => {
      showAndHideNavMenu.current.classList.remove("show-main-nav-menu");
    }
  return (
    <>
    <nav className="below-navbar">
        <div className="toggle-icon">
        <span><i onClick={toggleNavMenu}><FaAlignJustify /></i></span>
        </div>
        <div className="below-navbar-image-div">
            <NavLink to={"/"} className="href"><img src={logo} alt="logo icon" className='img-fluid' onClick={hideNavMenuInMobile} /></NavLink>
        </div>
        <div className="below-nav-menu">
            <ul className='main-nav-menu' ref={showAndHideNavMenu}>
                <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationWritingServices"} className="href">DISSERTATION WRITING SERVICES</NavLink>
                    <ul className='sub-nav-menu'>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationProposal"} className="href">dissertation proposal</NavLink></li>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationAbstract"} className="href">dissertation abstract</NavLink></li>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationIntroduction"} className="href">dissertation introduction</NavLink></li>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationLiteratureReview"} className="href">dissertation literature review</NavLink></li>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationMethodology"} className="href">dissertation methodology</NavLink></li>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationConclusion"} className="href">dissertation conclusion</NavLink></li>
                        <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationBibliography"} className="href">dissertation bibliography</NavLink></li>
                    </ul>
                </li>
                <li onClick={hideNavMenuInMobile}><NavLink to={"/dissertationSubjects"} className="href">SUBJECTS</NavLink></li>
                <li onClick={hideNavMenuInMobile}><NavLink to={"/capstoneProjects"} className="href">CAPSTONE PROJECTS</NavLink></li>
                <li className='getQuoteListItem'><button className='getQuoteBtn' onClick={hideNavMenuInMobile}><NavLink to={"/getQuote"} className="href">GET QUOTE</NavLink></button></li>
            </ul>
        </div>
    </nav>
    </>
  )
}

export default BelowNavbar;
