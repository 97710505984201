import React from 'react'
import { NavLink} from 'react-router-dom';
import ErrorPageImage from '../Image/404_Error_Page/error_image.png'
import './ErrorPageNotFound.css'

function ErrorPageNotFound() {
  return (
    <>
    <section className="notFoundErrorPageStartHere">
        <div className="container-fluid notFoundErrorPageInnerDiv">
            <div className="row justify-content-evenly">
                <div className="col-md-8 errorPageImageDiv">
                    <img src={ErrorPageImage} alt="Do My Dissertation" className='img-fluid' />
                </div>
                <div className="col-md-4 errorPageHeadingAndButton">
                    <h2 className='firstHeading'>404</h2>
                    <h5 className='secondHeading'>Something went wrong</h5>
                    <button type='submit' className='errorPageButton'><NavLink to={"/"} className="href">GO TO HOME</NavLink></button>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ErrorPageNotFound;