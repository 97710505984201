import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import useWordCountTime from '../../../../Hooks/useWordCountTime'
import MainChapter from '../../../../Image/Blog_Page/Blog_Imge/Dissertation_and_Its_Main_Chapter.png'
import '../../Blog_Component/CSS_File/MainBlogPage.css'
import BlogAlsoRead from '../../Blog_Component/JS_File/BlogAlsoRead'
import BlogCommentForm from '../../Blog_Component/JS_File/BlogCommentForm'
import BlogSocialMediaIcon from '../../Blog_Component/JS_File/BlogSocialMediaIcon'

const DissertationMainChapter = () => {
    const [readingTime, findWord1, findWord2] = useWordCountTime();

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://www.writemydissertationforme.com/blogpage/dissertation-and-its-main-chapter/"
            },
            "headline": "DISSERTATION AND ITS MAIN CHAPTER",
            "description": "The five main chapters of dissertation which includes introduction, literature review, methodology, results and discussion, and its brief description.",
            "image": "https://www.writemydissertationforme.com/static/media/Dissertation_and_Its_Main_Chapter.704e2be7e461aa9f1be1.png",
            "author": {
                "@type": "Organization",
                "name": ""
            },
            "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                    "@type": "ImageObject",
                    "url": ""
                }
            },
            "datePublished": "2022-10-14",
            "dateModified": "2023-01-04"
        }`}
                </script>
                <title>Blog | Dissertation and Its Main Chapter</title>
                <meta name="description" content="The five main chapters of dissertation which includes introdution, literature review, methodology, results and discussion and its brief description." />
                <link rel="canonical" href="https://www.writemydissertationforme.com/blogPage/dissertation-and-its-main-chapter" />
            </Helmet>
            <section className="blogPageStartHere">
                <div className="container blogPageInnerDivStartHere">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blogTitleDateAndReadTime">
                            <h1>Dissertation and Its Main Chapter</h1>
                            <p><span>Oct 14 2023</span> | <span id="dissertationLiteratureReview">{readingTime} min read</span></p>
                        </div>
                        <div className="col-md-12 blogImageDiv">
                            <img src={MainChapter} alt="Dissertation and Its Main Chapter" className="img-fluid" />
                        </div>
                        <div className="col-md-12 blogPageContentFirst" ref={findWord1}>
                            <p>A dissertation is a long piece of written work that presents the research of a graduate student. It is typically required as part of a graduate degree program, such as a Master's or Doctoral degree, and serves as a capstone to the student's academic and professional training. The purpose of a dissertation is to add to the existing body of knowledge in a particular field of study, and it is typically based on original research conducted by the student. A dissertation typically includes an introduction, literature review, methodology, results, discussion, and conclusion, as well as a list of references. The length and scope of a dissertation can be different depending on the specific requirements of the degree program and the nature of the research being conducted.</p>
                            <p>The five main chapters of a dissertation are</p>
                            <ol>
                                <li><strong>Dissertation Introduction</strong>: This introduces the topic of the dissertation and provides background information on the research problem being addressed.</li>
                                <li><strong>Dissertation Literature review</strong>: Review of the existing literature on the topic of the dissertation. It may include a summary of relevant research and theories, as well as a discussion of their implications for the current study.</li>
                                <li><strong>Dissertation Methodology</strong>: This describes the research methods used in the study, including the research design, sample, data collection and analysis procedures, and any statistical tests that were used.</li>
                                <li><strong>Dissertation Results</strong>: This presents the findings of the study, including any statistical analyses or other data.</li>
                                <li><strong>Dissertation Discussion</strong>: This interprets the results of the study and discusses their implications for the research question being addressed.</li>
                            </ol>
                            <h2><Link to={"/dissertationIntroduction"}>Dissertation Introduction</Link></h2>
                            <p>The introduction of a dissertation is the first chapter and is usually written last. It serves several important functions:</p>
                            <ul>
                                <li>It introduces the topic of the dissertation and provides background information on the research problem being addressed.</li>
                                <li>It explains the purpose of the study and the research questions or hypotheses being tested.</li>
                                <li>It provides a summary of the structure of the dissertation and the different chapters or sections that will follow.</li>
                                <li>It situates the study within the broader context of the existing literature on the topic, highlighting the contributions and gaps that the research aims to address.</li>
                                <li>It establishes the significance of the study and why it is worth doing.</li>
                            </ul>
                            <p>The introduction should be written in a clear and concise manner and should be engaging and interesting to the reader. It should also provide a roadmap for the rest of the dissertation, outlining the main points that will be covered in each chapter.</p>
                            <h2><Link to={"/blogPage/how-to-write-a-dissertation-literature-review"}>Dissertation Literature Review</Link></h2>
                            <p>The literature review in dissertation that presents a summary of the existing research on the topic of the study. It serves several important purposes:</p>
                            <ul>
                                <li>It provides context for the research being conducted by situating the study within the broader field of knowledge.</li>
                                <li>It identifies the gap in the existing literature that the research aims to fill.</li>
                                <li>It reviews the relevant theories and research findings and critically evaluates their strengths and limitations.</li>
                                <li>It synthesizes the existing knowledge on the topic, highlighting any conflicting views or controversies.</li>
                                <li>It defines the key terms and concepts used in the study.</li>
                            </ul>
                            <p>The literature review in dissertation should be organized around specific themes or topics and should be written clearly and logically. It should also be properly cited, using the appropriate referencing style. The literature review should not simply be a list of summaries of individual studies but should instead provide an overall synthesis of the existing knowledge on the topic.</p>
                            <h2><Link to={"/dissertationMethodology"}>Dissertation Methodology</Link></h2>
                            <p>The methodology section of a dissertation is a section that explains the main methods and techniques that were used in the research study. It includes a detailed description of the research design, the sample size and selection, the data collection procedures, and the data analysis techniques. The motive of the methodology chapter is to provide a clear and transparent account of how the research was conducted so that the reader can understand and evaluate the results.</p>
                            <p>The methodology chapter should include the following elements:</p>
                            <ul>
                                <li><strong>Research design</strong>: This describes the overall approach to the research, including the type of study (e.g., experimental, survey, case study), the research question or hypothesis being tested, and the variables being measured.</li>
                                <li><strong>Sample</strong>: This describes the participants or subjects of the study, including their characteristics and how they were selected.</li>
                                <li><strong>Data collection</strong>: This describes the methods used to collect data, such as surveys, interviews, or observations, and any relevant details about the data collection process (e.g., sampling method, response rate).</li>
                                <li><strong>Data analysis</strong>: This describes the statistical methods or other techniques used to analyze the data, including any software or tools that were used.</li>
                            </ul>
                            <p>It is important that the methodology chapter is written in a brief and concise manner and that it be sufficiently detailed to allow the reader to understand and replicate the research if needed. The methodology chapter should also be properly cited, using the appropriate referencing style.</p>
                        </div>
                        <div className="col-md-12 blogPageContentSecond" ref={findWord2}>
                            <h2>Dissertation Results</h2>
                            <p>The results chapter of the dissertation presents the findings of the study. It includes a summary of the data collected, as well as any statistical analyses or other methods of data analysis that were used. The purpose of the results chapter is to present the main findings of the research in a clear and concise manner, without any interpretation or discussion.</p>
                            <p>The results chapter should be organized around the research questions or hypotheses that were tested in the study. For each research question, the results should be presented in a logical and coherent manner, using tables, figures, and other visual aids as needed to help illustrate the findings. Any statistical analyses should be reported in detail, including the statistical tests used, the sample size, the degrees of freedom, and the p-values.</p>
                            <p>It is important that the results chapter should not include any interpretation or discussion of the findings. This should be reserved for the discussion chapter. The results chapter should also be properly cited, using the appropriate referencing style.</p>
                            <h2>Dissertation Discussion</h2>
                            <p>The discussion chapter of a dissertation is where the main findings of the study are interpreted and discussed. It is the place where the researcher can provide their own insights and perspectives on the results and relate them to the existing literature on the topic. The discussion chapter should address the following questions:</p>
                            <p>What are the main findings of the study, and how do they relate to the research questions or hypotheses?</p>
                            <p>How do the findings compare to those of other studies on the same topic?</p>
                            <p>What are the involvements in the findings for theory, practice, and future research?</p>
                            <p>Are there any limitations to the study that should be considered when interpreting the results?</p>
                            <p>The discussion chapter should be written in a clear and logical manner and should be properly cited, using the appropriate referencing style. It should also be carefully proofread for grammar and spelling errors.</p>
                            <h2><Link to={"/dissertationBibliography"}>Dissertation Bibliography</Link></h2>
                            <p>A dissertation bibliography provides a list of sources and additional information on a topic that was not necessarily cited in the text of a dissertation. It is similar to a references section but includes sources that were not directly cited in the text but that were consulted in the process of researching and writing the dissertation.</p>
                            <p>The dissertation bibliography should be organized alphabetically by the author's last name and should follow the style guidelines of the chosen referencing system (e.g., APA, MLA, Chicago). It should include all sources that were consulted in the process of researching and writing the dissertation, regardless of whether they were directly cited in the text or not.</p>
                            <p>The main objective of the dissertation bibliography is to provide a complete picture of the research that was conducted and to give credit to the sources that were used in the process. It can be especially useful for readers who are interested in pursuing further research on the topic. It is important to ensure that all sources included in the bibliography are complete and accurate and that they are formatted consistently with the chosen style guide.</p>
                        </div>
                        <BlogSocialMediaIcon />
                    </div>
                </div>
            </section>
            <BlogCommentForm />
            <BlogAlsoRead />
        </>
    )
}

export default DissertationMainChapter