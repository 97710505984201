import React from 'react'
import '../DissertationProposal.css'
import '../../CommonPage/Carousel.css'
import ProposalBannerForm from './ProposalBannerForm';

function ProposalBanner() {
  return (
    <>
    <section className="dissertationProposalBannerImageSection">
        <div className="container-fluid dissertationProposalInnerDivOfBannerImage">
            <div className="row justify-content-center dissertationproposalRowSection">
                <div className="col-md-7 dissertationProposalBannerImageHeadingAndForm">
                    {/* <h2>GET YOUR TASK COMPLETED</h2> */}
                    <ProposalBannerForm />
                </div>
                <div className="col-md-5 hideThisDivSection"></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ProposalBanner;