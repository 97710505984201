import React from 'react'
import { NavLink } from 'react-router-dom'
import CarouselAndBannerImageForm from '../CommonPage/CarouselAndBannerImageForm'
import DissertationWS2 from "../Image/Dissertation_Writing_Services/importance_of_dissertation.webp"
import DissertationWS1 from "../Image/Dissertation_Writing_Services/what_is_dissertation.webp"
import FAQCompOnDWServices from "../Page/FAQsComponentOnDPages/FAQCompOnDWServices"
import "./DissertationWritingServicesPage.css"
import CostOfDissertation from './Home/CostOfDissertation'
import CardTestimonial from './Testimonial/CardTestimonial'

const DissertationWritingServicesPage = () => {
  return (
    <>
      <section className="firstSectionOfDissertationWritingServicesPageStartHere">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 firstSectionHeadingDiv">
              <h1>Dissertation services available</h1>
              <p>Professional Dissertation Writing Services for Academic Success</p>
              <div className="carouselBannerImageFormDiv">
                <CarouselAndBannerImageForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secondSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 secondSectionOfHeadingDiv">
              <h2>what is dissertation</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="secondSectionOfDissertationWritingServicesPageStartHere">
        <div className="container-fluid secondSectionDissertationWritingServicesPageFirstInnerDiv">
          <div className="row justify-content-evenly secondSectionDissertationWritingServicesPageSecondInnerDiv">
            <div className="col-lg-5 secondSectionParaDiv">
              <p>A dissertation is an extensive academic paper that marks the culmination of a master's or doctoral program. It involves conducting original research on a specific topic, analyzing existing literature, and presenting findings in a structured format. The goal is to contribute new knowledge or insights to the chosen field of study. Completing a dissertation demonstrates a student's expertise, critical thinking, and research abilities, making it a key requirement for graduation in advanced academic programs. It is often the final step before obtaining a degree.</p>
            </div>
            <div className="col-lg-5 secondSectionImgDiv">
              <img src={DissertationWS1} alt="what is dissertation?" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section className="thirdSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 thirdSectionOfHeadingDiv">
              <h2>importance of dissertation</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="thirdSectionOfDissertationWritingServicesPageStartHere">
        <div className="container-fluid thirdSectionDissertationWritingServicesPageFirstInnerDiv">
          <div className="row justify-content-evenly thirdSectionDissertationWritingServicesPageSecondInnerDiv">
            <div className="col-lg-5 thirdSectionImgDiv">
              <img src={DissertationWS2} alt="importance of dissertation?" className='img-fluid' />
            </div>
            <div className="col-lg-5 thirdSectionParaDiv">
              <p>A dissertation is important because it allows students to demonstrate their in-depth knowledge, research abilities, and critical thinking in a specific field of study. It is a key requirement for completing advanced degrees, such as a master's or PhD. Writing a dissertation contributes new ideas or research to a field, showcasing the student’s ability to work independently. For those asking, <strong>Can someone write my dissertation for me?</strong> professional services can offer valuable support and guidance throughout the process, ensuring quality and timely submission, while still highlighting the importance of the student’s personal involvement in the research journey.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="fourthSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 fourthSectionHeadingDiv">
              <h2>We do complete Dissertation</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="fourthSectionOfDissertationWritingServicesPageStartHere">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-6 dissertationServicesCardMainDiv dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationProposal"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Proposal</h5>
                  <p className="dissertationServicesPara">Need help with your dissertation proposal? Our experts create custom, high-quality proposals to boost your academic success.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationAbstract"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Abstract</h5>
                  <p className="dissertationServicesPara">Get a clear, concise dissertation abstract crafted by our experts to highlight your research effectively.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationIntroduction"}>
                <div className="dissertationServicesCard dissertationServicesCardHeightClassName">
                  <h5>Dissertation Introduction</h5>
                  <p className="dissertationServicesPara">Need a strong start? Our experts create impactful dissertation introductions that engage readers from the beginning.</p>
                  <p className="dissertationServicesLearnMoreBtn learnMoreLink">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationLiteratureReview"}>
                <div className="dissertationServicesCard dissertationServicesCardHeightClassName">
                  <h5>Dissertation Literature Review</h5>
                  <p className="dissertationServicesPara">Having trouble with your literature review? Our experts offer a comprehensive analysis of essential research for your dissertation.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationMethodology"}>
                <div className="dissertationServicesCard dissertationServicesCardHeightClassName">
                  <h5>Dissertation Methodology</h5>
                  <p className="dissertationServicesPara">Struggling with your methodology? Our experts craft detailed, strategic approaches to enhance your dissertation research.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationConclusion"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Conclusion</h5>
                  <p className="dissertationServicesPara">Finalize your research with a strong conclusion. Our experts craft compelling summaries to highlight your dissertation’s impact.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 dissertationServicesCardMainDiv">
              <NavLink to={"/dissertationBibliography"}>
                <div className="dissertationServicesCard">
                  <h5>Dissertation Bibliography</h5>
                  <p className="dissertationServicesPara">Need help with your bibliography? Our experts ensure accurate, well-organized citations to support your dissertation.</p>
                  <p className="dissertationServicesLearnMoreBtn">Learn More</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="fifthSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 fifthSectionHeadingDiv">
              <h2>Write My Thesis Paper For Me</h2>
              <p>Need help with your thesis? Our expert writers provide top-quality, custom thesis papers tailored to your academic needs. We handle everything from research and writing to editing, ensuring your thesis meets all academic standards. Whether you're struggling with a specific chapter or need a full thesis written, we offer personalized support to help you succeed. Our services guarantee plagiarism-free content, timely delivery, and a focus on academic excellence. Let us take the stress out of your thesis journey.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="sixthSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 sixthSectionHeadingDiv">
              <h2>Pay Someone To Write My Research Paper </h2>
              <p>Need help with your research paper? <strong>Pay someone to write my research paper</strong> and get expert assistance from professional writers. We deliver high-quality, original content tailored to your requirements, helping you meet tight deadlines and achieve academic success. Our experienced writers ensure thorough research, proper formatting, and plagiarism-free work. Whether you're short on time or struggling with complex topics, our service provides a stress-free solution. Pay someone to write my research paper today and boost your grades with confidence! Let us handle the hard work for you.</p>
            </div>
          </div>
        </div>
      </section>
      <CostOfDissertation />
      <FAQCompOnDWServices />
      <section className="seventhSectionOfDissertationWritingServicesHeading">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 seventhSectionOfHeadingDiv">
              <h2>testimonial</h2>
            </div>
          </div>
        </div>
      </section>
      <section className='seventhSectionOfDissertationWritingServicesPageStartHere'>
        <CardTestimonial />
      </section>
    </>
  )
}

export default DissertationWritingServicesPage;