import React from 'react';

function Quote() {
  return (
    <>
    <section className="dissertationTestimonialQuoteSectionStartHere mb-4 pb-4">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <p className="dissertationTestimonialQuote">
                    “WriteMyDissertationForMe delivered beyond my expectations! Their expert writers provided a high-quality, plagiarism-free dissertation that was well-researched and on time. I highly recommend their professional academic services.”
                    </p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Quote;