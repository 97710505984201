import React from 'react'
import '../../Page/DissertationTestimonial.css'

function CustomerViewCard(props) {
    let {imageSource, title, studentsName, content, altTag} = props.data;
  return (
    <>
    <div className="container testimonialCardSection">
      <div className="row justify-content-center">
        <img src={imageSource} alt={altTag} className='testimonialCardImage img-fluid' />
        <div className="card-body testimonialCardBody">
          <h2 className='testimonialCardHeading'>{title}</h2>
          <p className='testimonialCardPara'>{content}</p>
          <p className='testimonialCardStudentName'>{studentsName}</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default CustomerViewCard;