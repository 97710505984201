import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useWordCountTime from '../../../../Hooks/useWordCountTime';
import DissertationLiteratureReview from '../../../../Image/Blog_Page/Blog_Imge/18_11_22_How to Write A Dissertation Literature.webp';
import '../../Blog_Component/CSS_File/MainBlogPage.css';
import BlogAlsoRead from '../../Blog_Component/JS_File/BlogAlsoRead';
import BlogCommentForm from '../../Blog_Component/JS_File/BlogCommentForm';
import BlogSocialMediaIcon from '../../Blog_Component/JS_File/BlogSocialMediaIcon';

const WriteDissertationLiteratureReview = () => {
    const [readingTime, findWord1, findWord2] = useWordCountTime();

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.writemydissertationforme.com/blogPage/how-to-write-a-dissertation-literature-review"
            },
            "headline": "How to write dissertation literature review?",
            "description": "What is a literature review? how to find the sources and identify the gaps and why is it essential to write a dissertation literature review.",
            "image": "https://www.writemydissertationforme.com/static/media/18_11_22_How%20to%20Write%20A%20Dissertation%20Literature.cf55618058f6a848f2ea.webp",  
            "author": {
              "@type": "Organization",
              "name": ""
            },  
            "publisher": {
              "@type": "Organization",
              "name": "",
              "logo": {
                "@type": "ImageObject",
                "url": ""
              }
            },
            "datePublished": "2022-08-12",
            "dateModified": "2022-11-18"          
        }`}
                </script>
                <title>Blog | How To Write A Dissertation Literature Review</title>
                <meta name="description" content="What is a literature review? how to find the sources and identify the gaps and why is it essential to write a dissertation literature review." />
                <meta name="keywords" content="Write Dissertation for me, Dissertation literature review, Hire someone to write my dissertation, do my dissertation literature review, Write dissertation literature review for me" />
                <link rel="canonical" href="https://www.writemydissertationforme.com/blogPage/how-to-write-a-dissertation-literature-review" />
            </Helmet>
            <section className="blogPageStartHere">
                <div className="container blogPageInnerDivStartHere">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blogTitleDateAndReadTime">
                            <h1>How To Write A Dissertation Literature Review?</h1>
                            <p><span>Aug 12 2023</span> | <span id="dissertationLiteratureReview">{readingTime} min read</span></p>
                        </div>
                        <div className="col-md-12 blogImageDiv">
                            <img src={DissertationLiteratureReview} alt="How to write a disseration abstract" className="img-fluid" />
                        </div>
                        <div className="col-md-12 blogPageContentFirst" ref={findWord1}>
                            <p>No idea! Don't know what a literature review for a dissertation is. How to write a dissertation literature review? We will cover everything step-by-step on researching and writing a good dissertation literature review.
                            </p>
                            <h2>What is a Dissertation Literature Review?</h2>
                            <p>A literature review is research on various sources about a specific subject. It provides you with knowledge of the subject and identifies the gaps and relevant theories in the existing research.</p>
                            <p>Many misinterpretations are on the dissertation literature review and what a literature review is, and how to convey or interpret it. In some cases, a literature review would summarize the sources(literature) that have been researched—providing positive and negative opinions on the sources or arguing with the different viewpoints on the sources with text. Or you have to be deeply engaged with the sources and provide all sorts of outcomes and opinions.
                            </p>
                            <p>While the summary will provide a recap of the sources of the literature review, a literature review may provide a new view on a research paper or combine both new and old interpretations.
                            </p>
                            <p>A literature review tends to summarise and provide a deep analysis of the research arguments you found in the readings without making new contributions to the literature.</p>
                            <p>Confusion arises between literature reviews and academic papers, but they are different. The main goal of academic papers is to provide novel research material about a subject, and literature review features as a part of this objective. A literature review is a base for the research paper to help highlight research gaps to support new arguments and insights you provide.</p>
                            <h2>Does a Literature Review Essential?</h2>
                            <p>As of now, we understand the literature review, and the next step is to write a literature review. A literature review is essential to any academic research paper as it shows your reader that you understand the sources about your research area and subject.</p>
                            <p>A literature review helps to induce the person to read and mark the assignment that you have written, which is relevant and can be defended the arguments and worthwhile. So a literature review is necessary, and you must invest time to get it right.</p>
                        </div>
                        <div className="col-md-12 blogPageContentSecond" ref={findWord2}>
                            <h2>How to write a Dissertation Literature Review?</h2>
                            <p>To write a dissertation literature review, you start by identifying the sources and then read and re-read them. Find any gaps in the literature sources and finally review using all information gathered and write the dissertation literature review.</p>
                            <h3>Identifying sources</h3>
                            <p>To write a dissertation literature review, you need to have adequate knowledge of the sources you would like to review. You can refer to the earlier recommended techniques if you haven't been given a reference list.</p>
                            <p>You can get the sources by including academic journals, relevant books, and any published articles from reputed scholars, which should balance the sources you are referring to. You might think about the parameters and objectives of the research before choosing all the sources. To identify the gaps in a literature review, you can consider the theoretical issues or any perspective you aim to solve. Focusing mainly on qualitative and quantitative studies can help you to select your sources, and the abstract of the source is also very useful. You can quickly scan the keywords in the abstract, which will indicate if the source is useful for your research or not.</p>
                            <p>While identifying the sources, keep a list, or you can lose focus with much information on the internet. You can use some tools to store your sources online or on your desktop, which is an excellent way to keep your bibliography organized. You can use citation tools for these sources when you require you can export them. It will save you more time. Or you can get help from the dissertation writing services to help you find the relevant sources to write a literature review from <Link to={"/dissertationLiteratureReview"}>Write My Dissertation For Me</Link>. They help find relevant sources for your dissertation literature review and help find the gaps and relevant information.</p>
                            <h3>Read Sources</h3>
                            <p>Till now, you have organized your sources and read them thoroughly. It is better to read all the sources step by step. In the first step, go through all the text to get the general content idea and arguments, which will help you to conclude and focus on the review. And in the next step, try to read, think and analyze the sources and ask questions about your opinions or if you have any comments on the approach or the theoretical arguments. Make a Note of everything in every step, all of the questions, comments, and the approach, and you will find it is not a summary anymore, and it will encourage you to do the work which is logical and coherent.</p>
                            <h3>Identifying gaps in the research</h3>
                            <p>Many students find it very difficult to identify the gaps in the research. So it is essential to find the gaps and prepare research questions that can gap to form a research paper. To write a dissertation literature review, you have to find the relevant gaps in your literature sources. You can put your positive or negative opinions and viewpoints into the existing research. It shows how well you know about the chosen field of study and demonstrates the effort and analysis you have put into the review.</p>
                            <p>If you need help identifying all the sources and the gaps in your dissertation literature review, you must complete it within a deadline. You can choose a <Link to={"/dissertationWritingServices"}>dissertation writing service</Link> to help you write your literature review.</p>
                        </div>
                        <BlogSocialMediaIcon />
                    </div>
                </div>
            </section>
            <BlogCommentForm />
            <BlogAlsoRead />
        </>
    );
}

export default WriteDissertationLiteratureReview;