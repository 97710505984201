import React from 'react';
import cardImageThree from '../../Image/About-Us-Page/Best Dissertation writing service.png';
import cardImageTwo from '../../Image/About-Us-Page/Can I Hire Someone To Write My Dissertation Online.png';
import cardImageFour from '../../Image/About-Us-Page/Do My Dissertation.png';
import cardImageOne from '../../Image/About-Us-Page/i need help with my dissertation.png';
import cardImageFive from '../../Image/About-Us-Page/write my dissertation for me.png';
import '../AboutUsPage.css';

function WhyChooseUs() {
  return (
    <>
    <section className="aboutUsWhyChooseUsSectionStartHere">
        <div className="container whyChooseUsFirstInnerDivSection">
            <div className="row justify-content-center">
                <div className="col-md-12 whyChooseUsHeading">
                    <h2><span>WHY CHOOSE US</span></h2>
                </div>
                <div className="col-lg-4 col-md-5 col-xl-4 whyChooseUsCardUnderAboutUs">
                    <img src={cardImageOne} alt="i need help with my dissertation" className="img-fluid card-img-top cardImage" />
                    <div className="card-body aboutUsCardBody" id='aboutUsQualityWork'>
                        <h5>QUALITY WORK</h5>
                    <p>Deliver a dissertation writing service that meets the highest academic standards with articulated sentences and perfect grammar.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 col-xl-4 whyChooseUsCardUnderAboutUs">
                    <img src={cardImageTwo} alt="Can I Hire Someone To Write My Dissertation Online" className="img-fluid card-img-top cardImage" />
                    <div className="card-body aboutUsCardBody" id='aboutUsPrivacy'>
                        <h5>PRIVACY</h5>
                    <p>Your data is yours to use, not ours and not of the experts. Neither do we share any of your private and confidential information with anyone nor do we disclose who our clients are.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 col-xl-4 whyChooseUsCardUnderAboutUs">
                    <img src={cardImageThree} alt="Best Dissertation writing service" className="img-fluid card-img-top cardImage" />
                    <div className="card-body aboutUsCardBody" id='aboutUsSupport'>
                        <h5>24*7 SUPPORT</h5>
                    <p>We are new age tech enabled company that are available 24*7 to ensure you get a response in the blink of an eye.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 col-xl-4 whyChooseUsCardUnderAboutUs whyChooseUsCardNumberFourForCenter">
                    <img src={cardImageFour} alt="Do My Dissertation" className="img-fluid card-img-top cardImage" />
                    <div className="card-body aboutUsCardBody" id='aboutUsTransparency'>
                        <h5>TRANSPARENCY</h5>
                    <p>Pricing and communication are kept very simple in order to avoid any kind of confusion.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 col-xl-4 whyChooseUsCardUnderAboutUs whyChooseUsCardNumberFiveForCenter">
                    <img src={cardImageFive} alt="write my dissertation for me" className="img-fluid card-img-top cardImage" />
                    <div className="card-body aboutUsCardBody" id='aboutUsPrice'>
                        <h5>AFFORDABLE PRICE</h5>
                    <p>Get the best quality academic content delivered to you at an unbelievable and affordable price. The most pocket friendly service company you will ever come across.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default WhyChooseUs;