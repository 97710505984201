
const FAQDataOnAccountsSubject = [
    {
        id : 1,
        question : "Which are the interesting accounting dissertation topics you can provide for undergraduate students?",
        answer : <span>writemydissertationforme provides a plethora of free accounting topics for undergraduate, graduate, and PhD students. Other interesting accounting dissertation topics are here for you:<br />
        <ol>
            <li>1. Worldwide tax Vs. territorial tax</li>
            <li>Forensic accounting- the art of resolving the financial puzzle</li>
            <li>Management accounting techniques in firms</li>
            <li>Effective strategies for bookkeeping</li>
            <li>Importance of public sector accounting in the 21st century</li>
            <li>Describe the cost accounting standards</li>
        </ol></span>,
    },
    {
        id : 2,
        question : "Can I communicate directly with the writer working on my dissertation?",
        answer : `Yes, we encourage direct communication between students and their assigned writers. This ensures that your specific instructions are followed, and you can provide feedback or ask for updates throughout the writing process.`,
    },
    {
        id : 3,
        question : "Is my personal and academic information kept confidential?",
        answer : "Absolutely. We prioritize your privacy and confidentiality. We use secure systems to protect your personal and payment details, and we never share your information with third parties. Your identity and project details remain strictly confidential throughout the entire process.",
    },
    {
        id : 4,
        question : "Do accounting students do a dissertation?",
        answer : "Completing a dissertation is an important task for accounting students, requiring hard work and dedication. In this article, readers will gain insight into the process of writing a successful accounting dissertation. Tips and tricks are provided to ensure that the process is both efficient and effective.",
    },
    {
        id : 5,
        question : "How can I ensure that my accounting research is relevant and impactful?",
        answer : "Our writers can identify the issues and provide proper evidence for the arguments. They research in-depth from authentic sources. Apart from that, our stalwarts have long years of experience in writing accounting dissertations. So, no matter how complex the topic is. They will write relevant and impactful dissertations for you.",
    },

];

export default FAQDataOnAccountsSubject;
