import React from 'react'
import CarouselData from '../Data/LandingPage/CarouselData';
import './Carousel.css'
import UseEmailValidation from '../Hooks/UseEmailValidation';

function CarouselAndBannerImageForm() {
    const [writingServices, dissertationWritingServices, email, carouselEmail, reEmail, carouselReEmail, enquireNowButton] = UseEmailValidation();

    return (
        <>
           <form action='/thank-you' name='contact-quick-carousel' method="post" enctype="multipart/form-data" data-netlify="true" onSubmit="submit" className="dissertationCarouselQuickQueryForm" id="dissertationCarouselQuickQueryForm">
                <input type="hidden" name='form-name' value="contact-quick-carousel" />
                <select className="dissertationCarouselFormSelect" name='typeOfPaper' value={writingServices} onChange={dissertationWritingServices} >
                    <option selected disabled>Type of Paper</option>
                    {
                        CarouselData.map((typeOfPaper) => (
                            <option key={typeOfPaper.id} value={typeOfPaper.value}>{typeOfPaper.typeOfPaperData}</option>
                        ))
                    }
                </select>

                <input type="email" name="carouselEmail" id="carouselEmail" className='carouselEmail ms-2' placeholder="Enter Your Email" value={email} onChange={carouselEmail} required />

                <input type="email" name="carouselReEmail" id="carouselReEmail" className='carouselEmail ms-2' placeholder="Confirm Your Email" ondrop="return false;" autocomplete="off" value={reEmail} onChange={carouselReEmail} onPaste = {(e) => {e.preventDefault(); return false;}} required />
                <div id="smallAlertEmailBox" className='smallAlertEmailBox'></div>

                <div className="dissertationCarouselSubmitButtonDiv">
                    <button type='submit' className="dissertationCarouselEnquireNowButton" id='dissertationCarouselEnquireNowButton' ref={enquireNowButton}>ENQUIRE NOW</button>
                </div>
            </form>
        </>
    )
}

export default CarouselAndBannerImageForm;