import React from 'react'
import '../../Page/DissertationTestimonial.css'

function Banner() {
  return (
    <>
     <section className="testimonialBannerSectionStartHere">
        <div className="container testimonialBannerSectionFirstInnerDiv">
            <div className="row justify-content-center">
                <div className="col-md-12"></div>
            </div>
        </div>
    </section>
    <div className="container givingLineBelowBannerImage">
        <div className="row justify-content-center">
            <div className="col-md-12 givingLineBelowTheUpperDiv"></div>
        </div>
    </div>
    </>
  )
}

export default Banner;