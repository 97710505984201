import React from 'react'
import '../DissertationCapstone.css'

function CapstoneStatistics() {
  return (
    <>
     <section className="capstoneStatisticsSectionStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 capstoneStatisticsInnerDiv">
                    <h2><span>CAPSTONE STATISTICS</span></h2>
                </div>
                <div className="col-lg-2 capstoneStatisticsSmallSection">
                    <p className="capstoneStatisticsData"><span className="capstoneStatisticsDataNumberOne">457</span></p>
                    <p className="capstoneStatisticsPara">QUALIFIED TUTORS</p>
                </div>
                <div className="col-lg-2 capstoneStatisticsSmallSection">
                    <p className="capstoneStatisticsData"><span>35.9K</span></p>
                    <p className="capstoneStatisticsPara">STUDENTS ENROLLED</p>
                </div>
                <div className="col-lg-2 capstoneStatisticsSmallSection">
                    <p className="capstoneStatisticsData"><span>94.7%</span></p>
                    <p className="capstoneStatisticsPara">SUCCESS RATE</p>
                </div>
                <div className="col-lg-2 capstoneStatisticsSmallSection">
                    <p className="capstoneStatisticsData"><span>54.8K</span></p>
                    <p className="capstoneStatisticsPara">DISSERTATION</p>
                </div>
                <div className="col-lg-2 capstoneStatisticsSmallSection">
                    <p className="capstoneStatisticsData"><span>120M</span></p>
                    <p className="capstoneStatisticsPara">WORDS WRITTEN</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default CapstoneStatistics;