import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import '../AboutUsPage.css';

function HaveAnyQuestions() {
    const [showAndHide, setShowAndHide] = useState(false);
  return (
    <>
    <section className="haveAnyQuestionsContainerStartHere">
        <div className="container">
            <div className="row justify-content-evenly">
                <div className="col-md-12 haveAnyQuestionsHeading">
                    <h2><span>HAVE ANY QUESTIONS</span></h2>
                </div>
                <div className="col-md-5 haveAnyQuestionsFaq">
                    <h5>How long will it take to do my dissertation?</h5>
                    <p>We have skilled educated experts who can tackle your task on each and every step, that your fulfillment is our need. We work to complete your task before the deadlines. The vast majority of our orders are finished and delivered to clients with time to spare so you can demand changes whenever required from our experts.</p>
                </div>
                <div className="col-md-5 haveAnyQuestionsFaq">
                    <h5>What are the qualifications of tutors?</h5>
                    <p>Your Dissertation is the most serious academic project for your career in dissertation writing. We completely Understand this and your need to have quality dissertation writers. We assign your work to the expert with the most experienced PhD holders in the specific field. </p>
                </div>
                <div className="col-md-12 haveAnyQuestionsFaqIcon">
                    <p className="text-center" onClick={() => setShowAndHide(!showAndHide)}>
                        {showAndHide ? <i className="bi bi-chevron-down"><BsChevronUp /></i> : <i className="bi bi-chevron-up"><BsChevronDown /></i>}
                    </p>
                </div>
                {
                    showAndHide ? 
                    <>
                  <div className="col-md-5 haveAnyQuestionsFaq dissertationIntroFaqSection">
                    <h5>Is plagiarism free?</h5>
                    <p>Yes, our expert dedicated quality assurance team checks every order using the most authentic plagiarism detection tools to make sure it is 100% plagiarism-free. We have never received any complaints regarding plagiarism or copied content. </p>
                </div>
                <div className="col-md-5 haveAnyQuestionsFaq dissertationIntroFaqSection">
                    <h5>What is the money back guarantee policy?</h5>
                    <p>Our experts are well educated from the best universities who are handling your dissertation writing, we Assured Good Grade or Money Back Guarantee<span>*</span>.</p>
                </div>
                    </>
                     : null
                }
            </div>
        </div>
    </section>
    </>
  )
}

export default HaveAnyQuestions;