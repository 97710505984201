
 const typesOfServices = [
    {
        id : 1,
        value : "Dissertation Abstract",
        servicesData : "Dissertation Abstract"
    },
    {
        id : 2,
        value : "Dissertation Introduction",
        servicesData : "Dissertation Introduction"
    },
    {
        id : 3,
        value : "Dissertation Literature Review",
        servicesData : "Dissertation Literature Review"
    },
    {
        id : 4,
        value : "Dissertation Methodology",
        servicesData : "Dissertation Methodology"
    },
    {
        id : 5,
        value : "Dissertation Discussion",
        servicesData : "Dissertation Discussion"
    },
    {
        id : 6,
        value : "Dissertation Data Analyst &amp; Results",
        servicesData : "Dissertation Data Analyst &amp; Results"
    },
    {
        id : 7,
        value : "Dissertation Conclusion",
        servicesData : "Dissertation Conclusion"
    },
    {
        id : 8,
        value : "Dissertation Bibliography",
        servicesData : "Dissertation Bibliography"
    },
    {
        id : 9,
        value : "Capstone Project",
        servicesData : "Capstone Project"
    }
];

export default typesOfServices;