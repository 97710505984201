
const FAQDataOnEnglishSubject = [
    {
        id : 1,
        question : "Is it legal to pay someone to do my English dissertation?",
        answer : <span>Yes, it is legal to get <strong>English dissertation help online</strong>. In case, you experience any difficulties in writing your dissertation, then take assistance from a reliable service provider like writemydissertationforme.com. As per your needs, our English language experts will offer you high-quality dissertation writing help at an affordable price.</span>,
    },
    {
        id : 2,
        question : "Can I get an academic paper revision from you?",
        answer : `Yes, from us, you can get unlimited paper revisions for free. As per your needs, we will do the necessary modifications until you get complete satisfaction with the outcome.`,
    },
    {
        id : 3,
        question : "What areas of English literature can you help with in my dissertation?",
        answer : "We can assist with a wide range of topics in English literature, including classical literature, modern literature, comparative literature, literary theory, post-colonial studies, and more. Whether you're focusing on a specific author, genre, or period, our experts have the knowledge to guide you through your dissertation.",
    },
    {
        id : 4,
        question : "Can you help with both literature-based and creative writing dissertations?",
        answer : "Yes, we offer support for both literature-based dissertations and creative writing projects. Whether you need help analyzing literary texts, exploring themes, or creating original content, our team of writers can assist with various types of English dissertations.",
    },
    {
        id : 5,
        question : "Can I get an academic paper revision from you?",
        answer : "Yes, from us, you can get unlimited paper revisions for free. As per your needs, we will do the necessary modifications until you get complete satisfaction with the outcome.",
    },

];

export default FAQDataOnEnglishSubject;








