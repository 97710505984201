import React from 'react';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import '../AboutUsPage.css';

function AboutUsCarousel() {
  return (
    <>
    <section className="reviewsContainerStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div id="carouselExampleCaptions" className="carousel aboutUsPageMainCarousel slide" data-bs-ride="false">
                        {/* <!-- <div className="carousel-indicators">
                          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div> --> */}
                        <div className="carousel-inner aboutUsPageInnerPartOfCarousel">
                          <div className="carousel-item dissertationAboutUsCarouselItems active">
                            <div className="carousel-caption">
                              <p>“These guys are pretty open with the money they charge; no matter how difficult the paper is, they communicate transparently, even if it's a delay; good job, guys. My dissertation passed through with a satisfactory grade. Look forward to working with y'all again.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                          <div className="carousel-item dissertationAboutUsCarouselItems">
                            <div className="carousel-caption">
                              <p>“It's like a miracle but It's true, I have completed my dissertation and now I am looking for my career but it is not possible without your support and guidance. Thank You for your help. I give you 5 out of 5 stars.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                          <div className="carousel-item dissertationAboutUsCarouselItems">
                            <div className="carousel-caption">
                              <p>“I was very much tensed that whether my dissertation will be able to be submitted on time or not. But thanks to the write my dissertation for me that they helped me for on time submission.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                          <div className="carousel-item dissertationAboutUsCarouselItems">
                            <div className="carousel-caption">
                              <p>“No matter how much you try to make your thesis perfect, there is something or the other that misses. I left it to the experts and they made it, with no plagiarism or grammar error. I also love how professional they are in writing and delivering.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                          <div className="carousel-item dissertationAboutUsCarouselItems">
                            <div className="carousel-caption">
                              <p>“I had no idea how to write a paper, and I was concerned about whether or not I would be able to turn it in on time. Many thanks to the experts assisted with writing the papers and submitting a day in advance. I really appreciate your efforts.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"><i className="bi bi-chevron-left"><BsChevronLeft /></i></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"><i className="bi bi-chevron-right"><BsChevronRight /></i></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutUsCarousel;