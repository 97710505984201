
const FAQDataOnHistorySubject = [
    {
        id : 1,
        question : "What is a History Dissertation?",
        answer : "A History Dissertation is a research project based on the chosen topic with the help of samples, surveys, and data provided. it is a field which is related to past evolution, one needs to do in - depth research to complete a dissertation. it is combined with the causes and consequences of the Important events.",
    },
    {
        id : 2,
        question : "Can I Pay Someone To Write My History Dissertation?",
        answer : <span>Yes, you <strong>can pay someone to write your history dissertation</strong>. We have a group of experts at writemydissertationforme who can help you with your history dissertation. Our writers are very skilled and have been writing history dissertations for years.</span>,
    },
    {
        id : 3,
        question : "How do I know my history dissertation is in good hands?",
        answer : "Our Writers are highly qualified and have years of experience handling history dissertations. They are Ph D and Alumni of the world's best universities. Thus, your project will be handled by experts who know their craft.",
    },
    {
        id : 4,
        question : "Can you help with both primary and secondary research for my history dissertation?",
        answer : "Yes, we assist with both primary and secondary research. Our team can help you locate and analyze historical documents, archives, and other primary sources. We also provide thorough literature reviews using credible secondary sources to support your dissertation.",
    },
    {
        id : 5,
        question : "What historical periods and topics can you assist with in my dissertation?",
        answer : "We offer assistance across a wide range of historical periods and topics, including ancient, medieval, modern, and contemporary history. Our experts cover specific areas such as political history, social history, cultural history, economic history, military history, and more. Whether your focus is regional or thematic, we can help.",
    },

];

export default FAQDataOnHistorySubject;
