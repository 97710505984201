import React from 'react'
import '../../Page/DissertationPrivacyPolicy.css'

function PrivacyPolicyBanner() {
  return (
    <>
    <section className="dissertationPrivacyPolicySectionStartHere">
        <div className="container-fluid dissertationPrivacyPolicyBannerImageSection">
            <div className="row justify-content-center">
                <div className="col-md-7 dissertationPrivacyPolicyBannerHeadingAndPara">
                    <h2 className='privacyPolicyHeading'>PRIVACY POLICY</h2>
                    <p className='dissertationPrivacyPolicyFirstPara'>
                    This privacy policy document outlines the types of personal information that is received and collected by WriteMyDissertationForMe. 
                    </p>
                    <p className='dissertationPrivacyPolicySecondPara'>
                    Last Updated: June 01, 2023<br />
                    Effective as of June 01, 2024
                    </p>
                </div>
                <div className="col-md-5"></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PrivacyPolicyBanner