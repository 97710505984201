
const FAQDataOnITSubject = [
    {
        id : 1,
        question : "What is an IT dissertation writing service?",
        answer : <span>An <strong>IT dissertation writing service</strong> provides expert assistance in researching, writing, editing, and formatting dissertations related to information technology topics. These services help students complete high-quality dissertations on time.</span>,
    },
    {
        id : 2,
        question : "What IT subjects do you cover in dissertation writing?",
        answer : <span>We cover a wide range of IT subjects, including but not limited to cybersecurity, data science, artificial intelligence, software engineering, cloud computing, big data, blockchain technology, and IT project management.</span>,
    },
    {
        id : 3,
        question : "How do I get started with your IT dissertation writing service?",
        answer : "Simply contact us through our website, fill out the inquiry form with your dissertation requirements, and our team will get in touch with you to discuss the next steps.",
    },
    {
        id : 4,
        question : "What qualifications do your IT dissertation writers have?",
        answer : "Our IT dissertation writers hold advanced degrees (Master’s or PhD) in information technology and related fields. They have extensive experience in academic writing and deep knowledge of IT topics to provide expert guidance.",
    },
    {
        id : 5,
        question : "Can I pay in installments for the IT dissertation writing service?",
        answer : "Yes, we offer flexible payment options, including installment plans, to make our services more affordable and accessible for students.",
    },

];

export default FAQDataOnITSubject;
