import React from 'react'
import '../../Page/DissertationPrivacyPolicy.css'
import arrowIcon from '../../Image/Dissertation_Literature_Review/Arrow.png'

function LastQuestion() {
    return (
        <>
            <section className="dissertationPrivacyPolicyLastQuestion">
                <div className="container">
                    <div className="col-md-12 justify-content-center dissertationPrivacyPolicyLastQuestionHeadingAndTable">
                        <h2>COOKIES AND YOUR PERSONAL DATA</h2>
                        <table className="table table-borderless dissertationPrivacyPolicyTable">
                            <tbody>
                                <tr>
                                    <th scope="row" className='dissertationPrivacyPolicyHeading'><img src={arrowIcon} alt="Pay someone to write my dissertation" /></th>
                                    <td className='dissertationPrivacyPolicyTableDataPara'>
                                        <p>At WriteMyDissertationForMe, our most important asset is our relationship with you.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className='dissertationPrivacyPolicyHeading'><img src={arrowIcon} alt="Pay someone to write my dissertation" /></th>
                                    <td className='dissertationPrivacyPolicyTableDataPara'>
                                    <p>WriteMyDissertationForMe is committed to maintaining the confidentiality, integrity, and security of any personal information about our users.      
                                    </p>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th scope="row" className='dissertationPrivacyPolicyHeading'><img src={arrowIcon} alt="dissertation assignment help" /></th>
                                    <td className='dissertationPrivacyPolicyTableDataPara'>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris.</p>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LastQuestion