import React from 'react'
import '../DissertationLiteratureReview.css'

function Guarantees() {
  return (
    <>
    <section className="guaranteesUnderLiteratureReviewPage">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 guaranteesHeading mt-4">
                    <h2>GUARANTEES</h2>
                </div>
                <div className="row justify-content-evenly dissertationLiteratureReviewCardSection">
                    <div className="col-md-2 firstCard literatureReviewCard guaranteesSectionCard">
                        <div className="cardNumberOne"></div>
                        <p>No Hidden Charges</p>
                    </div>
                    <div className="col-md-2 secondCard literatureReviewCard guaranteesSectionCard">
                        <div className="cardNumberTwo"></div>
                        <p>100% Confidentiability</p>
                    </div>
                    <div className="col-md-2 thirdCard literatureReviewCard guaranteesSectionCard">
                        <div className="cardNumberThree"></div>
                        <p>Safe &amp; Secure</p>
                    </div>
                    <div className="col-md-2 fourthCard literatureReviewCard guaranteesSectionCard">
                        <div className="cardNumberFour"></div>
                        <p>Excellent Quality Work</p>
                    </div>
                    <div className="col-md-2 fifthCard literatureReviewCard guaranteesSectionCard">
                        <div className="cardNumberFive"></div>
                        <p>Assistant Any Stage Of Your Dissertation</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Guarantees;