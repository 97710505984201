import React, { useEffect, useRef, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "../../CommonPage/FaqAndDissChaptersComponent.css";
import '../FrequentlyAskedQuestions.css';
import FAQCompOnCProjects from './FAQCompOnCProjects';
import FAQCompOnDAbstract from './FAQCompOnDAbstract';
import FAQCompOnDBibliography from './FAQCompOnDBibliography';
import FAQCompOnDConclusion from './FAQCompOnDConclusion';
import FAQCompOnDGeneralQues from './FAQCompOnDGeneralQues';
import FAQCompOnDLReview from './FAQCompOnDLReview';
import FAQCompOnDMethodology from './FAQCompOnDMethodology';
import FAQCompOnDProposal from './FAQCompOnDProposal';
import FAQCompOnDWServices from './FAQCompOnDWServices';

function AskedQuestions() {
    const [selected, setSelected] = useState(1);
    const [visibleIndex, setVisibleIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [buttons, setButtons] = useState([
        { id: 1, label: "General"},
        { id: 2, label: "Dissertation Writing Services"},
        { id: 3, label: "Dissertation Proposal"},
        { id: 4, label: "Dissertation Abstract"},
        { id: 5, label: "Dissertation Literature Review"},
        { id: 6, label: "Dissertation Methodology"},
        { id: 7, label: "Dissertation Conclusion"},
        { id: 8, label: "Dissertation Bibliography"},
        { id: 9, label: "Capstone Projects"}
    ]);
    const headingButtonRef = useRef([]);
    const selectFunc = (id) => {
        setSelected(id);
    }
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    const moveNext = () => {
        if (headingButtonRef.current.length > 0) {
            const nextIndex = (visibleIndex + 1) % headingButtonRef.current.length;
            setVisibleIndex(nextIndex);
            updateCarousal(nextIndex);
        }
        setButtons((prevButtons) => {
            const newButtons = [...prevButtons];
            const firstButton = newButtons.shift();
            newButtons.push(firstButton);
            return newButtons;
        })
    }

    const movePrev = () => {
        if (headingButtonRef.current.length > 0) {
            const prevIndex = (visibleIndex - 1 + headingButtonRef.current.length) % headingButtonRef.current.length;
            setVisibleIndex(prevIndex);
            updateCarousal(prevIndex);
        }
        setButtons((prevButtons) => {
            const newButtons = [...prevButtons];
            const lastButton = newButtons.pop();
            newButtons.unshift(lastButton);
            return newButtons;
        })
    }
    const updateCarousal = (index) => {
        headingButtonRef.current.forEach((button, i) => {
            button.classList.remove("active-faq-button");
        })
        headingButtonRef.current[index].classList.add("active-faq-button")
    }
    const getButtonStyle = (index) => {
        if (windowWidth < 768) {
            return {
                transition: 'all 0.5s ease',
                transform: `translateX(${(index - visibleIndex) * 15}%)`
            }
        }
    }
    return (
        <>
            <section className="faqPageSectionStartHere">
                <div className="container-fluid faqPageFirstInnerSectionStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 faqPageHeading">
                            <h2><span>QUICK ASSIST</span></h2>
                            <p>Answer to our most Frequently Asked Questions are just one click away.</p>
                        </div>
                        <hr />
                        <div className="forward-backward-icons-div">
                            <span className="forward-icon fbIcon" onClick={movePrev}><i><BsChevronLeft /></i></span>
                            <span className="backward-icon fbIcon" onClick={moveNext}><i><BsChevronRight /></i></span>
                        </div>
                        <div className="col-md-4 faq-page-heading-div">
                            {
                                buttons.map((button, index) => (
                                    <h5 onClick={() => selectFunc(button.id)} key={index} className={`faq-page-heading-button ${selected === button.id ? 'active-faq-button' : ''}`} style={getButtonStyle(index)}>{button.label}</h5>
                                ))
                            }
                            {/* <h5 onClick={() => selectFunc(1)} className="faq-page-heading-button">General</h5>
                            <h5 onClick={() => selectFunc(2)} className="faq-page-heading-button">Dissertation Writing Services</h5>
                            <h5 onClick={() => selectFunc(3)} className="faq-page-heading-button">Dissertation Proposal</h5>
                            <h5 onClick={() => selectFunc(4)} className="faq-page-heading-button">Dissertation Abstract</h5>
                            <h5 onClick={() => selectFunc(5)} className="faq-page-heading-button">Dissertation Literature Review</h5>
                            <h5 onClick={() => selectFunc(6)} className="faq-page-heading-button">Dissertation Methodology</h5>
                            <h5 onClick={() => selectFunc(7)} className="faq-page-heading-button">Dissertation Conclusion</h5>
                            <h5 onClick={() => selectFunc(8)} className="faq-page-heading-button">Dissertation Bibliography</h5>
                            <h5 onClick={() => selectFunc(9)} className="faq-page-heading-button">Capstone Projects</h5> */}
                        </div>
                        <div className="col-md-8 faq-page-heading-and-para-div">
                            <div className="faq-div">
                                <h3>{(selected === 1 && "General") || (selected === 2 && "Dissertation Writing Services") || (selected === 3 && "Dissertation Proposal") || (selected === 4 && "Dissertation Abstract") || (selected === 5 && "Dissertation Literature Review") || (selected === 6 && "Dissertation Methodology") || (selected === 7 && "Dissertation Conclusion") || (selected === 8 && "Dissertation Bibliography") || (selected === 9 && "Capstone Projects")}</h3>
                                {
                                    (selected === 1 && <FAQCompOnDGeneralQues />) ||
                                    (selected === 2 && <FAQCompOnDWServices />) ||
                                    (selected === 3 && <FAQCompOnDProposal />) ||
                                    (selected === 4 && <FAQCompOnDAbstract />) ||
                                    (selected === 5 && <FAQCompOnDLReview />) ||
                                    (selected === 6 && <FAQCompOnDMethodology />) ||
                                    (selected === 7 && <FAQCompOnDConclusion />) ||
                                    (selected === 8 && <FAQCompOnDBibliography />) ||
                                    (selected === 9 && <FAQCompOnCProjects />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AskedQuestions;