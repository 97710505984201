import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import '../DissertationTestimonial.css'
import CustomerViewCard from '../../Page/Testimonial/CustomerViewCard'
import TestimonialData from '../../Data/Testimonial_Page/TestimonialData';

function CardTestimonial() {
  return (
    <>
    <div className="container justify-content-center py-2 testimonialCardBox">
      <Swiper
      grabCursor={true}
      modules={[ Navigation ]}
      navigation={{ clickable: true }}
      className='mySwiper'
      breakpoints={{
        575: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 50
        }
      }}
      >
        {
          TestimonialData.map((cardDetails) => (
            <SwiperSlide> 
            <CustomerViewCard data=
            {{imageSource: cardDetails.image, title: cardDetails.title, content: cardDetails.content, studentsName: cardDetails.studentName, altTag: cardDetails.altTag, key: cardDetails.id}}
            />
            </SwiperSlide>
            ))
          }
        
        {/* <SwiperSlide><CustomerViewCard data={{imageSource: imageOne, title: "HEADING", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", studentsName: 'SAIRA JOHN', altTag: "Dissertation writing Help"}} /></SwiperSlide>
        */}
      </Swiper>
    </div>
    </>
  )
}

export default CardTestimonial;