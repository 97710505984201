import React from 'react'
import '../AboutUsPage.css'

function BannerAndOurMission() {
  return (
    <>
    <section className="aboutUsFirstContainerStartHere">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 aboutUsHeadingAndPara">
                    <h2><span>ABOUT US</span></h2>
                    <div className="below-heading-line mt-2"><span></span></div>
                    <p>We are a more than a decade old academic content writing firm that specializes in dissertation writing services and working with the motto of writing original content to ensure you meet academic success.</p>
                    <div className="aboutUsBackgroundImage">
                        <p className="aboutUsBackgroundImagePara">Original creators, Punctual, Decade+ experience</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="ourMissionSectionStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 ourMissionDiv">
                    <h2><span>OUR MISSION</span></h2>
                    <p>We strive to produce a well written academic paper with thorough research and original content at an affordable price which meets the highest standards of quality.</p>
                    <p>To achieve our mission, we employ tutors who not only are subject matter experts and PhD degree holders but also have prior writing experience. Not only are they put through rigorous training but their output is also checked and reviewed by another team of experts.</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BannerAndOurMission;