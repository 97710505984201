import React from 'react';
import { NavLink } from 'react-router-dom';
import arrowIcon from '../../Image/Dissertation_Literature_Review/Arrow.png';
import '../DissertationIntroduction.css';

function IntroductionQuestionAndChapters() {
  return (
    <>
    <section className="dissertationIntroductionFourthQuestionAndTestimonial">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6 col-12 dissertationIntroductionFourthQuestion">
                    <h2>HIRE SOMEONE TO WRITE MY DISSERTATION INTRODUCTION</h2>
                    <p>The process of solving dissertation is quite complex and will require engagement of effective work skills. When the complexity of the dissertation project becomes quite high, it is advisable to hire an expert for getting help on dissertation. Many students search for “hire someone to do my online dissertation” on Google, but they fail to get proper resolution for managing their dissertation as many of the dissertation writing companies come with dubious tutor base.</p>
                    <p>Our experts are thoroughly vetted through effective background checks and testing. Only 1 in 10 applicants are selected within our tutor base which ensures that only the best tutors with the requisite industry experience will be so dissertation abstract, introduction, literature review, and other elements of the project work is properly engaged and handled.</p>
                    <button><NavLink to={"/getQuote"} className="href">GET FREE QUOTE</NavLink></button>
                </div>
                <div className="col-lg-5 col-12 dissertationIntroductionChapters">
                    <h4>DISSERTATION CHAPTERS</h4>
                    <ul>
                        <li className="mt-4"><img src={arrowIcon} alt="" /><NavLink to={"/dissertationProposal"} className="href ms-2">Dissertation Proposal</NavLink></li> 
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationAbstract"} className="href ms-2">Dissertation Abstract</NavLink></li>                       
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationMethodology"} className="href ms-2">Dissertation Methodology</NavLink></li>
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationLiteratureReview"} className="href ms-2">Dissertation Literature Review</NavLink></li>
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationConclusion"} className="href ms-2">Dissertation Conclusions</NavLink></li>            
                        <li className="mb-4"><img src={arrowIcon} alt="" /><NavLink to={"/dissertationBibliography"} className="href ms-2">Dissertation Bibliography</NavLink></li>            
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default IntroductionQuestionAndChapters;