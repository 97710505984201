import React from 'react'
import '../DissertationLiteratureReview.css'
import '../DissertationConclusion.css'
import LiteratureReviewBannerForm from './LiteratureReviewBannerForm';

function LiteratureReviewBanner() {
  return (
    <>
    <section className="literatureReviewBannerImageSection">
        <div className="container-fluid literatureReviewInnerDivOfBannerImage">
            <div className="row justify-content-between">
                <div className="col-md-12 literatureReviewBannerImageHeadingAndPara">
                {/* <h2>CONNECT WITH OUR EXPERTS</h2> */}
                <LiteratureReviewBannerForm />
                </div>
                {/* <div className="col-md-5 hideThisDivSection"></div> */}
            </div>
        </div>
    </section>
    </>
  )
}

export default LiteratureReviewBanner;