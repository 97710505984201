import React from 'react';
import { NavLink } from "react-router-dom";
import QuickQueryForm from '../../CommonPage/QuickQueryForm';
import UseCostCalculator from '../../Hooks/UseCostCalculator';
import './CostOfDissertation.css';

function CostOfDissertation() {
    const [wordsOrPages, getWordsOrPages, valueOfWordsOrPages, typeWordsOrPages, cost] = UseCostCalculator();
    // const [wordsOrPages, setWordsOrPages] = useState();
    // const [radioButton, setradioButton] = useState();
    // const [calculateCost, setCalculateCost] = useState(false);
    // const [cost, setCost] = useState(0);

    // function getRadioButtonValue (e) {
    //     let radioButtonValue = setradioButton(e.target.value);
    //     console.log(radioButtonValue);
    // }

    // function getNumberOfWordsOrPages(e) {
    //     const re = /^[0-9\b]+$/;
        
    //     // if value is not blank, then test the regex
    //     if (e.target.value === '' || re.test(e.target.value)) {
    //         let num = e.target.value;
    //         setWordsOrPages(num);
    //         let cost = 0;
    //         // let x = document.calculateCostCalculator.requiredLength;
    //         if (radioButton === 'Pages') {
    //             cost = num * 10;
    //         } else if (radioButton === 'Words') {
    //             cost = ((num / 250) * 10 ).toFixed(2);
    //         }
    //         setCost(cost);
    //     }
    // }
  return (
    <>
    <section className="costOfDissertationSectionStartHere">
        <div className="container costOfDissertationContainer">
            <div className="row justify-content-center costOfDissertationBackgroundImageInnerContainer">
                <div className="col-md-12 my-3 py-3 costOfDissertationHeadingPart">
                    <h2 className="text-center"><span>FIND THE COST OF YOUR DISSERTATION</span></h2>
                    <div className="below-heading-line mt-2"></div>
                </div>
            </div>
        </div>
        <div className="container costOfDissertationBackgroundImageContainer">
            <div className="row justify-content-around costOfDissertationBackgroundImageInnerContainer">
                <div className="col-md-7 my-3 py-3 costOfDissertationCalculationPart">
                <p className='costOfDissertationCalculationPartHeading'>COST ESTIMATOR</p>
                    <form name='calculateCostCalculator'>
                    <div className="mb-3 costOfDissertationCalculationPartDiv">
                        <p>Will you provide the required length in:</p>
                        <input type="radio" id="Words" name="requiredLength" value={wordsOrPages} onChange={valueOfWordsOrPages} />
                        <label htmlFor="words" className='ms-2'>Words</label>
                        <input type="radio" id="Pages" name="requiredLength" className="ms-5" value={wordsOrPages} onChange={valueOfWordsOrPages}  />
                        <label htmlFor="pages" className='ms-2'>Pages</label>
                    </div>
                    <div className="mb-3 costOfDissertationCalculationPartDiv">
                        <p>Please provide the Number of Words / Pages:</p>
                        <input type="text" id="count" name="count" className="wordsOrPages" value={typeWordsOrPages} onChange={getWordsOrPages} />
                        <span className="ms-2">{wordsOrPages}</span>
                    </div>
                    <div className="mb-3 costOfDissertationCalculationPartDiv">
                        <p>Approx price of your dissertation is?</p>
                        <p><span className='costOfDissertation'>$</span>
                            {
                                <span className="ms-2 costOfDissertation">{cost}</span>
                            }
                        </p>
                    </div>
                    <div>
                        <NavLink to={"/getQuote"}><button className="costOfDissertationCalculationPartButton mt-3">ORDER NOW</button></NavLink>
                    </div>
                    </form>
                </div>
                <div className="col-md-4 my-3 py-3 costOfDissertationQuickQueryFormPart">
                    <p className='costOfDissertationQuickQueryFormPartHeading'>QUICK QUERY FORM</p>
                    <QuickQueryForm />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default CostOfDissertation;