import React from 'react'
import './Carousel.css'
import CarouselAndBannerImageForm from './CarouselAndBannerImageForm';

function Carousel() {
  return (
    <>
     <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active dissertationCarouselItems dissertationFirstCarousel">
        <div className="container dissertationCarouselContainer">
            <div className="row justify-content-evenly">
                <div className="col-md-12 dissertationMainCarouselHeadingAndFormDiv">
                    <h5>Punctuality
                        <div className="dissertaionCarouselInnerBoxOfHeading"></div>
                    </h5>
                    <CarouselAndBannerImageForm />
                </div>
            </div>
        </div>
    </div>
    <div className="carousel-item dissertationCarouselItems dissertationSecondCarousel">
    <div className="container dissertationCarouselContainer">
            <div className="row justify-content-evenly">
                <div className="col-md-12 dissertationMainCarouselHeadingAndFormDiv">
                    <h5 className='phdTutorsHeading'>PhD Tutors
                        <div className="dissertaionCarouselInnerBoxOfHeading"></div>
                    </h5>
                    <CarouselAndBannerImageForm />
                </div>
            </div>
        </div>
    </div>
    <div className="carousel-item dissertationCarouselItems dissertationThirdCarousel">
    <div className="container dissertationCarouselContainer">
            <div className="row justify-content-evenly">
                <div className="col-md-12 dissertationMainCarouselHeadingAndFormDiv">
                    <h5>Privacy
                        <div className="dissertaionCarouselInnerBoxOfHeading"></div>
                    </h5>
                    <CarouselAndBannerImageForm />
                </div>
            </div>
        </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </>
  )
}

export default Carousel;