import React from 'react'
import '../DissertationBibliography.css'
import BibliographyBannerForm from './BibliographyBannerForm';

function BibliographyBanner() {
  return (
    <>
    <section className="dissertationBibliographyBannerImageSection">
        <div className="container-fluid dissertationBibliographyInnerDivOfBannerImage">
            <div className="row justify-content-center">
                <div className="col-md-7 dissertationBibliographyBannerImageHeadingAndForm">
                    {/* <h2>GET YOUR TASK COMPLETED</h2> */}
                    <BibliographyBannerForm />
                </div>
                <div className="col-md-5 hideThisDivSection"></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BibliographyBanner;