import React, { useState } from 'react';
import '../CSS_File/MainBlogPage.css';
// import AnonymousBlogPersonIdentity from "../../../../Image/Blog_Page/Comment_Icon.png"

function BlogCommentForm() {
    const [name, setName] = useState("");
    const [comment, setComment] = useState("");
    // const [replyComment, setReplyComment] = useState("");


    function findNameOfTheInput(e) {
        let nameValue = e.target.value;
        setName(nameValue);
    }

    function findCommentOfTheInput(e) {
        setComment(e.target.value);
    }
    return (
        <>
            <section className="formCommentSectionStartHere">
                <div className="container formCommentSectionInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blogCommentFormSection">
                            <form name="contact-blog-comment" method="post" enctype="multipart/form-data" data-netlify="true" onSubmit="submit" id="blogCommentFrom" className="blogCommentForm">
                                <input type="hidden" name='form-name' value="contact-blog-comment" />
                                <div className="mb-3">
                                    <label htmlFor="blogName" className="form-label blogCommentFormLabel blogLabel">Name</label>
                                    <input type="text" className="form-control formInput" id="blogName" name="blogName" onChange={findNameOfTheInput} value={name} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="blogEmail" className="form-label blogCommentFormLabel blogLabel">Email</label>
                                    <input type="email" className="form-control formInput" id="blogEmail" name="blogEmail" />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="blogComment" className="form-label blogCommentFormLabel blogLabel">Comment<span>*</span></label>
                                    <textarea className="form-control formInput" id="blogComment" rows="1" name="blogComment" required onChange={findCommentOfTheInput} value={comment}></textarea>
                                </div>
                                <div className="mb-3 postCommentButtonDiv">
                                    <button type="submit" className="postCommentButton" id="postCommentButton">Post Comment</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="blogResponsesSectionStartHere">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 blogResponsesHeading blogResponsesHide" id='blogResponsesDiv'>
                            <h3>Responses</h3>
                        </div>
                        <div className="col-md-12 commentsImageHeadingAndParaDiv blogCommentsDiv d-flex" id='blogCommentsId'>
                            <div className='blogCommentIdentityImage'>
                                <img src={AnonymousBlogPersonIdentity} alt="Write My Dissertation For Me" className='img-fluid' />
                            </div>
                            <div>
                                <h4>Manisha Sharma</h4>
                                <p>2 days ago</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                                <p><a href="/" className="href">Reply</a></p>
                                <div className="mb-4 mt-3 commentReplyDiv">
                                    <textarea className="form-control formInput replyTextareaInput" id="blogCommentReply" rows="1" name="blogCommentreply" required onChange={findCommentOfTheReplyInput} value={replyComment}></textarea>
                                    <button type="submit" className='postCommentReplyButton mt-4'>Post</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default BlogCommentForm;