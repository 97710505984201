import React from 'react';
import { Helmet } from 'react-helmet';
import '../Page/AboutUsPage.css';
import AboutUsCarousel from './AboutUs/AboutUsCarousel';
import BannerAndOurMission from './AboutUs/BannerAndOurMission';
import CostEstimatorAndQueryForm from './AboutUs/CostEstimatorAndQueryForm';
import HaveAnyQuestions from './AboutUs/HaveAnyQuestions';
import OurPerformance from './AboutUs/OurPerformance';
import OurServices from './AboutUs/OurServices';
import WhyChooseUs from './AboutUs/WhyChooseUs';

function AboutUsPage() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "How long will it take to do my dissertation?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We have skilled educated experts who can tackle your task on each and every step, that your fulfillment is our need. We work to complete your task before the deadlines. The vast majority of our orders are finished and delivered to clients with time to spare so you can demand changes whenever required from our experts."
                }
              },{
                "@type": "Question",
                "name": "What are the qualifications of tutors?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Your Dissertation is the most serious academic project for your career in dissertation writing. We completely Understand this and your need to have quality dissertation writers. We assign your work to the expert with the most experienced PhD holders in the specific field."
                }
              },{
                "@type": "Question",
                "name": "Is plagiarism free?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, our expert dedicated quality assurance team checks every order using the most authentic plagiarism detection tools to make sure it is 100% plagiarism-free. We have never received any complaints regarding plagiarism or copied content."
                }
              },{
                "@type": "Question",
                "name": "What is the money back guarantee policy?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our experts are well educated from the best universities who are handling your dissertation writing, we Assured Good Grade or Money Back Guarantee*."
                }
              }]
            }`}
        </script>
        <title>About us | Dissertation Writing</title>
        <meta name="description" content="We are a decade-old academic writing firm specializing in dissertation writing services, and our motto is writing original content to ensure academic success." />
        <meta name="keywords" content="Can someone else write my dissertation?, take my online dissertation, pay someone to write your dissertation, write my dissertation online, Best Dissertation writing service" />
        <link rel="canonical" href=" https://www.writemydissertationforme.com/aboutUs/" />
      </Helmet>
      <BannerAndOurMission />
      <WhyChooseUs />
      <OurServices />
      <AboutUsCarousel />
      <OurPerformance />
      <CostEstimatorAndQueryForm />
      <HaveAnyQuestions />
    </>
  )
}

export default AboutUsPage;