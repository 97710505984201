
 const countryCode = [
    {
        id : 1,
        value : "USA - 1",
        phoneCodeData : "USA - 1"
    },
    {
        id : 2,
        value : "Australia - 61",
        phoneCodeData : "Australia - 61"
    },
    {
        id : 3,
        value : "Canada - 1",
        phoneCodeData : "Canada - 1"
    },
    {
        id : 4,
        value : "Saudi Arabia - 966",
        phoneCodeData : "Saudi Arabia - 966"
    },
    {
        id : 5,
        value : "U.A.E - 971",
        phoneCodeData : "U.A.E - 971"
    },
    {
        id : 6,
        value : "U.K - 44",
        phoneCodeData : "U.K - 44"
    },
    {
        id : 7,
        value : "Kuwait - 965",
        phoneCodeData : "Kuwait - 965"
    },
    {
        id : 8,
        value : "Others",
        phoneCodeData : "Others"
    }
    
];

export default countryCode;