import React, { useEffect } from 'react'
import CarouselData from '../../Data/LandingPage/CarouselData';

const AbstractBannerForm = () => {
    function submitAbstractForm (e) {
        let dissertationWritingServicesEmail = document.getElementById('dissertationWritingServicesEmail').value;
        let dissertationWritingServicesReEmail = document.getElementById('dissertationWritingServicesReEmail').value;
        let dissertationWritingServicesEnquireNowButton = document.getElementById('dissertationWritingServicesEnquireNowButton');
        if (dissertationWritingServicesReEmail !== dissertationWritingServicesEmail) {
            dissertationWritingServicesEnquireNowButton.classList.add('displayAbstract');
        } else {
            dissertationWritingServicesEnquireNowButton.classList.remove('displayAbstract');
        }
        e.preventDefault();
    }
    useEffect(() => {
        const dissertationWritingServicesReEmail = document.getElementById("dissertationWritingServicesReEmail");
        dissertationWritingServicesReEmail.onpaste = (e) => {
        e.preventDefault();
        return false;
        };
    }, [])
    return(
        <>
        <form action="/thankYouPage" name='contact-dissertation-writing-services' method="post" enctype="multipart/form-data" data-netlify="true" onSubmit="submit" className="dissertationAbstractQuickQueryForm" onChange={submitAbstractForm}>
                <input type="hidden" name='form-name' value="contact-dissertation-writing-services" />
                <select className="dissertationAbstractFormSelect" name='dissertationWritingServicesTypeOfPaper' >
                    <option selected disabled>Type of Paper</option>
                    {
                        CarouselData.map((typeOfPaper) => (
                            <option key={typeOfPaper.id} value={typeOfPaper.value}>{typeOfPaper.typeOfPaperData}</option>
                        ))
                    }
                </select>

                <input type="email" name="dissertationWritingServicesEmail" id="dissertationWritingServicesEmail" className='abstractEmail ms-2' placeholder="Enter Your Email" />

                <input type="email" name="dissertationWritingServicesReEmail" id="dissertationWritingServicesReEmail" className='abstractEmail ms-2' placeholder="Confirm Your Email" onpaste="return false;" ondrop="return false;" autocomplete="off" />
                <div id="smallAlertEmailBox" className='smallAlertEmailBox'></div>

                <div className="dissertationAbstractSubmitButtonDiv">
                    <button type='submit' className="dissertationWritingServicesEnquireNowButton" id='dissertationWritingServicesEnquireNowButton'>ENQUIRE NOW</button>
                </div>
            </form>
        </>
    );
}

export default AbstractBannerForm;