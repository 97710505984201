
 const references = [
    {
        id : 1,
        value : "Less Than 10",
        referenceData : "Less Than 10"
    },
    {
        id : 2,
        value : "11 to 20",
        referenceData : "11 to 20"
    },
    {
        id : 3,
        value : "21 to 30",
        referenceData : "21 to 30"
    },
    {
        id : 4,
        value : "31 to 40",
        referenceData : "31 to 40"
    },
    {
        id : 5,
        value : "41 to 50",
        referenceData : "41 to 50"
    },
    {
        id : 6,
        value : "More Than 50",
        referenceData : "More Than 50"
    }
];



export default references;