import React from 'react';
import { NavLink } from 'react-router-dom';
import "../CommonPage/Navbar.css";

const UpperNavbar = () => {
  return (
    <>
    <nav className="upper-navbar">
        <div className="upper-nav-div">
            <ul>
                <li><NavLink to={"/aboutUs"} className="href">ABOUT US</NavLink></li>
                <li><NavLink to={"/blog"} className="href">BLOG</NavLink></li>
                <li><NavLink to={"/testimonial"} className="href">TESTIMONIALS</NavLink></li>
                <li><NavLink to={"/faq"} className="href">FAQ</NavLink></li>
                <li>writemydissertationforme@gmail.com</li>
            </ul>
        </div>
    </nav>
    </>
  )
}

export default UpperNavbar;