import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MoreSubjectButton from '../../CommonPage/MoreSubjectButton';
import FAQDataOnHistorySubject from '../../Data/FAQs/FAQDataOnHistorySubject';
import Account1 from "../../Image/Dissertation_Subject_Page/History-P/History-1.webp";
import Account2 from "../../Image/Dissertation_Subject_Page/History-P/History-2.webp";
import Account3 from "../../Image/Dissertation_Subject_Page/History-P/History-3.webp";
import Account4 from "../../Image/Dissertation_Subject_Page/History-P/History-4.webp";
import '../../Page/Dissertation_Subject_Page/MainSubjectPage.css';
import "../../Page/FAQsComponentOnDPages/FAQComponentOnDPages.css";
import Guarantees from '../LiteratureReview/Guarantees';

function HistorySubjectPage() {
    const [showContent, setShowContent] = useState(false);
    const [showMoreContent, setShowMoreContent] = useState(false);
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, []);
    const toggleContent = () => {
        setShowContent(!showContent)
    }
    const toggleMoreContent = () => {
        setShowMoreContent(!showMoreContent)
    }
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    return (
        <>
            <Helmet>
                <title>History Dissertation | Do My History Dissertation For Me</title>
                <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
                <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
                <link rel="canonical" href=" https://www.writemydissertationforme.com/history/" />
            </Helmet>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Write My History Dissertation For Me</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>Are you overwhelmed with your history dissertation? Our expert team is here to help! When you say, <strong>Write my history dissertation for me</strong>, you can rely on our skilled writers to provide comprehensive research, original content, and a well-structured argument tailored to your specific topic. We ensure timely delivery, plagiarism-free work, and adherence to academic standards, helping you achieve your academic goals. Let us take the stress out of your dissertation process today</p>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account1} alt="What is a Finance Dissertation Writing Service?" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>What Topics are Offered By Our History Dissertation Writers?</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account2} alt="Finance Dissertation Help Service" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p><b>1. Impact of Colonialism on Indigenous Cultures</b>: Examine how colonial powers affected the social, cultural, and economic structures of indigenous populations.</p>
                            <p><b>2. The Role of Women in World War II</b>: Analyze the contributions of women during the war and how these roles influenced post-war society.</p>
                            <p><b>3. The Rise and Fall of Ancient Civilizations</b>: Explore the factors that led to the emergence and decline of notable civilizations like the Romans, Greeks, or Egyptians.</p>
                            {
                                showContent && (
                                    <div className="more-content-div">
                                        <p><b>4. The Cold War's Influence on Global Politics</b>: Investigate how the Cold War shaped political alliances and conflicts across different regions.</p>
                                        <p><b>5. Civil Rights Movements Across the Globe</b>: Compare and contrast various civil rights movements, focusing on their strategies, successes, and challenges.</p>
                                        <p><b>6. The Evolution of Democracy</b>: Study the historical development of democratic systems, focusing on key revolutions and political philosophies.</p>
                                        <p><b>7. Economic Impacts of the Great Depression</b>: Assess the causes and consequences of the Great Depression on various countries and their economies.</p>
                                        <p><b>8. The Role of Propaganda in Warfare</b>: Investigate how propaganda has been used throughout history to shape public opinion during conflicts.</p>
                                        <p><b>9. Historical Perspectives on Migration</b>: Analyze the social, political, and economic factors influencing major migration patterns throughout history.</p>
                                        <p><b>10. Environmental History</b>: Explore the relationship between humans and the environment, examining historical perspectives on conservation and exploitation.</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleContent}>{showContent ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>What Do You Get When You Hire Us?</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>When you hire us, you gain access to a team of experienced professionals dedicated to your success. You receive tailored support for your dissertation, including expert research, original content, and thorough editing. Our commitment to plagiarism-free work ensures academic integrity, while our timely delivery keeps you on track with deadlines. Additionally, we offer ongoing communication and revisions, ensuring your satisfaction with the final product. Trust us to help you achieve your academic goals with confidence</p>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account3} alt="Finance Dissertation Help Services" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Essential Precautions Before Hiring Professional Dissertation Help</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account4} alt="Finance Dissertation Help" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p><b>1. Research the Service</b>: Ensure the company or individual has a solid reputation with positive reviews and testimonials.</p>
                            <p><b>2. Check for Plagiarism-Free Guarantees</b>: Make sure they provide original, plagiarism-free content with proper citations.</p>
                            <p><b>3. Verify Expertise</b>: Confirm that the service has experienced writers in your field of study.</p>
                            {
                                showMoreContent && (
                                    <div className="more-content-div">
                                        <p><b>4. Clarify Deadlines</b>: Ensure they can meet your project’s deadlines.</p>
                                        <p><b>5. Understand Terms and Pricing</b>: Read the terms of service and ensure transparency in pricing and revisions.</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleMoreContent}>{showMoreContent ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </section>
            <MoreSubjectButton />
            <Guarantees />
            <section className="faq-section-on-faq-page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h2>HAVE ANY QUESTIONS</h2>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    FAQDataOnHistorySubject.map((item, i) => (
                                        <>
                                            <div className="faqParaAndHeadingDiv" key={item.id}>
                                                <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                                                    <h5>{item.question}</h5>
                                                </div>
                                                <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HistorySubjectPage;