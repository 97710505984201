import React from 'react';
import { BsChevronRight } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import '../AboutUsPage.css';

function OurServices() {
  return (
    <>
    <section className="ourServicesSectionStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 ourServicesInnerDiv">
                    <h2><span>DISSERTATION WRITING SERVICES</span></h2>
                </div>
                <div className="col-lg-5 ourServicesLeftButtonOfDissertationServices">
                    <p><NavLink to={"/dissertationWritingServices"} className="href"><span className='ourServicesAnchorLinkButton'>DISSERTATION WRITING</span><span className='ourServicesChevronRightButton'><i><BsChevronRight /></i></span></NavLink></p>
                    <p><NavLink to={'/capstoneProjects'} className="href"><span className='ourServicesAnchorLinkButton'>CAPSTONE PROJECTS</span><span className='ourServicesChevronRightButton'><i><BsChevronRight /></i></span></NavLink></p>
                </div>
                <div className="col-lg-5 ourServicesRightUnOrderedListDissertationChapters" style={{backgroundColor: "#3389B5"}}>
                    <ul>
                        <li><NavLink to={"/dissertationProposal"} className="href">Dissertation Proposal</NavLink></li>
                        <li><NavLink to={"/dissertationAbstract"} className="href">Dissertation Abstracts</NavLink></li>
                        <li><NavLink to={"/dissertationIntroduction"} className="href">Dissertation Introduction</NavLink></li>
                        <li><NavLink to={"/dissertationLiteratureReview"} className="href">Dissertation Literature Review</NavLink></li>
                        <li><NavLink to={"/dissertationMethodology"} className="href">Dissertation Methodology</NavLink></li>
                        <li><NavLink to={"/dissertationConclusion"} className="href">Dissertation Conclusion</NavLink></li>
                        <li><NavLink to={"/dissertationBibliography"} className="href">Dissertation Bibliography</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default OurServices;