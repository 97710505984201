import React from 'react'
import '../AboutUsPage.css'
import { NavLink } from "react-router-dom"
import QuickQueryForm from '../../CommonPage/QuickQueryForm';
import UseCostCalculator from '../../Hooks/UseCostCalculator';

function CostEstimatorAndQueryForm() {
    const [wordsOrPages, getWordsOrPages, valueOfWordsOrPages, typeWordsOrPages, cost] = UseCostCalculator();
    
  return (
    <>
    <section className="costOfDissertationSectionStartHere">
        <div className="container-fluid costOfDissertationBackgroundImageSection">
            <div className="row justify-content-evenly">
                <div className="col-md-4 my-3 py-3 costOfDissertationQuickQueryFormPart">
                    <p style={{textAlign: "center", fontWeight: "600", fontSize: "23px"}}>QUICK QUERY FORM</p>
                    <QuickQueryForm />
                </div>
                <div className="col-md-7 my-3 py-3 costOfDissertationCalculationPart">
                <p style={{textAlign: "center", fontWeight: "600", fontSize: "23px", color: "#006CA2"}}>COST ESTIMATOR</p>
                    <div className="mb-3 costOfDissertationCalculationPartDiv">
                        <p>Will you provide the required length in:</p>
                        <input type="radio" id="Words" name="requiredLength" value={wordsOrPages} onChange={valueOfWordsOrPages} />
                        <label htmlFor="words" className='ms-2'>Words</label>
                        <input type="radio" id="Pages" name="requiredLength" className="ms-5" value={wordsOrPages} onChange={valueOfWordsOrPages} />
                        <label htmlFor="pages" className='ms-2'>Pages</label>
                    </div>
                    <div className="mb-3 costOfDissertationCalculationPartDiv">
                        <p>Please provide the Number of Words / Pages:</p>
                        <input type="text" id="count" name="count" className="wordsOrPages" value={typeWordsOrPages} onChange={getWordsOrPages} />
                        <span className="ms-2">{wordsOrPages}</span>
                    </div>
                    <div className="mb-3 costOfDissertationCalculationPartDiv">
                        <p>Approx price of your disertation is?</p>
                        <p><span>$</span>
                            <span className="ms-2">
                                {
                                    <span className="ms-2 costOfDissertation">{cost}</span>
                                }
                            </span>
                        </p>
                    </div>
                    <div>
                        <NavLink to={"/getQuote"}><button className="costOfDissertationCalculationPartButton mt-3">ORDER NOW</button></NavLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default CostEstimatorAndQueryForm;