import React from 'react'
import '../DissertationAbstract.css'
import AbstractBannerForm from './AbstractBannerForm';

function AbstractBanner() {
  return (
    <>
    <section className="dissertationAbstarctBannerImageSection">
        <div className="container-fluid dissertationAbstractBannerImage">
            <div className="row justify-content-center">
                <div className="col-md-12 abstractBannerImageHeadingAndParaSection">
                    <h1>STARTING YOUR DISSERTATION</h1>
                    <p className="bannerImageParaOne">Affordable Price | Moneyback Guarantee<span>*</span></p>
                    {/* <h3>Connect With Our Experts</h3> */}
                    <AbstractBannerForm />
                </div>
                {/* <div className="col-md-4 bannerImageVacantSection"></div> */}
            </div>
        </div>
    </section>
    </>
  )
}

export default AbstractBanner;