import React from 'react'
import '../Page/DissertationCapstone.css';
import CapstoneStatistics from './Capstone_Project/CapstoneStatistics';
import WriteCapstoneProject from './Capstone_Project/WriteCapstoneProject';
import BannerAndFirstQuestion from './Capstone_Project/BannerAndFirstQuestion';
import QuestionFaqAndImage from './Capstone_Project/QuestionFaqAndImage';
import { Helmet } from 'react-helmet';

function DissertationCapstone() {
  return (
    <>
     <Helmet>
          <title>Capstone Project Writing Services | Plagiarism Free</title>
          <meta name="description" content="We have the best academic writer who do a thorough research before writing the project and give you the best Capstone Project." />
          <meta name="keywords" content="take my online dissertation, Do My Dissertation For Me, hire someone to write my dissertation, Can someone else write my dissertation?, Dissertation topics" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/capstoneprojects/" />
        </Helmet>
    <BannerAndFirstQuestion />
    <WriteCapstoneProject />
    <QuestionFaqAndImage />
    <CapstoneStatistics />
    </>
  )
}

export default DissertationCapstone