
const TestimonialData = [
    {
        id : 1,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Jasmine.png"),
        title : "Quicker response",
        content : "I didn't have to wait long until I got my response; they were pretty quick in responding to the queries I had. Oh ya, one more thing, my research was on voting disenfranchisement, the data used were credible, and from renowed scholars, the jury that assessed my paper in the universities was happy with data utilization. I'd rate the writemydissertationforme a 4 out of 5.",
        studentName : "Jasmine Flaire",
        altTag: "Dissertation writing Help"
    },
    {
        id : 2,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Abigale.png"),
        title : "No hidden rates",
        content : "These guys are pretty open with the money they charge; no matter how difficult the paper is, they communicate transparently, even if it's a delay; good job, guys. My dissertation passed through with a satisfactory grade. Look forward to working with y'all again.",
        studentName : "Abigale Moris",
        altTag: "Dissertation Paper"
    },
    {
        id : 3,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Joyce.png"),
        title : "Early delivery",
        content : "I liked working with them; these writers were adaptive to my urgency; since the university needed an urgent submission, the writer took the responsibility to understand my need and delivered the work before the deadline I had set for them. I liked the fact that I had the authority to take control of the deadlines with them.",
        studentName : "Joyce Martin",
        altTag: "write my dissertation online"
    },
    {
        id : 4,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Phil.png"),
        title : "Friendly service",
        content : "I was not able to respond on time, but the writers had taken responsibility for the paper and had completed a major portion of it, and left only those parts where my input was needed. I felt good that when I was caught up in my personal work, my education part was taken care of without any compromises.",
        studentName : "Phil Strowan",
        altTag: "Can someone else write my dissertation?"
    },
    {
        id : 5,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Mary.png"),
        title : "Satisfactory grade",
        content : "Hell Yeah! My dissertation paper got a satisfactory grade; no edits were needed. There were no grammar errors or plagiarism issues; the citations were done pretty neatly. The feedback I gave was taken gracefully; these are reaaallllyy good people when it comes to writing! They took the burden off my shoulders.",
        studentName : "Mary Liv",
        altTag: "Pay someone to write my dissertation"
    },
    {
        id : 6,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/George.png"),
        title : "Value for money",
        content : "I feel luck was on my side when I had the opportunity to collaborate and work with tutors. The topic was qualitative methods in education and human development. The resources that were used for dissertation research were credible. The professor was impressed with the quality of the work. I could certainly say, they are value for money.",
        studentName : "George Curtis",
        altTag: "Do My Dissertation For Me"
    },
    {
        id : 7,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Anna.png"),
        title : "Ease with thesis",
        content : "I am a working woman with a family of four, while completing my PhD was always on my mind. I could not juggle with everything. However the online academics came very handy and they made my life a lot easier and helping me with finishing my research report. I would rate them 10 on 10. Thank you!",
        studentName : "Anna Roberts",
        altTag: "take my online dissertation"
    },
    {
        id : 8,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Audrey.png"),
        title : "Quality Work",
        content : "If you are planning to pursue your higher doctorate studies and you are confused on how to go about it. Then I would recommend to use these services, since it has helped me in every other way -economical, clear communication and timely work. I was happy with their professional attitude and their quality work.",
        studentName : "Audrey Holt",
        altTag: "take my online dissertation"
    },
    {
        id : 9,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Danny.png"),
        title : "Punctual for time",
        content : "I was very much tensed that whether my dissertation will be able to be submitted on time or not. But thanks to the write my dissertation for me that they helped me for on time submission.",
        studentName : "Danny Broom",
        altTag: "take my online dissertation"
    },
    {
        id : 10,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Daniel.png"),
        title : "Proper guidance",
        content : "It's like a miracle but It's true, I have completed my dissertation and now I am looking for my career but it is not possible without your support and guidance. Thank You for your help. I give you 5 out of 5 stars.",
        studentName : "Daniel Brook",
        altTag: "take my online dissertation"
    },
    {
        id : 11,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Ken.png"),
        title : "Exceptional grades",
        content : "Very good job, guys! I liked the communication; it was swift. I was skeptical initially about giving my paper, but you guys surprised me; I got an A grade on my paper, thank you. I will work with you again, my friends and I have a big paper coming up.",
        studentName : "Ken Roberts",
        altTag: "take my online dissertation"
    },
    {
        id : 12,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Hal.png"),
        title : "Timely delivery",
        content : "I didn't know how to write a dissertation research paper and was worried that I could submit my dissertation within the deadline. But when I took dissertation service, these guys helped me present my research paper within the deadline. The content was plagiarism free and from credible sources. I would rate the writemydissertationforme a 9 out of 10.",
        studentName : "Hal Miller",
        altTag: "take my online dissertation"
    },
    {
        id : 13,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Jenny.png"),
        title : "Perfection",
        content : "No matter how much you try to make your thesis perfect, there is something or the other that misses. I left it to the experts and they made it, with no plagiarism or grammar error. I also love how professional they are in writing and delivering.",
        studentName : "Jenny Moore",
        altTag: "take my online dissertation"
    },
    {
        id : 14,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Arthur.png"),
        title : "Editing services",
        content : "Dissertation editing services are a life-saver for students struggling with dissertations. I worked on my dissertation for months, and I was about to give up when I found out about dissertation editing services. I decided to try it, and I am so glad I did! The editor I worked with was excellent. She helped me to organize my thoughts and to improve my writing. I was able to finish my dissertation thanks to her help. I am so grateful for her support!",
        studentName : "Arthur Miller",
        altTag: "take my online dissertation"
    },
    {
        id : 15,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Kendy.png"),
        title : "Plagiarism checker",
        content : "I have been using this plagiarism checker for my dissertations for a while, and it has been beneficial. It is accurate, and I have never had any problems with it. I highly recommend it to anyone who needs to check their work for plagiarism.",
        studentName : "Kendy",
        altTag: "take my online dissertation"
    },
    {
        id : 16,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Katie.png"),
        title : "Day in advance",
        content : "I had no idea how to write a paper, and I was concerned about whether or not I would be able to turn it in on time. Many thanks to the experts assisted with writing the papers and submitting a day in advance. I really appreciate your efforts.",
        studentName : "Katie",
        altTag: "take my online dissertation"
    },
    {
        id : 17,
        image : require("../../Image/Testimonial_Page/Testimonial_Image/Roman.png"),
        title : "Right choice",
        content : "I was unsure when I ordered what kind of service I would receive, but now I Thank you for giving me an A grade in my course. Friendly portal and supported me in taking my online class. Followed my submission deadlines and delivered on what was promised. Yes, I would also like to recommend the website to my friends.",
        studentName : "Roman Max",
        altTag: "take my online dissertation"
    }

];

export default TestimonialData;