import React from 'react'
import { Helmet } from 'react-helmet'
import Carousel from '../CommonPage/Carousel'
import '../Page/Home/FaqComponent.css'
import CostOfDissertation from './Home/CostOfDissertation'
import DoMyDissertation from './Home/DoMyDissertation'
import FaqComponent from './Home/FaqComponent'
import SocialStatistics from './Home/SocialStatistics'
import WriteMyDissertation from './Home/WriteMyDissertation'
import './LandingPage.css'

function LandingPage() {
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                    "@type": "Question",
                    "name": "Is everything confidential?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Of course! Privacy and confidentiality are the two things we swear by. All conversations between you and the expert are kept private and secure. Nothing is shared with any third party. Even the expert working on your project only knows the topic and your feedback. They have no access to your personal information."
                    }
                },{
                    "@type": "Question",
                    "name": "Is it original?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "100%! The writers are trained and moulded to only deliver original content. Our internal quality checks ensure quality and original content. Also, we use software like TurnItIn and Copyscape to back ourselves. We do not even utter the word ‘plagiarism’ in our company."
                    }
                },{
                    "@type": "Question",
                    "name": "How do I place an order?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a free quote instantly. All the communication after the form is filled, happens through email. Hassle free, fast and smooth!"
                    }
                },{
                    "@type": "Question",
                    "name": "How long will it take to Do My Dissertation?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our commitment is to meet the deadline given by you. Periodic update on the progress being made and the chapters that are being completed will also be shared with you. Feedbacks will also be incorporated in the paper."
                    }
                },{
                    "@type": "Question",
                    "name": "What is the qualification of the experts?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Your most important academic paper (dissertation and capstone) is handled by our most knowledgeable and serious tutors. We take on board tutors who have completed their own PhDs. This gives them a better insight into your requirements."
                    }
                },{
                    "@type": "Question",
                    "name": "What is the quality of work?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "So good that your professor will never ask you to make any changes. We strive to hit the nail at one attempt. Not only with perfect grammar and well-articulated sentences but also with ideas that match the context. Also, each section of the paper is thoroughly reviewed by another expert."
                    }
                }]
                }`}
                </script>
            </Helmet>
            {/* Write My Dissertation For Me Section Start Here  */}
            <Carousel />
            <WriteMyDissertation />

            {/* Do My Dissertation Section Start Here  */}
            <DoMyDissertation />

            {/* Cost Of Dissertation Start Here */}
            <CostOfDissertation />

            {/* FAQ Section Start Here */}
            <FaqComponent />

            {/* Social Statistis Section Start Here  */}
            <SocialStatistics />
        </>
    )
}

export default LandingPage;