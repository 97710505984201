import React from 'react'
import "../CommonPage/Navbar.css"
import BelowNavbar from '../CommonPage/BelowNavbar';
import UpperNavbar from '../CommonPage/UpperNavbar';

const Navbar = () => {
  return (
    <>
    <header className='sticky-top'>
        <UpperNavbar />
        <BelowNavbar />
    </header>
    </>
  )
}

export default Navbar;