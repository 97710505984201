
 const style = [
    {
        id : 1,
        value : "APA",
        styleData : "APA"
    },
    {
        id : 2,
        value : "MLA",
        styleData : "MLA"
    },
    {
        id : 3,
        value : "Chicago",
        styleData : "Chicago"
    },
    {
        id : 4,
        value : "Harvard",
        styleData : "Harvard"
    },
    {
        id : 5,
        value : "Oxford",
        styleData : "Oxford"
    },
    {
        id : 6,
        value : "Other",
        styleData : "Other"
    }
];



export default style;