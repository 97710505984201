import React, { useState } from 'react'
import './FaqComponent.css'
import faqComponent from '../../Data/LandingPage/FaqLandingPageDataComponent'

function FaqComponent() {
  // const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null)
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <>
    <section className="faqSectionStartHere">
      <div className="container-fluid faqSectionFirstInnerDiv">
        <div className="row justify-content-evenly">
          <div className="col-md-12">
            <h2>FREQUENTLY ASKED QUESTIONS? (FAQ)</h2>
          </div>
          {
            faqComponent.map((item, i) => (
              <>
              <div className="col-lg-4 col-md-5 col-sm-5 faqIconsParaAndHeadingDiv" key={item.id}>
              <div className="faqIconNumberOne faqIcons faqIconsCenter my-3" onClick={() => toggle(i)}>
                <img src={item.image} alt={item.altTag} />
              <h5>{item.question}</h5>
              </div>
              <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
              </div>
              </>
            ))
          }
        </div>
      </div>
    </section>
    </>
  )
}

export default FaqComponent;