import React from 'react'
import { Link } from 'react-router-dom'
import "../Page/Dissertation_Subject_Page/MainSubjectPage.css"

const MoreSubjectButton = () => {
    const ReadMoreBtnDiv = {
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center'
    }
    const MoreSubjectsBtn = {
        background: 'linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%)',
        borderRadius: '4px',
        border: 'none',
        margin: '1rem 0',
        color: '#FFFFFF',
        fontWeight: '500',
        padding: '0.7rem 7rem',
        fontSize: '19px'
    }
  return (
    <>
    <section className="read-more-btn-section">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12" style={ReadMoreBtnDiv}>
                    <Link to={"/dissertationSubjects"} style={{color: "#FFFFFF", textDecoration: "none"}}><button className="more-subjects-btn" style={MoreSubjectsBtn}>More Subjects</button></Link>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default MoreSubjectButton