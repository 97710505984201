import React from 'react'
import '../DissertationIntroduction.css'
import IntroductionBannerForm from './IntroductionBannerForm';

function IntroductionBanner() {
  return (
    <>
    <section className="dissertationIntroductionBannerImageSection">
        <div className="container-fluid dissertationIntroductionInnerDivOfBannerImageSection">
            <div className="row justify-content-center">
                <div className="col-md-12 introductionBannerImageHeadingAndForm">
                    {/* <h3>GET YOUR TASK COMPLETED</h3> */}
                    <IntroductionBannerForm />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default IntroductionBanner;