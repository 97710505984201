
 const CarouselData = [
    {
        id : 1,
        value : "Dissertation Proposal",
        typeOfPaperData : "Dissertation Proposal"
    },
    {
        id : 2,
        value : "Dissertation Abstract",
        typeOfPaperData : "Dissertation Abstract"
    },
    {
        id : 3,
        value : "Dissertation Introduction",
        typeOfPaperData : "Dissertation Introduction"
    },
    {
        id : 4,
        value : "Dissertation Literature Review",
        typeOfPaperData : "Dissertation Literature Review"
    },
    {
        id : 5,
        value : "Dissertation Methodology",
        typeOfPaperData : "Dissertation Methodology"
    },
    {
        id : 6,
        value : "Dissertation Discussion",
        typeOfPaperData : "Dissertation Discussion"
    },
    // {
    //     id : 7,
    //     value : "Dissertation Data Analyst &amp; Results",
    //     typeOfPaperData : "Dissertation Data Analyst &amp; Results"
    // },
    {
        id : 7,
        value : "Dissertation Conclusion",
        typeOfPaperData : "Dissertation Conclusion"
    },
    {
        id : 8,
        value : "Dissertation Bibliography",
        typeOfPaperData : "Dissertation Bibliography"
    },
    {
        id : 9,
        value : "Capstone Project",
        typeOfPaperData : "Capstone Project"
    }
];

export default CarouselData;