import React from 'react'
import '../DissertationLiteratureReview.css'

function FirstTwoQuestions() {
  return (
    <>
    <section className="literatureReviewFirstTwoQuestionSection">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 literatureReviewFirstQuestion">
                    <h2>WHAT IF YOU GET STUCK WRITING DISSERTATION LITERATURE REVIEW ?</h2>
                    <p>The process of writing dissertation literature review requires effective engagement and flow of ideas. Literature Review has to be carefully researched from multiple sources for explaining the core concepts connected with the thesis of the project. Many students seek help while solving dissertation paper to ensure that the quality of literature review is properly managed. Without clear planning of the literature, the ideas within the project are likely to be quite shallow. As such, there is a necessity to plan the literature review in a clear manner wherein long term management of the quality and depth of the ideas would be followed. Students who are looking for dissertation writers online regularly contact us with messages that state, “Pay Someone to Write My Dissertation”. These students tend to suffer from concerns regarding dissertation Literature Review. If you are stuck during the process of completing literature review, please get in touch with our expert tutors. They recommend thorough research of the core concepts of the topic for completing the literature review with in depth analysis.</p>
                </div>
                <div className="col-md-12 literatureReviewSecondQuestion">
                    <h2>HOW TO GET OUT EXPERTS HELP IN DISSERTATION WRITING ?</h2>
                    <p>Dissertation writing requires assessment of the thesis statement in a detailed manner. The use of expert help in solving dissertations is crucial. A number of crucial steps have to be followed to ensure that dissertation help is sought from qualified and experienced tutors. It is pertinent for students to ensure that prospective experts are evaluated in terms of checking their background, expertise and past records. Dissertation assistance should be taken from experts who are likely to have proper experience in solving dissertation projects. Privacy of students is quite an important aspect of managing dissertation help. As such, responsible tutors will have to be selected so that the data sourced from clients would be kept away from public forums. A good way to assess the quality of the experts while seeking help in dissertation writing is connected with review of the testimonials of past clients. Our track record with over 95% positive reviews and feedback from clients provides testimony to the quality of experts who provide dissertation help to our clients.</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default FirstTwoQuestions;