import React from 'react';
import '../CSS_File/MainBlogPage.css';
// import cardImageOne from '../../../../Image/Blog_Page/Blog_Page_1.jpg'
// import { useState } from 'react'
import { ourBlogs } from '../../../../Data/Blogs/OurBlogs';

function BlogAlsoRead() {
    // const [data, showData] = useState(ourBlogs);
    let randomArrayNumOne = Math.floor(Math.random() * ourBlogs.length);
    let randomArrayNumTwo = Math.floor(Math.random() * ourBlogs.length);
    let randomArrayNumThree = Math.floor(Math.random() * ourBlogs.length);
    return (
        <>
            <section className="blogAlsoReadSectionStartHere">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 blogAlsoReadHeading">
                            <h2>also read</h2>
                        </div>
                        <div className="col-lg-4 col-md-5 col-xl-4 blogAlsoReadCard my-3">
                            <div className="blog-also-read-first-inner-div">
                                <img src={ourBlogs[randomArrayNumOne].blogImage} alt={ourBlogs[randomArrayNumOne].altTag} className="img-fluid card-img-top cardImage" />
                                <div className="card-body blogAlsoReadCardBody" id='blogAlsoReadCardBody'>
                                    <h5>{ourBlogs[randomArrayNumOne].title}</h5>
                                    <p>{ourBlogs[randomArrayNumOne].description.slice(0, 125)}...</p>
                                    <button className="blogAlsoReadCardButton">{ourBlogs[randomArrayNumOne].newPage}</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 col-xl-4 blogAlsoReadCard my-3">
                            <div className="blog-also-read-first-inner-div">
                                <img src={ourBlogs[randomArrayNumTwo].blogImage} alt={ourBlogs[randomArrayNumTwo].altTag} className="img-fluid card-img-top cardImage" />
                                <div className="card-body blogAlsoReadCardBody" id='blogAlsoReadCardBody'>
                                    <h5>{ourBlogs[randomArrayNumTwo].title}</h5>
                                    <p>{ourBlogs[randomArrayNumTwo].description.slice(0, 125)}...</p>
                                    <button className="blogAlsoReadCardButton">{ourBlogs[randomArrayNumTwo].newPage}</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 col-xl-4 blogAlsoReadCard my-3">
                            <div className="blog-also-read-first-inner-div">
                                <img src={ourBlogs[randomArrayNumThree].blogImage} alt={ourBlogs[randomArrayNumThree].altTag} className="img-fluid card-img-top cardImage" />
                                <div className="card-body blogAlsoReadCardBody" id='blogAlsoReadCardBody'>
                                    <h5>{ourBlogs[randomArrayNumThree].title}</h5>
                                    <p>{ourBlogs[randomArrayNumThree].description.slice(0, 125)}...</p>
                                    <button className="blogAlsoReadCardButton">{ourBlogs[randomArrayNumThree].newPage}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogAlsoRead