import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import useWordCountTime from '../../../../Hooks/useWordCountTime'
import ShouldLiteratureReview from '../../../../Image/Blog_Page/Blog_Imge/How_long_should_a_Dissertation_literature_review_be.png'
import '../../Blog_Component/CSS_File/MainBlogPage.css'
import BlogAlsoRead from '../../Blog_Component/JS_File/BlogAlsoRead'
import BlogCommentForm from '../../Blog_Component/JS_File/BlogCommentForm'
import BlogSocialMediaIcon from '../../Blog_Component/JS_File/BlogSocialMediaIcon'

const HowLongLiteratureReview = () => {
    const [readingTime, findWord1, findWord2] = useWordCountTime();
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://www.writemydissertationforme.com/blogPage/how-long-should-a-dissertation-literature-review-be"
            },
            "headline": "How long Should A Dissertation Literature Review be",
            "description": "A literature review generally covers 20-30% of the dissertation. For a dissertation of 5000 words the literature review can be 1000-1500 words of length.",
            "image": "https://www.writemydissertationforme.com/static/media/How_long_should_a_Dissertation_literature_review_be.754a07aae2a3adc59bb7.png",
            "author": {
                "@type": "Organization",
                "name": "Alisha Howard"
            },
            "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                    "@type": "ImageObject",
                    "url": ""
                }
            },
            "datePublished": "2022-10-24",
            "dateModified": "2023-01-07"
        }`}
                </script>
                <title>Blog | How Long Should a Dissertation Literature Review be</title>
                <meta name="description" content="A literature review generally covers 20-30% of the dissertation. For a dissertation of 5000 words the literature review can be 1000-1500 words of length." />
                <link rel="canonical" href="https://www.writemydissertationforme.com/blogPage/how-long-should-a-dissertation-literature-review-be" />
            </Helmet>
            <section className="blogPageStartHere">
                <div className="container blogPageInnerDivStartHere">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blogTitleDateAndReadTime">
                            <h1>How Long Should a Dissertation Literature Review be</h1>
                            <p><span>Oct 24 2023</span> | <span id="dissertationLiteratureReview">{readingTime} min read</span></p>
                        </div>
                        <div className="col-md-12 blogImageDiv">
                            <img src={ShouldLiteratureReview} alt="How Long Should a Dissertation Literature Review be" className="img-fluid" />
                        </div>
                        <div className="col-md-12 blogPageContentFirst" ref={findWord1}>
                            <p>A dissertation is a long written document that is a part of the study for bachelor's and master's degree programs. It is a research-based document that showcases the original research or analysis of research on a specific topic. A dissertation is a research paper that consists of more or fewer chapters and components. It differs by the depth of research and word count.</p>
                            <p>The length of the literature review depends on the required length of the dissertation and the nature of the research question being addressed. A literature review can be around 20-30% of the overall dissertation, and it can vary depending on the requirements of the program and field of study. While writing a literature review should be focused and concise, and it should provide a critical evaluation and synthesis of the relevant literature, highlighting the main themes and arguments that have been developed in the field.</p>
                            <h2>The Main Chapters of the Dissertation</h2>
                            <p>The main chapters of the dissertation consist of the following:</p>
                            <ol>
                                <li><b>Introduction</b> - This section provides an overview of the research question being addressed in the dissertation and explains the context and significance of the study.</li>
                                <li><b>Literature review</b> - This section surveys the existing research on the topic and provides a critical evaluation and synthesis of the relevant literature.</li>
                                <li><b>Methodology</b> - This section describes the research design and methods that were used to collect and analyze data for the study.</li>
                                <li><b>Discussion</b> - This section interprets the results of the study and puts them in the context of the existing literature on the topic. It should also discuss any limitations of the study and suggest areas for future research.</li>
                                <li><b>Conclusion</b> - This section summarizes the main findings of the study and their implications for the field.</li>
                            </ol>
                            <p>In addition to these main components, a dissertation may also include an abstract, a table of contents, a list of figures, results, and a bibliography.</p>
                            <p>In this article, we are going to emphasize the literature review chapter and how you can structure and write it better.</p>
                            <h2>How A Dissertation is Divided</h2>
                            <p>The literature review covers almost 30% of the total word count in a dissertation. Therefore, for a dissertation that stretches up to 5000 words or 10000 words, 30% of the word count comes to around 1500 words or 3000 words. Here is a guide on how a dissertation chapters might be divided, with each chapter representing a percentage of the overall length of the dissertation:</p>
                            <ol>
                                <li>Introduction - 10-15%</li>
                                <li>Literature review - 20-30%</li>
                                <li>Methodology - 15-20%</li>
                                <li>Discussion - 20-30%</li>
                                <li>Conclusion - 5-10%</li>
                            </ol>
                            <p>It's important to keep in mind that these are general guidelines, and the specific division of a dissertation may vary depending on the requirements of the program or field of study. It is always good to check with your advisor or program guidelines to determine the specific requirements for the structure and content of your dissertation.</p>
                            <h2>How To Write A Literature Review?</h2>
                            <ol>
                                <li><b>Define the research question or problem:</b> Defining the research question or problem is the first step to writing a literature review. This will help to guide the selection of relevant literature and ensure that the review is focused and relevant.</li>
                                <li><b>Search for literature:</b> Once the research question has been defined, the next step is to search for literature that is relevant to the topic. This may involve using online databases, library catalogs, or other sources to find articles, books, and other publications that address the research question.</li>
                                <li><b>Evaluate the quality of the literature:</b> After identifying relevant literature, the next step is to evaluate the quality of the sources. Involving factors such as the credibility of the authors, the relevance of the source to the research question, the precise methods used in methods, and the overall reliability of the source.</li>
                                <li><b>Synthesize the literature:</b> Once the literature has been identified as relevant and high-quality, the next step is to synthesize the information and identify any themes, patterns, or gaps in the research. This may involve creating a table or matrix to organize the information or using techniques such as mind mapping or concept mapping to visualize the relationships between different studies.</li>
                                <li><b>Write the review:</b> The final step in writing a literature review is to write a summary of the findings, highlighting the main themes and arguments that have been developed in the field and how they relate to the research question being addressed. The review should be structured and logical, with clear transitions between different sections or themes.</li>
                                <li><b>Edit and proofread the review carefully:</b> To ensure that the paper is free of errors and the readers can read it smoothly, be sure to edit and proofread the review. Proper citation style should be used to properly credit the sources you have used in the review.</li>
                            </ol>
                            <h2>Importance Of Literature Review</h2>
                            <p>Provides context and background information for the research being conducted.</p>
                            <p>Helps to establish the significance and relevance of the research question or problem.</p>
                            <p>Identifies gaps in the existing research and defines the contribution of the current study to the field.</p>
                            <p>Situates the current study within the larger context of the field and demonstrates how it builds upon the existing knowledge base.</p>
                            <p>Ensures that the research being conducted is ethical and scientifically rigorous by identifying and addressing any potential ethical issues or biases in previous studies.</p>
                            <p>Plays a crucial role in establishing the validity and importance of the research being conducted.</p>
                            <h2>Choose Material For Research</h2>
                            <p>Some key points to consider when choosing material for a literature review:</p>
                            <ol>
                                <li><b>Relevance</b> - The material should be directly relevant to the research question or problem being addressed in the review.</li>
                                <li><b>Quality</b> - The material should come from credible sources, such as peer-reviewed journals or books published by reputable publishers. It should also be based on rigorous research methods and be reliable.</li>
                                <li><b>Timeliness</b> - The material should be up-to-date and not outdated, as the field may have progressed significantly since the publication of older sources.</li>
                                <li><b>Breadth</b> - The material should provide a broad overview of the field rather than focusing too narrowly on a specific aspect or issue.</li>
                                <li><b>Depth</b> - The material should be in-depth and provide a detailed analysis of the topic rather than just skimming the surface.</li>
                                <li><b>Balance</b> - The material should present a balanced view of the field rather than being biased toward a particular perspective.</li>
                                <li><b>Accessibility</b> - The material should be easily accessible, either through online databases or the library, to ensure that it can be easily accessed and reviewed.</li>
                            </ol>
                        </div>
                        <div className="col-md-12 blogPageContentSecond" ref={findWord2}>
                            <h2>Structure and Write your Literature Review</h2>
                            <ol>
                                <li>Start with an introduction that provides an overview of the research question or problem being addressed in the review, as well as the context and significance of the study.</li>
                                <li>Divide the literature into themes or sub-topis, and organize the material within each theme or sub-topic in a logically coherent way.</li>
                                <li>Use clear and concise headings and subheadings to help guide the reader through the review.</li>
                                <li>Use transitional phrases and sentences to link different sections or themes and to help ensure a smooth and logical flow to the review.</li>
                                <li>Provide a critical evaluation of the literature, highlighting the main themes and arguments that have been developed in the field and how they relate to the research question being addressed.</li>
                                <li>Identify gaps or limitations in the existing research, and suggest areas for future research.</li>
                                <li>Conclude the review by summarizing the main findings and their implications for the filed</li>
                                <li>Use proper citation style to properly credit the sources you have use in the review.</li>
                                <li>Carefully edit and proofread the review to make it free of errors and can read smoothly.</li>
                            </ol>
                            <h2>If it Does not Help, Hire our Literature Review Writing Services</h2>
                            <p>If you don't have experience and knowledge in writing a literature review, it is difficult to write it yourself and may put you under stress. It always finds an assistant who has knowledge in the required domain.</p>
                            <p>In such cases, you can <Link to={"/dissertationLiteratureReview"}>hire literature review writing services</Link> that will resolve all your literature review problems. We have a huge panel of professional writers who belong to different fields and are experts, and have experience in the field of research and writing.</p>
                            <p>To find out more, all you have to do is get the form filled up and give your details and contact our customer support team through email and get it started !!!</p>
                        </div>
                        <BlogSocialMediaIcon />
                    </div>
                </div>
            </section>
            <BlogCommentForm />
            <BlogAlsoRead />
        </>
    )
}

export default HowLongLiteratureReview