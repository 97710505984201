import React from 'react'
import '../DissertationMethodology.css'
import MethodologyBannerForm from './MethodologyBannerForm'

function MethodologyBanner() {
  return (
    <>
     <section className="dissertationMethodologyBannerImageSection">
        <div className="container-fluid dissertationMethodologyInnerDivOfBannerImage">
            <div className="row justify-content-center">
                <div className="col-md-7 dissertationMethodologyBannerImageHeadingAndForm">
                    {/* <h2>GET FREE QUOTE WITH OUR EXPERTS</h2> */}
                    <MethodologyBannerForm />
                </div>
                {/* <div className="col-md-5 hideThisDivSection"></div> */}
            </div>
        </div>
    </section>
    </>
  )
}

export default MethodologyBanner