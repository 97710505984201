import React from 'react'
import { Link } from 'react-router-dom'
import dissertationWritingService from "../../Image/Landing_Page//Dissertation Writing Service.webp"
import capstoneProject from "../../Image/Landing_Page/Capstone Projects.webp"
import doMyDissertation from "../../Image/Landing_Page/Do My Dissertation.webp"
import '../LandingPage.css'

function DoMyDissertation() {
  return (
    <>
      <section className="thirdSectionStartHere">
          <div className="container thirdSectionContentStartHere">
              <div className="row justify-content-between thirdInnerSectionContentStartHere">
                <div className="col-lg-5 image-section">
                    <img src={doMyDissertation} alt="Do my dissertation" className="img-fluid dissertationWritingImage" />
                </div>
                  <div className="col-lg-5 doMyDissertation my-3 py-3">
                    <p className="heading-1">DO MY DISSERTATION</p>
                    <p className="mt-3 paragraph">Is the complexity of your dissertation weighing you down? Help is at hand. Our great writers, with a best-in-industry assignment writing system and complete confidentiality, will ensure that you get access to the best work with your privacy intact. No need to worry about Turnitin or Copyscape reports as our 100% original work absolutely nails the project for you. So, when the deadline for the dissertation is fast approaching, just drop in a mail to us and get your project done with completely customized project work. </p>
                    <button><Link to={"/getQuote"}>READ MORE</Link></button>
                  </div>
                  <div className="col-lg-5 dissertationWritingService my-3 py-3">
                    <p className="heading-2">DISSERTATION WRITING SERVICE</p>
                    <p className="mt-3 paragraph">Dissertation writers in our tutor base are trained with years of experience to provide unique and original content which comprehensively matches your project objectives. While we provide the best tutors for your needs, our clients do not need to fret about our costs which are in an affordable range. Such a pricing system is why our clients provide the highest stars while reviewing our services and recommending us to their friends for their projects! Want urgent dissertation work to be done? You have reached the right place. Just fill in the order request form or drop an email to us.</p>
                    <button><Link to={"/dissertationWritingServices"}>READ MORE</Link></button>
                  </div>
                  <div className="col-lg-5 image-section my-3 py-3">
                    <img src={dissertationWritingService} alt="hire someone to write my dissertation for me" className="img-fluid dissertationWritingImage dissertationWritingServicesPicClass" />
                  </div>
                  <div className="col-lg-5 image-section mt-3 pt-3">
                    <img src={capstoneProject} alt="can someone my write my capstone project" className="img-fluid dissertationWritingImage" />
                  </div>
                  <div className="col-lg-5 capstoneProjects mt-3 pt-3">
                    <p className="heading-3">CAPSTONE PROJECTS</p>
                    <p className="mt-3 paragraph">Culminating (capstone) project at the end of the semester to be done? Do not recall everything that you studied in your academic journey? Fret not! Help is at hand! We, at Write My Dissertation For Me, create a team of subject matter experts from multiple fields. They collaborate and pool in their knowledge to write the capstone project. The experts, with PhD degrees (mostly), use their vast experience and develop a project that ensures you end your academic journey on a high!</p>
                    <button className='capstoneProjectsButton mb-4'><Link to={"/capstoneProjects"}>READ MORE</Link></button>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default DoMyDissertation;