
import React, { useEffect, useState } from "react";
// import { ourBlogs } from "../../Data/Blogs/OurBlogs";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import '../Blog_Page/Pagination.css';

function Pagination({ showPerPage, onPaginationChange, total }) {
  // useEffect(() => {
  //       window.scrollTo({ behavior: "smooth", top: "0px" });
  //   }, []);
  const [counter, setCounter] = useState(1);
  const [numberOfButtons, setNumberOfButtons] = useState(
    Math.ceil(total / showPerPage)
  );

  useEffect(() => {
    const value = showPerPage * counter;
    onPaginationChange(value - showPerPage, value);
  }, [counter]);

  const onButtonClick = (type) => {
    if (type === "prev") {
      if (counter === 1) {
        setCounter(1);
      } else {
        setCounter(counter - 1);
      }
    } else if (type === "next") {
      if (numberOfButtons === counter) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
    }
    document.documentElement.scrollTo({top: 0, left: 0, behavior: "instant"})
  };
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCounter(pageNumber);
  }
  const getPaginationGroup = () => {
    let start = Math.floor((counter - 1) / showPerPage) * showPerPage;
    return new Array(showPerPage).fill("").map((_, idx) => start + idx + 1);
  };
  return (
    <>
      <div className="d-flex">
        <nav aria-label="Page navigation example">
          <ul className="pagination blogPagePagination">
            <li className="page-item">
              <button
                className="pageLinkButton"
                onClick={() => onButtonClick("prev")}
              >
                <span><i><BsChevronLeft /></i></span>
              </button>
            </li>
            {/* show page numbers */}
            {/* Iske comments ko on karenge to page number nazar aayega (03-12-2022 ko change kiye hain)  */}
            {/* {getPaginationGroup().map((item, index) => (
        <button
          key={index}
          onClick={changePage}
          className={`paginationItem ${counter === item ? 'active' : null}`}
        >
          <span>{item}</span>
        </button>
      ))} */}
            {/* Iske upar tak hai  */}
            
            {/* {new Array(numberOfButtons).fill("").map((el, index) => (
            <li className={`page-item ${index + 1 === counter ? "active" : null}`}>
              <a
                className="page-link"
                href="!#"
                onClick={() => setCounter(index + 1)}
              >
                {index + 1}
              </a>
            </li>
          ))} */}
            <li className="page-item nextPageItemButton">
              <button
                className="pageLinkButton pageLinkLeftButton"
                onClick={() => onButtonClick("next")}
              >
                <span><i><BsChevronRight /></i></span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Pagination;
