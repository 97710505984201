import React from 'react';
import homeIcon from '../Image/ThankYou_Page/home.png';
import './ThankYouPage.css';
import { NavLink } from 'react-router-dom';

function ThankYouPage() {
  return (
    <>
    <section className="thankYouPageBackgroundImageSectionStartHere">
        <div className="container-fluid thankYouPageBackgroundImageFirstInnerDiv">
            <div className="row thankYouPageRow justify-content-center">
                <div className="col-md-12 thankYouPageBackgroundImageMainHeading">
                    <h2 className="thankYouPageBackgroundImageHeading">THANK YOU!</h2>
                    <p className="thankYouPageBackgroundImagePara">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div className="goToHomePageBox">
                        <span className="thankYouPageBackgroundImageButton"><NavLink to={"/"} class="href"><span>Go Home</span><span className="ms-3"><img src={homeIcon} alt={"Home"} /></span></NavLink></span>
                    </div>
                </div>
                <div className="thankYouPageBackgroundImageLastBorder"></div>
            </div>
        </div>
    </section>
    <section className="thankYouPageTestimonialSection">
        <div className="container">
            <div className="row thankYouPageRow justify-content-evenly">
                <div className="col-md-12 testimonialSectionHeading">
                    <h2><span>CHECK OUT SOME OF OUR TESTIMONIALS</span></h2>
                </div>
                <div className="col-md-3 testimonialSectionCard">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque </p>
                </div>
                <div className="col-md-3 testimonialSectionCard">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque </p>
                </div>
                <div className="col-md-3 testimonialSectionCard">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque </p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ThankYouPage;