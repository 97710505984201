import React from 'react'
import '../AboutUsPage.css'

function OurPerformance() {
  return (
    <>
    <section className="ourPerformanceSectionStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 ourPerformanceInnerDiv">
                    <h2><span>OUR PERFORMANCE</span></h2>
                </div>
                <div className="col-lg-2 ourPerformanceSmallSection">
                    <p className="ourPerformanceData"><span className="ourPerformanceDataNumberOne">457</span></p>
                    <p className="ourPerformancePara">QUALIFIED TUTORS</p>
                </div>
                <div className="col-lg-2 ourPerformanceSmallSection">
                    <p className="ourPerformanceData"><span>35.9K</span></p>
                    <p className="ourPerformancePara">STUDENTS ENROLLED</p>
                </div>
                <div className="col-lg-2 ourPerformanceSmallSection">
                    <p className="ourPerformanceData"><span>94.7%</span></p>
                    <p className="ourPerformancePara">SUCCESS RATE</p>
                </div>
                <div className="col-lg-2 ourPerformanceSmallSection">
                    <p className="ourPerformanceData"><span>54.8K</span></p>
                    <p className="ourPerformancePara">DISSERTATION</p>
                </div>
                <div className="col-lg-2 ourPerformanceSmallSection">
                    <p className="ourPerformanceData"><span>120M</span></p>
                    <p className="ourPerformancePara">WORDS WRITTEN</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default OurPerformance;