import React, { useState } from 'react';
import { BsFacebook, BsLink, BsLinkedin, BsTwitter } from "react-icons/bs";
import '../CSS_File/MainBlogPage.css';
// import { BsHeartFill } from "react-icons/bs";

function BlogSocialMediaIcon() {
    let copyUrl = navigator.clipboard.writeText(window.location.href);
    const [isCopied, setIsCopied] = useState(!copyUrl);

    function copyToClipboard () {
        setIsCopied(copyUrl);
        setTimeout(() => {
            setIsCopied(!copyUrl)
        }, 2000);
    }
    return (
        <>
            <div className="col-md-12 boxEndDottedLineDivStartHere">
                <div className="boxEndDottedLine"></div>
            </div>
            <div className="col-md-12 blogLastTitleAndLikeShareButton">
                <p>If you loved reading this, please share this,</p>
                <p>
                    { /* <span>56<i className="ms-2 socialMediaLikeIcon"><BsHeartFill /></i></span> */ }
                    <span onClick={() => window.open('https://twitter.com/i/flow/login')}><i className="socialMediaIcon"><BsTwitter /></i></span>
                    <span onClick={() => window.open('https://www.facebook.com/login/')}><i className="socialMediaIcon"><BsFacebook /></i></span>
                    <span onClick={() => window.open('https://in.linkedin.com/')}><i className="socialMediaIcon"><BsLinkedin /></i></span>
                    <span onClick={copyToClipboard}><i className="socialMediaIcon"><BsLink /></i></span>
                </p>
            </div>
            {
                isCopied ? 
                <>
                 <div className="showCopiedContent">
                    <p>Link Copied</p>
                </div>
                </> : ""
            }
        </>
    )
}

export default BlogSocialMediaIcon;