import React from 'react'
import '../../Page/DissertationTermsAndConditions.css'

function TermsAndConditionsBanner() {
  return (
    <>
    <section className="dissertationTermsAndConditionsSectionStartHere">
        <div className="container-fluid dissertationTermsAndConditionsBannerImageSection">
            <div className="row justify-content-center">
                <div className="col-md-12"></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default TermsAndConditionsBanner