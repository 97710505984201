import { useEffect, useRef, useState } from "react";

const useWordCountTime = () => {
    const [readingTime, setReadingTime] = useState();
    const findWord1 = useRef("");
    const findWord2 = useRef("");

    useEffect (() => {
        let Read_Per_Mins = 250;
        let wordCountFirst = findWord1.current.innerText.trim().split(" ").length;
        let wordCountSecond = findWord2.current.innerText.trim().split(" ").length;
        let wordCount = wordCountFirst + wordCountSecond;
        let time = Math.ceil(wordCount / Read_Per_Mins);
        if (time > 0 || time < 10) {
            time = "0" + time;
        }
        setReadingTime(time);
    }, [])

    // const showReadingTime = () => {
    //      // let words = document.querySelectorAll("div.blogPageContentFirst, div.blogPageContentSecond");
    //     // let wordCountFirst = words[0].innerText.trim().split(" ").length;
    //     // let wordCountSecond = words[1].innerText.trim().split(" ").length;  
    // }

    return [readingTime, findWord1, findWord2];

}

export default useWordCountTime;