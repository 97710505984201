import React from 'react';
import { NavLink } from 'react-router-dom';
import arrowIcon from '../../Image/Dissertation_Literature_Review/Arrow.png';
import '../DissertationLiteratureReview.css';

function QuestionsChaptersAndImage() {
  return (
    <>
    <section className="dissertationLiteratureReviewLastThreeQuestionAndChaptersSection">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-12 col-12 dissertationLiteratureReviewLastThreeQuestions">
                    <h2>ARE THE WRITERS WORK AS PER ORDER GUIDELINES ?</h2>
                    <p>When students seek help from dissertation experts, it is important to constantly review whether the writing is being done in line with the specified guidelines. Dissertation projects require complete review of the topics, outline, research methodology, etc. A good practice for students would be to review the draft on a consistent basis to ensure that the project work is being carried out in line with the dissertation instruction shared by professors. Feedback from the professors should be sought to ensure that the gaps within the dissertation research is properly covered up. Students continue to search for experts who respond to queries like “Pay Someone to Write your dissertation”. <br /><br />There will be a need for completion of the dissertation projects in a detailed manner using templates suggested within the dissertation guide. Our dissertation supervisors review the work of the tutors to check whether all feedback of the students have been followed in a detailed manner. Review by supervisors is focussed on checking whether all dissertation project requirements are followed. Unless the client requirements are followed in all aspects, dissertation projects undergo constant revision to ensure that the quality of writing matches the needs of the client. </p>
                    <button className="dissertationLiteratureReviewClickHereButton mt-1 mb-4"><NavLink to={"/getQuote"} className="href">CLICK HERE</NavLink></button>
                </div>
                <div className="col-lg-6 dissertationLiteratureReviewLastThreeQuestions">
                <h2>HOW LONG SHOULD A LITERATURE REVIEW BE FOR A DISSERTATION ?</h2>
                    <p>The length of literature review depends on the core subject matter. It is likely to range from 20 pages to as long as 60 pages. The overall length of literature review should be justified in terms of the subject matter of the dissertation project. Top experts who provide guidance for students who drop queries connected with “pay someone to write my dissertation” suggest that the literature review should be designed to comprehensively explain the core subject of the dissertation paper. As such, the length will have to match the dissertation project guidelines shared by the professor and also be aligned with the flow of the content. Care should be taken to ensure that the literature review should not be too short as it is then likely to miss important aspects of the topic nor should it be too long as it put-off the readers of the dissertation paper. No one likes to read a long rambling paper! Have concerns about solving proper literature? Get in touch with our expert tutors!</p>
                </div>
                <div className="col-lg-5 col-12 dissertationLiteratureReviewImagesAndChaptersSection">
                    <h2>DISSERTATION CHAPTERS</h2>
                    <ul>
                        <li className="mt-4"><img src={arrowIcon} alt="" /><NavLink to={"/dissertationProposal"}
                                className="href ms-2">Dissertation Proposal</NavLink></li> 
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationAbstract"}
                                className="href ms-2">Dissertation Abstract</NavLink></li>
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationIntroduction"}
                                className="href ms-2">Dissertation Introduction</NavLink></li>       
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationMethodology"}
                                className="href ms-2">Dissertation Methodology</NavLink></li>
                        <li><img src={arrowIcon} alt="" /><NavLink to={"/dissertationConclusion"}
                                className="href ms-2">Dissertation Conclusions</NavLink></li>
                        <li className="mb-4"><img src={arrowIcon} alt="" /><NavLink to={"/dissertationBibliography"} className="href ms-2">Dissertation Bibliography</NavLink></li>
                    </ul>
                </div>
                <div className="col-md-12 dissertationLiteratureReviewLastThreeQuestions">
                    <h2>WHAT ARE THE RULES FOR WRITING A LITERATURE REVIEW ?</h2>
                    <p>Best literature review follows the core steps of ensuring that topic is clearly understood by the target audience. During the process of solving literature review, it is crucial to ensure that effective reading is engaged. The process of managing the reading should be backed with the idea of taking notes as well. Our experts provide a Dissertation Writing Service which ensures that literature review will be focused on the core topic of the research. Best Dissertation Writing Service has to be consistent. Readers should be able to engage elements connected with critical thinking while reviewing the literature review. Logical structure has to be followed within literature review to ensure that gaps in existing literature review would be covered within the dissertation project.</p>
                    <button className="dissertationLiteratureReviewHireExpertButton mt-1 mb-4"><NavLink to={"/getQuote"} className="href">HIRE EXPERT</NavLink></button>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default QuestionsChaptersAndImage;