import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MoreSubjectButton from '../../CommonPage/MoreSubjectButton';
import FAQDataOnPsychologySubject from '../../Data/FAQs/FAQDataOnPsychologySubject';
import Account1 from "../../Image/Dissertation_Subject_Page/Psychology-P/Psychology-1.webp";
import Account2 from "../../Image/Dissertation_Subject_Page/Psychology-P/Psychology-2.webp";
import Account3 from "../../Image/Dissertation_Subject_Page/Psychology-P/Psychology-3.webp";
import Account4 from "../../Image/Dissertation_Subject_Page/Psychology-P/Psychology-4.webp";
import '../../Page/Dissertation_Subject_Page/MainSubjectPage.css';
import "../../Page/FAQsComponentOnDPages/FAQComponentOnDPages.css";
import Guarantees from '../LiteratureReview/Guarantees';

function PsychologySubjectPage() {
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, []);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    return (
        <>
            <Helmet>
                <title>Psychology Dissertation | Do My Psychology Dissertation For Me</title>
                <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
                <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
                <link rel="canonical" href=" https://www.writemydissertationforme.com/psychology/" />
            </Helmet>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Psychology Dissertation Writing Help</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>Are you an undergraduate or graduate student writing a psychology dissertation? Our company specializes in helping you craft a high-quality dissertation that showcases your knowledge and research abilities in the psychology field. With a focus on ethics and integrity, our expert writers—selected for their academic excellence, honesty, and reliability—are assigned based on their psychology background. We are committed to delivering exceptional, custom-written dissertation papers to meet your academic needs.</p>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account1} alt="What is a Finance Dissertation Writing Service?" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Professional Psychology Dissertation Help Online For All Levels</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account2} alt="Finance Dissertation Help Service" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>Are you seeking <strong>online psychology dissertation help</strong>, whether for critique elements or topic selection? We offer tailored assistance across all academic levels, including high school, graduate, post-graduate, and doctorate. Our writers develop unique solutions by closely assessing each requirement, ensuring perfection and timely delivery. Students can consult us for personalized writing aid, access samples, and learn how to write dissertations, from undergraduate to Ph.D. level, with expert guidance at every step.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Which Topics Are Covered in Psychology Dissertation Help?</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>We specialize in various psychological research subjects, from cognitive elements to neuropsychological aspects. Some popular topics we cover include childhood anxiety and depression, social phobia challenges, psychological assessment tests, child abuse prevention, depression's impact on daily life, and the effects of bullying on students. With our dissertation writing service, choosing psychology topics and excelling academically is easier than ever. Reach out to us for expert guidance and personalized support in your research.</p>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account3} alt="Finance Dissertation Help Services" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Benefits of hiring a Professional Psychology Dissertation Help Service</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account4} alt="Finance Dissertation Help" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>We offer numerous perks for those seeking academic help with psychological studies. Enjoy access to blogs, samples, last-minute assistance, advanced academic tools, and a money-back guarantee. Our resources include sample papers and informative blogs for easier learning. Get last-minute help when you're short on time, and use free tools like grammar checkers and plagiarism detectors. Plus, we offer a money-back guarantee if your paper is incomplete, inaccurate, or plagiarized.</p>
                        </div>
                    </div>
                </div>
            </section>
            <MoreSubjectButton />
            <Guarantees />
            <section className="faq-section-on-faq-page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h2>HAVE ANY QUESTIONS</h2>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {
                                    FAQDataOnPsychologySubject.map((item, i) => (
                                        <>
                                            <div className="faqParaAndHeadingDiv" key={item.id}>
                                                <div className="faqCompIconNumberOne my-3" onClick={() => toggle(i)}>
                                                    <h5>{item.question}</h5>
                                                </div>
                                                <p className={selected === i ? 'content show' : 'content'}>{item.answer}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PsychologySubjectPage;