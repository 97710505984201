import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";
import SubjectComponent from '../CommonPage/SubjectComponent';
import bannerImage from '../Image/Dissertation_Subject_Page/banner_image.png';
import clickYesPoint from '../Image/Dissertation_Subject_Page/click_yes_point.png';
import '../Page/DissertationSubject.css';

function DissertationSubject() {
    return (
        <>
            <Helmet>
                <title>Dissertation Subject | Do My Dissertation For Me</title>
                <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
                <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
                <link rel="canonical" href=" https://www.writemydissertationforme.com/dissertationsubjects/" />
            </Helmet>
            <section className="dissertationSubjectPageBannerImageSection">
                <div className="container-fluid">
                    <div className="row justify-content-evenly">
                        <div className="col-lg-5 dissertationSubjectPageHeadingAndParaInBannerSection">
                            <h1>Expert Dissertation Help Across All Subjects</h1>
                            <p>Our <strong>dissertation writing services</strong> cover various academic subjects, ensuring expert guidance, tailored research, and high-quality content for your dissertation needs.</p>
                            <p><span><img src={clickYesPoint} alt="" /></span><span className="ms-4">Affordable Price</span></p>
                            <p><span><img src={clickYesPoint} alt="" /></span><span className="ms-4">Assured Grade A or B</span></p>
                            <p><span><img src={clickYesPoint} alt="" /></span><span className="ms-4">Moneyback Guarantee</span><span className='dissertationSubjectStarMark'>*</span></p>
                            <button className="dissertationSubjectPageOrderNowButton"><NavLink className="href" to={"/getQuote"}>ORDER NOW</NavLink></button>
                        </div>
                        <div className="col-lg-5 dissertationSubjectPageLeftImageSection">
                            <img src={bannerImage} alt="Best Dissertation writing service" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <SubjectComponent />
        </>
    )
}

export default DissertationSubject;