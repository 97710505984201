import React from 'react'
import '../DissertationConclusion.css'
import ConclusionBannerForm from './ConclusionBannerForm';

function ConclusionBanner() {
  return (
    <>
    <section className="dissertationConclusionBannerImageSection">
        <div className="container-fluid dissertationConclusionInnerDivOfBannerImage">
            <div className="row justify-content-center">
                <div className="col-md-7 dissertationConclusionBannerImageHeadingAndForm">
                    {/* <h2>CONNECT WITH OUR EXPERTS</h2> */}
                    <ConclusionBannerForm />
                </div>
                <div className="col-md-5 hideThisDivSection"></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ConclusionBanner;