import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import useWordCountTime from '../../../../Hooks/useWordCountTime'
import DissertationBibliography from '../../../../Image/Blog_Page/Blog_Imge/How_To_Write_a_Dissertation_Bibliography.png'
import '../../Blog_Component/CSS_File/MainBlogPage.css'
import BlogAlsoRead from '../../Blog_Component/JS_File/BlogAlsoRead'
import BlogCommentForm from '../../Blog_Component/JS_File/BlogCommentForm'
import BlogSocialMediaIcon from '../../Blog_Component/JS_File/BlogSocialMediaIcon'

function WriteDissertationBibliography() {
    const [readingTime, findWord1, findWord2] = useWordCountTime();

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.writemydissertationforme.com/blogPage/how-to-write-a-dissertation-bibliography"
            },
            "headline": "How to write a dissertation bibliography",
            "description": "This article explains how to write a dissertation bibliographic page. It includes information on how to cite sources properly.",
            "image": "https://www.writemydissertationforme.com/static/media/How_To_Write_a_Dissertation_Bibliography.3534e244ec7ebbe67c5a.png",  
            "author": {
              "@type": "Organization",
              "name": ""
            },  
            "publisher": {
              "@type": "Organization",
              "name": "",
              "logo": {
                "@type": "ImageObject",
                "url": ""
              }
            },
            "datePublished": "2022-08-26",
            "dateModified": "2022-11-30"          
        }`}
                </script>
                <title>Blog | How to Write A Dissertation Bibliography</title>
                <meta name="description" content="This article explains how to write a dissertation bibliographic page. It includes information on how to cite sources properly." />
                <meta name="keywords" content="write my dissertation online, Can I Hire Someone To Write My Dissertation Online, Dissertation bibliography, dissertation writing services, online dissertation bibliography writing" />
                <link rel="canonical" href="https://www.writemydissertationforme.com/blogPage/how-to-write-a-dissertation-bibliography" />
            </Helmet>
            <section className="blogPageStartHere">
                <div className="container blogPageInnerDivStartHere">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blogTitleDateAndReadTime">
                            <h1>How To Write a Dissertation Bibliography</h1>
                            <p><span>Aug 26 2023</span> | <span id="dissertationLiteratureReview">{readingTime} min read</span></p>
                        </div>
                        <div className="col-md-12 blogImageDiv">
                            <img src={DissertationBibliography} alt="How To Write a Dissertation Bibliography" className="img-fluid" />
                        </div>
                        <div className="col-md-12 blogPageContentFirst" ref={findWord1}>
                            <p>Giving citations to all the sources of the research work at the end of a dissertation is essential. A bibliography includes sources of all the data, graphics, text, and ideas you have used in the dissertation paper. Also, include the non-written information sources that may have been consulted while carrying out the project. This section seems simple and easy but can be time-consuming if you don't know how to compile a dissertation bibliography. Different formats are used in the bibliography, so it is good to ask the professor which format they desire.</p>
                            <p>The primary things to do to write the bibliography -</p>
                            <ul>
                                <li>Keep track of all the books, magazines, websites, etc., and you consulted for your background research.</li>
                                <li>Cite the sources you consulted, and a thorough bibliography shows how the research has been done.</li>
                                <li>You can use bibliography sheets if you do not follow a particular format.</li>
                                <li>Here are complete insights into how different types of bibliography works and how to choose another format for the bibliography or how to write in different formats.</li>
                            </ul>
                            <h2>Why write a bibliography for a dissertation?</h2>
                            <p>When you start writing a dissertation or other academic writing, the ideas and thoughts are built up on the ideas of scholars, professors, and researchers. It is essential to recognize the source of the concept, data, and research from which references have been drawn. This will differentiate the ideas and thoughts from others, and the readers can follow the cited data if they want to.</p>
                            <p>Before you start writing a bibliography, you should ensure to include the full publication details of the relevant text and do not forget to note down the following things:</p>
                            <ul>
                                <li>Full Name</li>
                                <li>Date of publication</li>
                                <li>Title of the text</li>
                                <li>Volume number, the title of the journal</li>
                                <li>Place of publication, the title of a book, and the chapter with page number taken for reference.</li>
                            </ul>
                            <h2>Different types of bibliography styles</h2>
                            <p>The ideas and the thoughts that have been referenced from the source get mentioned whenever you quote them. It helps indicate a word of appreciation towards the source and avoids plagiarism.</p>
                            <p>Different people use different formats for bibliographies and referencing styles. Some use footnotes or endnotes, author-date systems, and numbering systems. Writing styles and patterns differ too.</p>
                            <p>The general format of the bibliography is the most commonly used. Here at the end bibliography is written. Every source is listed in alphabetical order in this section. The author's name, the title of the work, the publisher, the published year, and the journal must contain in the bibliography. The most commonly used styles for bibliography in dissertation are MLA style for humanities and APA style for sciences.</p>
                            <h3>Reference in MLA Style</h3>
                            <p>According to the Modern Associate Book of Eighth Edition, the bibliography is a work cited page. MLA style differs from APA and Chicago style because you must mention the media of publication like DVD, print and films, etc. In this style, first, the writer's last name is written, then the first name and a period. The referenced title of the book is to be mentioned in italics, along with the period and other things like the city of publication, a colon, and the publisher, then a comma and year of publication, and finally, the period to be mentioned.</p>
                            <h3>Reference in APA Style</h3>
                            <p>APA refers to the American Psychological Association, typically used in social science dissertations. According to the Seventh Edition introduced by APA, you must consult a committee chair to know more specific needs to complete a bibliography in APA style. The writer needs to list the sources in the text, the author's last name with a comma, and the first initial name and period that come at last. The year of publication should be put inside the parentheses with the period. And finally, the publisher's location, a colon, and the publisher's name end with a period.</p>
                        </div>
                        <div className="col-md-12 blogPageContentSecond" ref={findWord2}>
                            <h2>How to Write Chicago Style Bibliography</h2>
                            <p>Chicago style includes a bibliography page for the dissertation in a correctly formatted style. To format all the sources correctly, you need to list the author's name with a comma, then the first name, and a period. The title should be in italics, followed by a period, city of publication, and a comma at the end.</p>
                            <h3>Annotated Bibliography</h3>
                            <p>You need to briefly describe the source of the research paper and the advantages and disadvantages associated with the source. This is for the readers to read about a particular topic in detail. Most of the dissertations do not use an annotated bibliography.</p>
                            <p>From any article, if you cite the sources, the rules change to suit the cited article and help the reader to check the source independently. The author's citation should be the same as mentioned in the book. And the articles published in newspapers and magazines. The year of publication must be mentioned along with the page number from which the reference is taken. In visual media, citing the place of publication is not needed. In the case of movies, the title of the film followed by the director's name, year of release, and, if possible, mention the studio where the cinema was produced.</p>
                            <p>Some departments advised to prepare a chapter-wise separate bibliography instead of one at the end. So, you don't need to write additional bibliography at the end.</p>
                            <p>If you are finding it difficult to do a citation for your dissertation, we at <Link to={"/dissertationWritingServices"}>Write My Dissertation For Me</Link> is there to help you to write your <Link to={"/dissertationBibliography"}>dissertation bibliography</Link>.</p>
                            <p>We have professional writers experts in writing dissertation bibliography and citing in a proper format which your professor desires. Making your dissertation more readers friendly.</p>

                        </div>
                        <BlogSocialMediaIcon />
                    </div>
                </div>
            </section>
            <BlogCommentForm />
            <BlogAlsoRead />
        </>
    );
}

export default WriteDissertationBibliography;