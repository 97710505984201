
const FAQDataOnPoliticalScienceSubject = [
    {
        id : 1,
        question : "Can I pay someone to write my Political Science dissertation?",
        answer : "Yes, our professional writers can help write your Political Science dissertation while adhering to your specific guidelines and academic standards.",
    },
    {
        id : 2,
        question : "Can I get discounts with online political science dissertation help?",
        answer : "Yes, our Political Science Dissertation Help in the USA offers discounts on special occasions.",
    },
    {
        id : 3,
        question : "Do you have experts in Political Science as writers?",
        answer : "Yes, our team includes experts with advanced degrees in Political Science who are experienced in dissertation writing.",
    },
    {
        id : 4,
        question : "What services do you provide for Political Science dissertations?",
        answer : "We offer comprehensive dissertation writing services including topic selection, research, writing, editing, and citation formatting for Political Science topics.",
    },
    {
        id : 5,
        question : "How do you ensure originality in the dissertation?",
        answer : "We provide plagiarism-free content, thoroughly researched and written from scratch, along with plagiarism reports if needed.",
    },

];

export default FAQDataOnPoliticalScienceSubject;
