import { useEffect, useRef, useState } from "react";

const UseEmailValidation = () => {
    const [writingServices, setWritingServices] = useState("");
    const [email, setEmail] = useState('');
    const [reEmail, setReEmail] = useState('');
    const enquireNowButton = useRef("");

    useEffect (() => {
        if (reEmail !== email) {
            enquireNowButton.current.classList.add('display');
        } else {
            enquireNowButton.current.classList.remove('display');
        }
    }, [reEmail, email])

    const dissertationWritingServices = (e) => {
        setWritingServices(e.target.value);
    }

    const carouselEmail = (e) => {
        setEmail(e.target.value);
    }

    const carouselReEmail = (e) => {
        setReEmail(e.target.value);
    }

    return [writingServices, dissertationWritingServices, email, carouselEmail, reEmail, carouselReEmail, enquireNowButton];
}

export default UseEmailValidation;