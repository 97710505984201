import React from 'react';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import '../DissertationIntroduction.css';

function IntroductionQuestionAndCarousel() {
  return (
    <>
    <section className="dissertationIntroductionFifthQuestionAndTestimonial">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6 col-12 dissertationIntroductionFifthQuestion">
                    <h2>IS IT NECESSARY TO HIRE EXPERTS IN DISSERTATION INTRODUCTION ITSELF ?</h2>
                    <p>Dissertation introduction forms the foundation for managing the flow of the dissertation project work.  As such, taking help from experts for solving dissertation introduction will be essential to enhance the quality of the project. Examiners would be evaluating the quality of the overall project on the opinion which they are likely to form related to the ideas which are part of the introduction. There is a necessity for enhancing the quality of writing by hiring services of experts. It is necessary for dealing with the expansion of the grades of the entire project.</p>
                    <p>Many students worry with queries on online forums stating, “Can I Hire Someone to Write My Dissertation Online”. However, once you select a quality tutoring company to take care of your dissertation, the overall work system becomes quite smooth. Our experienced tutors are equipped with the ability to manage the completion of your project work in a clear manner.</p>
                    <button><NavLink to={"/getQuote"} className="href">REGISTER NOW</NavLink></button>
                </div>
                <div className="col-lg-5 col-12 dissertationIntroductionTestimonial">
                    <h4>TESTIMONIALS</h4>
                    <div id="carouselExampleControls" className="carousel dissertationIntroductionCarousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner dissertationIntroductionInnerPartOfCarousel">
                          <div className="carousel-item dissertationIntroductionCarouselItem active">
                            <p className="text-center">No matter how much you try to make your thesis perfect, there is something or the other that misses. I left it to the experts and they made it, with no plagiarism or grammar error. I also love how professional they are in writing and delivering.</p>
                          </div>
                          <div className="carousel-item dissertationIntroductionCarouselItem">
                            <p className="text-center">It's like a miracle but It's true, I have completed my dissertation and now I am looking for my career but it is not possible without your support and guidance. Thank You for your help. I give you 5 out of 5 stars.</p>
                          </div>
                          <div className="carousel-item dissertationIntroductionCarouselItem">
                            <p className="text-center">I was very much tensed that whether my dissertation will be able to be submitted on time or not. But thanks to the write my dissertation for me that they helped me for on time submission.</p>
                          </div>
                        </div>
                        <button className="carousel-control-prev carouselLeftRightButton" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"><BsChevronLeft /></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next carouselLeftRightButton" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"><BsChevronRight /></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default IntroductionQuestionAndCarousel;