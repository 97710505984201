import { useState } from "react";


const UseCostCalculator = () => {
    const [wordsOrPages, setWordsOrPages] = useState("");
    const [typeWordsOrPages, setTypeWordsOrPages] = useState("");
    const [cost, setCost] = useState(0);

    const valueOfWordsOrPages = (e) => {
        setWordsOrPages(e.target.id);
    }

    const getWordsOrPages = (e) => {
        const re = /^[0-9\b]+$/;
        // let id = e.target.id;
        if (e.target.value === "" || re.test(e.target.value)) {  
            let num = e.target.value;
            setTypeWordsOrPages(num);
            let cost = 0;
            if (wordsOrPages === "Pages") {
                cost = num * 10;
            } else if (wordsOrPages === "Words") {
                cost = ((num / 250) * 10 ).toFixed(2);
            }
            setCost(cost)
        }
    }

    return [wordsOrPages, getWordsOrPages, valueOfWordsOrPages, typeWordsOrPages, cost]
}

export default UseCostCalculator;