import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ourBlogs } from '../Data/Blogs/OurBlogs';
import '../Page/DissertationBlog.css';
import Pagination from './Blog_Page/Pagination';

function DissertationBlog() {
    let randomBlogDetailsOnBlogPageNumOne = Math.floor(Math.random() * ourBlogs.length);
    let randomBlogDetailsOnBlogPageNumTwo = Math.floor(Math.random() * ourBlogs.length);
    let randomBlogDetailsOnBlogPageNumThree = Math.floor(Math.random() * ourBlogs.length);
    let randomBlogDetailsOnBlogPageNumFour = Math.floor(Math.random() * ourBlogs.length);
    // let randomBlogDetailsOnBlogPageNumFive = Math.floor(Math.random() * ourBlogs.length);
    const [showPerPage, setShowPerPage] = useState(6);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    let blogArray = [];
    for(let i = ourBlogs.length - 1; i >= 0; i--) {
        blogArray.push(ourBlogs[i])
    }
    return (
        <>
            <Helmet>
                <title>Blog | Can Someone Write My Thesis For Me</title>
                <meta name="description" content="Are you looking for dissertation blogs that can help with your writing process? Dissertation services have you covered! Our experts know what they are doing." />
                <meta name="keywords" content="Dissertation Paper, Dissertation writing Help, Hire someone to write my dissertation, Dissertation Meaning, Do My Dissertation For Me" />
                <link rel="canonical" href=" https://www.writemydissertationforme.com/blogpage/" />
            </Helmet>
            <section className="dissertationBlogPageStartHere">
                <div className="container dissertationBlogPageFirstInnerDivStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-lg-9 col-12">
                            <div className="container">
                                <div className="row justify-content-evenly">
                                    {
                                        blogArray.slice(pagination.start, pagination.end).map((blogsData) => (
                                            <>
                                                <div className="col-lg-5 dissertationBlogPageBlogCard" key={blogsData.id}>
                                                    <div className="dissertation-blog-card-first-inner-div">
                                                        <img src={blogsData.blogImage} alt={blogsData.altTag} className="img-fluid card-img-top dissertationBlogPageCardImage" />
                                                        <div className="card-body dissertationPageCardBody">
                                                            <h5>{blogsData.title}</h5>
                                                            <p className="dissertationBlogPageFirstPara">{blogsData.description.slice(0, 121)}...</p>
                                                            <p className="dissertationBlogPageReadMoreButton">{blogsData.newPage}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                                <Pagination
                                    showPerPage={showPerPage}
                                    onPaginationChange={onPaginationChange}
                                    total={ourBlogs.length}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 dissertationBlogPageLatestBlogSection">
                            <h4>LATEST BLOG</h4>
                            <table className="table table-borderless dissertationBlogPageTable">
                                <tbody>
                                    <tr>
                                        <th scope="row" className='dissertationBlogPageHeading'>01.</th>
                                        <td className='dissertationBlogPageTableDataPara'>
                                            <p>{ourBlogs[randomBlogDetailsOnBlogPageNumOne].description.slice(0, 125)}...</p>
                                            <p className='dissertationBlogPageReadMoreButton'>{ourBlogs[randomBlogDetailsOnBlogPageNumOne].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationBlogPageHeading'>02.</th>
                                        <td className='dissertationBlogPageTableDataPara'>
                                            <p>{ourBlogs[randomBlogDetailsOnBlogPageNumTwo].description.slice(0, 125)}...</p>
                                            <p className='dissertationBlogPageReadMoreButton'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwo].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationBlogPageHeading'>03.</th>
                                        <td className='dissertationBlogPageTableDataPara'>
                                            <p>{ourBlogs[randomBlogDetailsOnBlogPageNumThree].description.slice(0, 125)}...</p>
                                            <p className='dissertationBlogPageReadMoreButton'>{ourBlogs[randomBlogDetailsOnBlogPageNumThree].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationBlogPageHeading'>04.</th>
                                        <td className='dissertationBlogPageTableDataPara'>
                                            <p>{ourBlogs[randomBlogDetailsOnBlogPageNumFour].description.slice(0, 125)}...</p>
                                            <p className='dissertationBlogPageReadMoreButton'>{ourBlogs[randomBlogDetailsOnBlogPageNumFour].newPage}</p>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <th scope="row" className='dissertationBlogPageHeading'>05.</th>
                                        <td className='dissertationTermsAndConditionsTableDataPara'>
                                            <p>{ourBlogs[randomBlogDetailsOnBlogPageNumFive].description}</p>
                                            <p className='dissertationBlogPageReadMoreButton'>{ourBlogs[randomBlogDetailsOnBlogPageNumFive].newPage}</p>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DissertationBlog;