
const faqComponent = [
    {
        id : 1,
        image : require("../../Image/Landing_Page/Dissertation_Emergency.png"),
        question : "Is everything confidential?",
        answer : "Of course! Privacy and confidentiality are the two things we swear by. All conversations between you and the expert are kept private and secure. Nothing is shared with any third party. Even the expert working on your project only knows the topic and your feedback. They have no access to your personal information.",
        altTag: "write my dissertation for me"
    },
    {
        id : 2,
        image : require("../../Image/Landing_Page/Dissertation_Plagiarism.png"),
        question : "Is it original?",
        answer : "100%! The writers are trained and moulded to only deliver original content. Our internal quality checks ensure quality and original content. Also, we use software like TurnItIn and Copyscape to back ourselves. We do not even utter the word ‘plagiarism’ in our company.",
        altTag: "do my dissertation"
    },
    {
        id : 3,
        image : require("../../Image/Landing_Page/Dissertation_Moneyback.png"),
        question : "How do I place an order?",
        answer : "Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a free quote instantly. All the communication after the form is filled, happens through email. Hassle free, fast and smooth!",
        altTag: "hire someone to write my dissertation"
    },
    {
        id : 4,
        image : require("../../Image/Landing_Page/Dissertation_Assignment.png"),
        question : "How long will it take to Do My Dissertation?",
        answer : "Our commitment is to meet the deadline given by you. Periodic update on the progress being made and the chapters that are being completed will also be shared with you. Feedbacks will also be incorporated in the paper.",
        altTag: "dissertation writing services"
    },
    {
        id : 5,
        image : require("../../Image/Landing_Page/Dissertation_Tutors.png"),
        question : "What is the qualification of the experts?",
        answer : "Your most important academic paper (dissertation and capstone) is handled by our most knowledgeable and serious tutors. We take on board tutors who have completed their own PhDs. This gives them a better insight into your requirements.",
        altTag: "i need help with my dissertation"
    },
    {
        id : 6,
        image : require("../../Image/Landing_Page/Dissertation_University.png"),
        question : "What is the quality of work?",
        answer : "So good that your professor will never ask you to make any changes. We strive to hit the nail at one attempt. Not only with perfect grammar and well-articulated sentences but also with ideas that match the context. Also, each section of the paper is thoroughly reviewed by another expert.",
        altTag: "take my online dissertation"
    }

];

export default faqComponent;