import React from 'react'
import '../Page/DissertationTermsAndConditions.css'
import TermsAndConditionsBanner from './Terms_And_Conditions/TermsAndConditionsBanner';
import QuestionsBlogAndChapters from './Terms_And_Conditions/QuestionsBlogAndChapters';
import { Helmet } from 'react-helmet';

function DissertationTermsAndConditions() {
  return (
    <>
    <Helmet>
          <title>Dissertation Proposal Writing Help | Professional Writers</title>
          <meta name="description" content="For dissertation writing services we follow some rules and regulations" />
          <meta name="keywords" content="thesis writing services near me, pay someone to write my dissertation, phd thesis online, masters dissertation help, thesis services" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/termsandconditions/" />
        </Helmet>
    <TermsAndConditionsBanner />
    <QuestionsBlogAndChapters />
    </>
  )
}

export default DissertationTermsAndConditions;