import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";
import "../Page/DissertationSiteMap.css";

function DissertationSiteMap() {
  return (
    <>
    <Helmet>
          <title>Dissertation Sitemap</title>
          <meta name="description" content="How we work, what you are looking for across the website." />
          <meta name="keywords" content="final year dissertation, economics dissertation help, do my thesis, help me write a thesis, write a thesis for me" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/sitemap/" />
        </Helmet>
    <section className="dissertationSiteMapSectionStartHere pt-4 mt-4">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 dissertationSiteMapDiv">
                    <ul className='mainUnOrderedListOfSiteMap'>
                        <NavLink to={"/"} className="listItem"><li>Home</li></NavLink>
                        <li>
                            Dissertation Writing Services
                            <ul>
                                <NavLink to={"/dissertationProposal"} className="listItem"><li>Dissertation Proposal</li></NavLink>
                                <NavLink to={"/dissertationAbstract"} className="listItem"><li>Dissertation Abstract</li></NavLink>
                                <NavLink to={"/dissertationIntroduction"} className="listItem"><li>Dissertation Introduction</li></NavLink>
                                <NavLink to={"/dissertationLiteratureReview"} className="listItem"><li>Dissertation Literature Review</li></NavLink>
                                <NavLink to={"/dissertationMethodology"} className="listItem"><li>Dissertation Methodology</li></NavLink>
                                <NavLink to={"/dissertationConclusion"} className="listItem"><li>Dissertation Conclusion</li></NavLink>
                                <NavLink to={"/dissertationBibliography"} className="listItem"><li>Dissertation Bibliography</li></NavLink>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={"/dissertationSubjects"} className="listItem">Dissertation Subjects</NavLink>
                            <ul>
                                <NavLink to={"/accounts"} className="listItem"><li>Accounts Dissertation</li></NavLink>
                                <NavLink to={"/economics"} className="listItem"><li>Economics Dissertation</li></NavLink>
                                <NavLink to={"/finance"} className="listItem"><li>Finance Dissertation</li></NavLink>
                                <NavLink to={"/history"} className="listItem"><li>History Dissertation</li></NavLink>
                                <NavLink to={"/english"} className="listItem"><li>English Dissertation</li></NavLink>
                                <NavLink to={"/mathematics"} className="listItem"><li>Mathematics Dissertation</li></NavLink>
                                <NavLink to={"/it"} className="listItem"><li>IT Dissertation</li></NavLink>
                                <NavLink to={"/psychology"} className="listItem"><li>Psychology Dissertation</li></NavLink>
                                <NavLink to={"/political-science"} className="listItem"><li>Political Science Dissertation</li></NavLink>
                                <NavLink to={"/sociology"} className="listItem"><li>Sociology Dissertation</li></NavLink>
                            </ul>
                        </li>
                        <NavLink to={"/capstoneProjects"} className="listItem"><li>Capstone Projects</li></NavLink>
                        <NavLink to={"/getQuote"} className="listItem"><li>Get Quote</li></NavLink>
                        <NavLink to={"/aboutUs"} className="listItem"><li>About Us</li></NavLink>
                        <NavLink to={"/blog"} className="listItem"><li>Blog</li></NavLink>
                        <NavLink to={"/testimonial"} className="listItem"><li>Testimonials</li></NavLink>
                        <NavLink to={"/faq"} className="listItem"><li>FAQ</li></NavLink>
                        <NavLink to={"/privacyPolicy"} className="listItem"><li>Privacy Policy</li></NavLink>
                        <NavLink to={"/termsAndConditions"} className="listItem"><li>Terms &amp; Conditions</li></NavLink>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default DissertationSiteMap