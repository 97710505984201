import React from 'react';
import { NavLink } from 'react-router-dom';
import { ourBlogs } from '../../Data/Blogs/OurBlogs';
import '../../Page/DissertationPrivacyPolicy.css';

function BlogAndChapters() {
    let randomPrivacyPolicyBlogsNumOne = Math.floor(Math.random() * ourBlogs.length);
    let randomPrivacyPolicyBlogsNumTwo = Math.floor(Math.random() * ourBlogs.length);
    let randomPrivacyPolicyBlogsNumThree = Math.floor(Math.random() * ourBlogs.length);
    let randomPrivacyPolicyBlogsNumFour = Math.floor(Math.random() * ourBlogs.length);
    let randomPrivacyPolicyBlogsNumFive = Math.floor(Math.random() * ourBlogs.length);
    return (
        <>
            <section className="dissertationPrivacyPolicyParaBlogAndChapters">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-7 col-12 dissertationPrivacyPolicyQuestionsHeadingAndPara">
                            <h2>YOUR PRIVACY IS NOT FOR SALE</h2>
                            <p>We do not and will not sell or rent your personal information to anyone, for any reason, at any time. WriteMyDissertationForMe uses and discloses your personal information only as follows:<br />
                                to analyze site usage and improve the Service;<br />
                                to deliver to you any administrative notices, money alerts and communications relevant to your use of the Service;<br />
                                to fulfill your requests for certain products and services;<br />
                                for market research, project planning, troubleshooting problems, detecting and protecting against error, fraud
                                or other criminal activity;<br />
                                to third-party contractors that provide services to WriteMyDissertationForMe and are bound by these same privacy restrictions;<br />
                                to enforce WriteMyDissertationForMe's Terms of Use;<br /> and
                                as otherwise set forth in this Privacy and Security Policy.
                            </p>
                            <h2>WE LIMIT THE COLLECTION AND USE OF PERSONAL INFORMATION</h2>
                            <p>Certain areas and features of <strong><NavLink to={"/"} style={{ textDecoration: "none", color: "#006CA2", fontWeight: "500" }}>WriteMyDissertationForMe.com</NavLink></strong> are available to you without registration or the need to provide to us any information. However, other features of the Site or the Service may require basic sign up information, which involves provision to WriteMyDissertationForMe of an email address or phone number.<br />
                                From time to time we may request other personal information to provide you with other benefits of the Service. In all such instances, you will be given the opportunity to provide or to decline to provide that information, and it will be used only for the stated purpose.
                            </p>
                            <h2>CONTACT US IF YOU HAVE ANY QUESTIONS</h2>
                            <p>If you have questions, comments, concerns or feedback regarding this Privacy and Security Policy or any other privacy or security concern, <span style={{ fontWeight: "500" }}>send an e-mail to writemydissertationforme@gmail.com</span>.<br />
                                <span style={{ fontWeight: "500" }}> WriteMyDissertationForMe</span>
                            </p>
                        </div>
                        <div className="col-lg-5 col-12 dissertationPrivacyPolicyBlogAndChapters">
                            <h4>LATEST BLOG</h4>
                            <table className="table table-borderless dissertationPrivacyPolicyTable">
                                <tbody>
                                    <tr>
                                        <th scope="row" className='dissertationPrivacyPolicyHeading'>01.</th>
                                        <td className='dissertationPrivacyPolicyTableDataPara'>
                                            <p className=''>{ourBlogs[randomPrivacyPolicyBlogsNumOne].description.slice(0, 105)}...</p>
                                            <p className='dissertationPrivacyPolicyReadMoreButton'>{ourBlogs[randomPrivacyPolicyBlogsNumOne].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationPrivacyPolicyHeading'>02.</th>
                                        <td className='dissertationPrivacyPolicyTableDataPara'>
                                            <p>{ourBlogs[randomPrivacyPolicyBlogsNumTwo].description.slice(0, 105)}...</p>
                                            <p className='dissertationPrivacyPolicyReadMoreButton'>{ourBlogs[randomPrivacyPolicyBlogsNumTwo].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationPrivacyPolicyHeading'>03.</th>
                                        <td className='dissertationPrivacyPolicyTableDataPara'>
                                            <p>{ourBlogs[randomPrivacyPolicyBlogsNumThree].description.slice(0, 105)}...</p>
                                            <p className='dissertationPrivacyPolicyReadMoreButton'>{ourBlogs[randomPrivacyPolicyBlogsNumThree].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationPrivacyPolicyHeading'>04.</th>
                                        <td className='dissertationPrivacyPolicyTableDataPara'>
                                            <p>{ourBlogs[randomPrivacyPolicyBlogsNumFour].description.slice(0, 105)}...</p>
                                            <p className='dissertationPrivacyPolicyReadMoreButton'>{ourBlogs[randomPrivacyPolicyBlogsNumFour].newPage}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='dissertationPrivacyPolicyHeading'>05.</th>
                                        <td className='dissertationTermsAndConditionsTableDataPara'>
                                            <p>{ourBlogs[randomPrivacyPolicyBlogsNumFive].description.slice(0, 105)}...</p>
                                            <p className='dissertationPrivacyPolicyReadMoreButton'>{ourBlogs[randomPrivacyPolicyBlogsNumFive].newPage}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogAndChapters